import * as React from "react";
import {ActionTag} from "../../models/Action";
import {Box, ThemeProvider} from "@mui/material";
import {TextFieldSmall} from "../common/TextFieldSmall";
import {toNumber} from "@amcharts/amcharts5/.internal/core/util/Type";
import {QuickEditRow} from "./CreateQuickEdit";
import {useState} from "react";
import createTheme from "@mui/material/styles/createTheme";



export interface CreateQuickEditRowProps extends React.HTMLProps<any>  {
    firstColWidth:number;
    catchmentID:string;
    catchmentName:string;
    onValueChange:(id:string, tag:ActionTag, value:number) => void;
    values:QuickEditRow;
    actionOrder:string[];
}


export function CreateQuickEditRow(props:CreateQuickEditRowProps) {
    const [rowValues, setRowValues] = useState<QuickEditRow>(props.values);

    function valueChanged(tag:ActionTag, value:string) {
        let val = 0;
        if (/^\d+$/.test(value)) {
            // Clamp to percentage range
            val = Math.max(0.0, Math.min(1.0, toNumber(value) / 100));
        }
        let rowVals = {...rowValues}
        rowVals[tag] = val
        setRowValues(rowVals);
        // Sets the "master" but does not trigger any events
        props.onValueChange(props.catchmentID, tag, val);
    }



    return (
        <Box style={{height:"48px"}} className={"box-content-row-padding"}>
            <Box style={{width:props.firstColWidth + "px"}}>
                <p style={{margin:"4px 0px 4px 0px", fontSize:"14px", fontWeight:(props.catchmentID === "all") ? "bold" : "normal"}}>{props.catchmentName}</p>
            </Box>
            {
                props.actionOrder.map((tag) => {
                    let at:ActionTag = tag as ActionTag;
                    return <TextFieldSmall onChange={(event) => {valueChanged(at, event.currentTarget.value)}}
                                           key={props.catchmentID + "-" + tag}
                                           id={props.catchmentID + "-" + tag}
                                           value={((rowValues[at] ?? 0) * 100.0).toFixed(0)}
                                           style={{flex:1, fontSize:"12px"}}
                                            />
                })
            }
        </Box>
    )


}
