import React from "react";
import {TextField} from "@mui/material";
import "../../App.css"
import InputAdornment from "@mui/material/InputAdornment";

export interface TextFieldSmallProps extends React.HTMLProps<any>  {
    value?: string;
    label?: string;
    style?: object;
    type?: string;
    id?: string;
    suffix?: string;
}

export function TextFieldSmall(props:TextFieldSmallProps) {
    return (
        <TextField
            value={props.value}
            label={props.label}
            style={props.style}
            size={"small"}
            variant="outlined"
            type={props.type}
            onChange={props.onChange}
            onBlur={props.onBlur}
            InputProps={ (props.suffix) ? {endAdornment: <InputAdornment position="end">{props.suffix}</InputAdornment>} : {}}
        />
    );

}
