import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TextFieldSmall} from "../common/TextFieldSmall";
import * as React from "react";
import {useContext} from "react";
import {FlowDataContext} from "../../models/FlowContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {AppTheme} from "../../AppTheme";
import {DivWithRef} from "../common/DivWithRef";

export interface CompareScenarioSelectProps extends React.HTMLProps<any> {
    selectedActionSets:Array<string>;
    infoSelection:string;
    onToggleSelection:(val:string) => void;
    onEdit:(val:string) => void;
    onSetInfoSelection:(val:string) => void;
}

export function CompareScenarioSelectView(props:CompareScenarioSelectProps) {
    const {t} = useTranslation();
    const {actionSets} = useContext(FlowDataContext);

    function ListEntry(p:any) {
        let selectedColor = (props.selectedActionSets.includes(p.id)) ? AppTheme.palette.showHilite : "#ffffff";
        let infoColor = (props.infoSelection === p.id) ? AppTheme.palette.showInfoHilite : "#ffffff";

        return <Box
            key={"entry-" + p.id}
            id={"entry-" + p.id}
            className={"box-row"}
            sx={{gap:"0px", height:"36px", border:"1px solid lightgray"}}
        >
            <Box
                style={{flex:1, height:"100%", backgroundColor:selectedColor}}
                onClick={()=>props.onToggleSelection(p.id)}
            >
                <p style={{width:"100%", margin:"8px 0px 0px 8px"}}>{p.name}</p>
            </Box>
            <Box
                sx={{display:"flex", justifyContent:"center", alignItems:"center", width:"36px", height:"100%", backgroundColor:infoColor, borderLeft:"1px solid lightgray"}}
                onClick={()=>props.onSetInfoSelection(p.id)}
            >
                <InfoOutlinedIcon />
            </Box>
            <Box style={{display:"flex", justifyContent:"center", alignItems:"center", width:"36px", height:"100%", borderLeft:"1px solid lightgray"}} onClick={()=>props.onEdit(p.id)}>
                <EditIcon />
            </Box>
        </Box>
    }

    return (
        <DivWithRef style={{width:"100%", height:"100%"}} id={"show-select-actions"}>
        <Box className={"box-content-col-padding"} sx={{gap:"0px"}} >
            <p style={{margin:"0 0 8px 0"}}><b>{t("Planning_Compare_ScenarioList_Title")}</b></p>
            <Box className={"box-content-row"}>
                <TextFieldSmall style={{flex:1}} value={""}/>
                <Button variant={"outlined"}>{t("Planning_Filter_TriggerButton_FilterOff")}</Button>
            </Box>
            <Box className={"box-content-row"}>
                <Button variant={"outlined"}>{t("Planning_Compare_ScenarioList_ChooseAll")}</Button>
            </Box>
            <Box className={"box-col"} sx={{flex:1}}>
                <Box className={"box-content-auto"} >
                    <Box className={"box-col"} sx={{width:"100%"}}>
                        {
                            actionSets.map((value) => {
                                return ListEntry({id:value.id, name:value.name});
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
        </DivWithRef>
    )
}
