import {Box} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export interface CreateQuickEditFactorHeaderProps extends React.HTMLProps<any>  {
    firstColWidth:number;
    text:string;
}
export function CreateQuickEditFactorHeader(props:CreateQuickEditFactorHeaderProps) {
    const {t} = useTranslation()
    return (
        <Box style={{height: "48px"}} className={"box-content-row-padding"}>
            <Box style={{width: props.firstColWidth + "px"}}>
                <p style={{margin: "4px 0px 4px 0px", fontSize: "14px", fontWeight:"bold"}}>{props.text}</p>
            </Box>
            <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <p style={{margin: "4px 0px 4px 0px", fontSize: "14px", fontWeight:"bold"}}>{t("Planning_Create_GrowthFactor_FRC")}</p>
            </Box>
            <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <p style={{margin: "4px 0px 4px 0px", fontSize: "14px", fontWeight:"bold"}}>{t("Planning_Create_GrowthFactor_SRC")}</p>
            </Box>
            <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <p style={{margin: "4px 0px 4px 0px", fontSize: "14px", fontWeight:"bold"}}>{t("Planning_Create_GrowthFactor_Spill")}</p>
            </Box>
        </Box>
    )
}
