import * as React from "react";
import {siteIDToSiteName} from "../../models/SiteData";
import {Box} from "@mui/material";
import {QuickEditFactorColumns} from "./CreateQuickEdit";
import {FactorTag} from "../../models/Action";
import {useTranslation} from "react-i18next";
import {CreateQuickEditFactorRow} from "./CreateQuickEditFactorRow";

export interface CreateQuickEditFactorProps extends React.HTMLProps<any>  {
    tableData:QuickEditFactorColumns;
    firstColWidth:number;
    onValueChange:(id:string, tag:FactorTag, value:number) => void;
}

export function CreateQuickEditFactorTable(props:CreateQuickEditFactorProps) {
    const {t} = useTranslation();

    function rows() : Array<JSX.Element> {
        let result:Array<JSX.Element> = [];
        let rowsForSort:{[key:string]:string}[] = []

        // Add to an array to be able to sort correctly
        for (const key in props.tableData) {
            rowsForSort.push({
                key : key,
                name: (key === "all") ? "00" : siteIDToSiteName(key)
            });
        }
        rowsForSort.sort((a, b) => {
            return a["name"].localeCompare(b["name"])
        })

        for (const keyObj of rowsForSort) {
            const key = keyObj["key"];
            const row = props.tableData[key];
            result.push(
                <CreateQuickEditFactorRow
                    catchmentID={key}
                    key={key}
                    catchmentName={key === "all" ? t("Planning_Create_QuickEdit_AllAreas") : siteIDToSiteName(key)}
                    values={row}
                    onValueChange={props.onValueChange}
                    firstColWidth={props.firstColWidth}
                />
            )
        }
        return result;
    }
    return (
        <Box className={"box-col"}>
            {
                rows()
            }
        </Box>
    )
}
