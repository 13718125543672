import * as React from "react";
import {useContext} from "react";
import {ScenarioSelectedView} from "./ScenariosView";
import {FlowDataContext} from "../../models/FlowContext";
import {ActionSet} from "../../models/Action";
import {DataGrid, GridActionsCellItem, GridColumns} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {faInfo, faPlay, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ScenariosListProps extends React.HTMLProps<any>  {
    selectedView:ScenarioSelectedView;
    onShowScenario:(id:string)=>void;
    onDeleteScenario:(id:string)=>void;
    onSimulateScenario:(id:string)=>void;
}

export function ScenariosListView(props:ScenariosListProps) {
    const {actionSets,periodSelection} = useContext(FlowDataContext);
    const {t} = useTranslation()

    function getListData() : ActionSet[] {
        let result:ActionSet[] = [];
        for (const actionSet of actionSets) {
            if (props.selectedView === ScenarioSelectedView.all || actionSet.states[periodSelection] as string === props.selectedView as string) {
                result.push(actionSet)
            }
        }
        return result
    }

    function getColumns() : GridColumns<ActionSet> {
        return [
            {
                field: 'name',
                headerName: t("Planning_Scenarios_TableHead_Name"),
                flex:4,
                disableColumnMenu:true
                // width: 90
            },
            {
                field: 'description',
                headerName: t("Planning_Scenarios_TableHead_Desc"),
                flex:3,
                disableColumnMenu:true

            },
            {
                field: 'createdBy',
                headerName: t("Planning_Scenarios_TableHead_User"),
                flex:3,
                disableColumnMenu:true

            },
            {
                field: 'createdAt',
                headerName: t("Planning_Scenarios_TableHead_Created"),
                flex:2,
                disableColumnMenu:true

            },
            {
                field: 'handle',
                headerName: t("Planning_Scenarios_TableHead_Actions"),
                type: 'actions',
                flex:1,
                sortable: false,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faPlay} />}
                        label={t("Planning_Scenarios_NewSimulation")}
                        onClick={()=>{props.onShowScenario(params.row.id)}}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faInfo} />}
                        label={t("Planning_Scenarios_Show")}
                        onClick={()=>{props.onShowScenario(params.row.id)}}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        label={t("Upload_Delete")}
                        onClick={()=>{props.onDeleteScenario(params.row.id)}}
                        showInMenu
                    />,
                ],
            }
        ];
    }

    return (
        <div style={props.style}>
            <DataGrid
                style={{height:"100%", width:"100%"}}
                rows={getListData()}
                columns={getColumns()}
                // pageSize={5}
                // rowsPerPageOptions={[5]}
                // checkboxSelection
                // disableSelectionOnClick
            />
        </div>
    );

}
