import * as React from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {MapView} from "../common/MapView";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {FlowDataContext, FlowDataSet} from "../../models/FlowContext";
import {StatusCatchmentGraphsView} from "../status/StatusCatchmentGraphsView";
import {CompareCostBenefitView} from "./CompareCostBenefitView";

export interface ShowTabViewProps extends React.HTMLProps<any>  {
    editMode:boolean;
    flowData:{[key:string]:FlowDataSet};
    useRelativeValues:boolean
    onRelativeValuesChanged:(flag:boolean) => void
}


export function CompareTabView(props:ShowTabViewProps) {
    const {selectedCatchment, setSelectedCatchment, selectedDischarge, setSelectedDischarge} = useContext(FlowDataContext);
    const {t} = useTranslation();
    const tabHeight = "30px";
    const panelHeight = "calc(100% - 30px)";

    function catchmentSelected(id:string) {
        setSelectedDischarge(null);
        setSelectedCatchment(id);
    }
    function dischargeSelected(id:string) {
        setSelectedCatchment(null);
        setSelectedDischarge(id);
    }

    return (
        <Tabs style={{width:'100%', height:'100%'}}>
            <TabList>
                <Tab style={{width:"20%", height:tabHeight, fontSize:'15px'}}>{t("Planning_Create_Graph")}</Tab>
                <Tab style={{width:"20%", height:tabHeight, fontSize:'15px'}}>{t("Planning_Create_Map")}</Tab>
                <Tab style={{width:"20%", height:tabHeight, fontSize:'15px'}}>{t("Planning_Compare_Chart")}</Tab>
            </TabList>
            <TabPanel style={{width:"100%", height:panelHeight}}>
                <CompareCostBenefitView
                    id={"costBenefit"}
                    flowData={props.flowData}
                    useRelativeValues={props.useRelativeValues}
                    onRelativeValuesChanged={props.onRelativeValuesChanged}
                />
            </TabPanel>
            <TabPanel style={{width:"100%", height:panelHeight}}>
                <MapView
                    id={"map-show"}
                    editMode={props.editMode}
                    selectedCatchments={(selectedCatchment) ? [selectedCatchment] : null}
                    selectedDischarges={(selectedDischarge) ? [selectedDischarge] : null}
                    onSelectCatchment={catchmentSelected}
                    onSelectDischarge={dischargeSelected}
                />
            </TabPanel>
            <TabPanel style={{width:"100%", height:panelHeight}}>
                <StatusCatchmentGraphsView editMode={props.editMode}/>
            </TabPanel>
        </Tabs>
    );

}
