import React, {useEffect, useLayoutEffect} from 'react';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import {Color, DataItem, Graphics} from "@amcharts/amcharts5";
import {IValueAxisDataItem, ValueAxis} from "@amcharts/amcharts5/xy";
import {AxisRendererCircular} from "@amcharts/amcharts5/radar";

export interface GaugeProps extends React.HTMLProps<any>  {
    id: string;
    min: number;
    max: number;
    current: number;
    goal?: number;
    reduction?: number;
    color?: string;
    mode?: GaugeViewMode;
}
export enum GaugeViewMode {
    goal = "GOAL",
    reduction = "REDUCTION"
}

export function GaugeView(props:GaugeProps) {
    // const [root, setRoot] = React.useState<Root>();
    // const [chart, setChart] = React.useState<Chart>();
    const [axisSettings, setAxisSettings] = React.useState<ValueAxis<AxisRendererCircular>>();
    const [greenRange, setGreenRange] = React.useState<DataItem<IValueAxisDataItem>>();
    const [redRange, setRedRange] = React.useState<DataItem<IValueAxisDataItem>>();
    const [handData, setHandData] = React.useState<DataItem<IValueAxisDataItem>>();

    useLayoutEffect(() => {
        let root = am5.Root.new("gauge-" + props.id);
        let chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                startAngle: -180,
                endAngle: 0,
                innerRadius: -20
            })
        );

        let axisRenderer =  am5radar.AxisRendererCircular.new(root, {});
        axisRenderer.labels.template.set("visible", false);

        let valueAxis:ValueAxis<AxisRendererCircular> = am5xy.ValueAxis.new(root, {
            renderer:axisRenderer
        });

        // valueAxis.renderer.labels.template.disabled = true;
        chart.xAxes.push(valueAxis);


        let rangeDataGreen = valueAxis.makeDataItem({});
        let rangeDataRed = valueAxis.makeDataItem({});

        valueAxis.createAxisRange(rangeDataGreen);
        valueAxis.createAxisRange(rangeDataRed);

        let fillColor:string

        fillColor = (props.mode === GaugeViewMode.reduction) ?
            (props.color) ? props.color : "#ff0000" :
            "#adf3b7" // Some hard coded green

        let gg:Graphics = rangeDataGreen.get("axisFill") as Graphics;
        gg.setAll({
            fill: Color.fromString(fillColor),
            fillOpacity: 1.0
        });

        fillColor = (props.mode === GaugeViewMode.reduction) ?
            "#bbbbbb" :
            "#ff9090" // Some hard coded red

        let rg:Graphics = rangeDataRed.get("axisFill") as Graphics;
        rg.setAll({
            fill: Color.fromString(fillColor),
            fillOpacity: 1.0
        });


        let handDataItem = valueAxis.makeDataItem({});

        valueAxis.createAxisRange(handDataItem);

        let clockHand = am5radar.ClockHand.new(root, {
            radius: am5.percent(100),
            innerRadius:am5.percent(20),
            pinRadius: am5.percent(0)
        });

        handDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: clockHand
        }));



        // Update state
        setAxisSettings(valueAxis);
        setGreenRange(rangeDataGreen);
        setRedRange(rangeDataRed);
        setHandData(handDataItem);


        // Make stuff animate on load
        chart.appear(1000, 100);

    }, [props.color, props.id, props.mode]);

    useEffect(() => {
        if (!axisSettings || !greenRange || !redRange || !handData) {
            return;
        }
        let totalRange = props.max - props.min;
        if (totalRange <= 0.0) {
            return;
        }
        axisSettings.set("min", 0.0);
        axisSettings.set("max", 1.0);

        let goal = (props.mode === GaugeViewMode.reduction) ?
            (props.current) ? props.current : 0.0 :     // A reduction shows the color range same as the needle
            (props.goal) ? props.goal : 0.0;

        greenRange.set("value", 0.0);
        greenRange.set("endValue", goal / totalRange);

        redRange.set("value", goal / totalRange);
        redRange.set("endValue", 1.0);

        handData.set("value", props.current / totalRange);

    }, [axisSettings, greenRange, redRange, handData, props]);

    return (
        <div id={"gauge-" + props.id} style={{width:"100%", height:"100%"}}></div>
    );
}
