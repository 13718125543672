
export enum ScenarioStepType {
    none = "none",
    wait = "wait",
    step = "step",               // Overlay window with a 'next' button or similar
    pop = "pop",                 // Overlay window without interactivity
    done = "done",               // Overlay window with result and 'done' button

}

export enum ScenarioStepPlacement {
    empty = "",
    none = "none",
    center = "center",      // Both centered
    right = "right",        // Same as centerRight
    left = "left",          // Same as centerLeft
    top = "top",            // Same as topCenter
    bottom = "bottom",      // Same as bottomCenter
    topLeft = "top-left",
    topCenter = "top-center",
    topRight = "top-right",
    bottomLeft = "bottom-left",
    bottomCenter = "bottom-center",
    bottomRight = "bottom-right",
    centerLeft = "center-left",
    centerRight = "center-right",
    centerRightLow = "center-right-low",

}


export enum ScenarioConditionType {
    none = "none",
    click = "click",        // Clicked a button, value contains button id
    event = "event",        // A scenario event triggered
    multiEvent = "multi-event",        // A scenario event triggered
    next = "next",          // Clicked next in help window
    url = "url",            // User has navigated to a specific url
    win = "win",            // All conditions in win conditions are done
    save = "save",            // Saves progress and continues
    message = "message",            // Sends a message
    time = "time",          // Wait a specified time
    inflow = "inflow",      // Inflow has been changed according to logic/value
    overflow = "overflow",  // Overflow has been changed according to logic/value
    treated = "treated",    // Treated has been changed according to logic/value
    treatedUpper = "treatedUpper",    // Treated has been changed according to logic/value
    treatedLower = "treatedLower",    // Treated has been changed according to logic/value
    budget = "budget"       // Budget has been changed according to logic/value
}

// There are obviously more logic types, but these are the only ones used at the moment
export enum ScenarioConditionLogic {
    none = "none",
    equals = "equals",
    equalsNot = "equalsNot",
    contains = "contains",
    lessOrEqual = "lessOrEqual",            // Less or equal 'value' to be true
    reducedRelative = "reducedRelative",    // Reduced by the percentage in 'value'
}

export type ScenarioCallback = () => void;
export type ScenarioStepCallback = () => void;

export type Scenario = {
    id:string,
    title:string;
    shortBody:string;
    body:string;
    levels:number;
    savedLevel: number;
    completedLevel: number;
    steps:Array<ScenarioStep>;
    environment: ScenarioEnvironment;
    winConditions:Array<ScenarioCondition>;
    winCallback:ScenarioCallback | null;
}

export function getEmptyScenario(): Scenario {
    return {
        id:"",
        title:"",
        shortBody:"",
        body:"",
        levels: 1,
        savedLevel: 0,
        completedLevel: 0,
        steps:[],
        environment: getEmptyScenarioEnvironment(),
        winConditions:[],
        winCallback:null
    }
}

export function parseScenario(json:{[key:string]:any}) : Scenario {
    let scenario = getEmptyScenario();
    scenario.id = json.id ?? "";
    scenario.title = json.title ?? "";
    scenario.shortBody = json.shortBody ?? "";
    scenario.body = json.body ?? "";
    scenario.levels = json.levels ?? 1;
    scenario.savedLevel = 0;
    scenario.completedLevel = 0;
    let stepsData = json.steps ?? [];
    for (const data of stepsData) {
        let step = parseScenarioStep(data);
        scenario.steps.push(step);
    }
    scenario.environment = parseScenarioEnvironment(json.environment ?? {});

    let winConditionsData = json.winConditions ?? [];
    for (const data of winConditionsData) {
        let condition = parseScenarioCondition(data);
        scenario.winConditions.push(condition);
    }
    return scenario;
}

export type ScenarioEnvironment = {
    // This list may contain disabled actions
    disabledActions:Array<string>;
    // This list may contain disabled menus or buttons
    disabledInteractions:Array<string>;
    // This list may contain ID:s of catchments and discharges that should be enabled
    enabledEntities:Array<string>;
    // ... and probably factors for variables like rain, groundwater etc

}
export function getEmptyScenarioEnvironment(): ScenarioEnvironment {
    return {
        disabledActions: [],
        disabledInteractions: [],
        enabledEntities: []
    }
}

function parseScenarioEnvironment(json:{[key:string]:any}) {
    let result = getEmptyScenarioEnvironment();
    result.disabledActions = json.disabledActions ?? [];
    result.disabledInteractions = json.disabledInteractions ?? [];
    result.enabledEntities = json.enabledEntities ?? [];
    return result;
}

export type ScenarioStep = {
    type:ScenarioStepType;
    title:string;
    body:string;
    anchor:string;
    placement:ScenarioStepPlacement;
    stepCondition: ScenarioCondition;
    stepCallback: ScenarioStepCallback | null;
}

export function getEmptyScenarioStep():ScenarioStep {
    return {
        type:ScenarioStepType.none,
        title:"",
        body:"",
        anchor:"",
        placement:ScenarioStepPlacement.none,
        stepCondition: getEmptyScenarioCondition(),
        stepCallback: null,
    }
}

function parseScenarioStep(json:{[key:string]:any}) : ScenarioStep {
    let step = getEmptyScenarioStep();
    step.type = (json.type ?? "none") as ScenarioStepType;
    step.title = json.title ?? "";
    step.body = json.body ?? "";
    step.anchor = json.anchor ?? "";
    step.placement = (json.placement ?? "none") as ScenarioStepPlacement;
    step.stepCondition = parseScenarioCondition(json.stepCondition);
    return step;
}

export function scenarioStepIsWaitForWin(step: ScenarioStep):boolean {
    return (step.stepCondition.type === ScenarioConditionType.win)
}

export type ScenarioCondition = {
    type:ScenarioConditionType;
    logic:ScenarioConditionLogic;
    id:string;
    value:any;
    level: number;
    resetAtStart:boolean;
    resetAtStop:boolean;
}

export function getEmptyScenarioCondition() : ScenarioCondition {
    return {
        type:ScenarioConditionType.none,
        id:"",
        logic:ScenarioConditionLogic.none,
        value:0,
        resetAtStart:true,
        resetAtStop:true,
        level: 1,
    };
}

function parseScenarioCondition(json:{[key:string]:any}) : ScenarioCondition {
    let result = getEmptyScenarioCondition();
    result.type = (json.type ?? "none") as ScenarioConditionType;
    result.logic = (json.logic ?? "none") as ScenarioConditionLogic;
    result.id = (json.id ?? "") as string;
    result.value = json.value;
    result.resetAtStart = (json.resetAtStart ?? true) as boolean;
    result.resetAtStop = (json.resetAtStop ?? true) as boolean;
    result.level = (json.level ?? 1)
    return result;
}


