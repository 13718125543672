import {GaugeView, GaugeViewMode} from "../common/GaugeView";
import * as React from "react";
import {Box} from "@mui/material";
import {AppTheme} from "../../AppTheme";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {FlowDataContext, FlowDataSet, FlowDataTags, getGlobalValueFromRegionalData} from "../../models/FlowContext";
import {getFlowReduction} from "../../models/FlowContextUtils";
import {formatNumber} from "../../utils/textUtils";

export type GaugeParameters = {
    min: number
    max: number
    current: number
    reduction: number
}
export function GetEmptyGaugeParameters() : GaugeParameters {
    return {
        current: 0, max: 0, min: 0, reduction: 0
    }
}

export interface ShowGaugeViewProps extends React.HTMLProps<any>  {
    title: string;
    selectedId: string;
    flowData:{[key:string]:FlowDataSet}
    useRelativeValues: boolean
}

export function CompareGaugeView(props:ShowGaugeViewProps) {
    const {inflowSelection, overflowSelection, partlyTreatedSelection} = useContext(FlowDataContext);
    const {t} = useTranslation()
    const [inflowParams, setInflowParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());
    const [overflowParams, setOverflowParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());
    const [partlyTreatedParams, setPartlyTreatedParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());


    useEffect(() => {

        // We may get the selectedId before the flow data is loaded
        if (!props.flowData[props.selectedId]) {
            return;
        }

        let params = GetEmptyGaugeParameters();
        let reduction:number
        let current:number


        current = getGlobalValueFromRegionalData(props.flowData[props.selectedId], FlowDataTags.totalInflowPercentage)
        reduction = getFlowReduction(props.flowData, props.selectedId, inflowSelection, props.useRelativeValues)
        params.reduction = reduction;
        params.current = current
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setInflowParams(params)

        params = GetEmptyGaugeParameters();
        current = getGlobalValueFromRegionalData(props.flowData[props.selectedId], FlowDataTags.totalOverflowPercentage)
        reduction = getFlowReduction(props.flowData, props.selectedId, overflowSelection, props.useRelativeValues)
        params.reduction = reduction;
        params.current = current
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setOverflowParams(params)

        params = GetEmptyGaugeParameters();
        let currentSelection = (partlyTreatedSelection === FlowDataTags.partiallyUpper) ?
            FlowDataTags.totalPartiallyUpperPercentage : FlowDataTags.totalPartiallyLowerPercentage;
        current = getGlobalValueFromRegionalData(props.flowData[props.selectedId], currentSelection)

        reduction = getFlowReduction(props.flowData, props.selectedId, partlyTreatedSelection, props.useRelativeValues)
        params.reduction = reduction;
        params.current = current
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setPartlyTreatedParams(params)

    },[inflowSelection, overflowSelection, partlyTreatedSelection, props.flowData, props.selectedId, props.useRelativeValues])

    return (
        <Box className={"box-content-col"}>
            <p className={'field-header'} style={{width:"100%", textAlign:"center"}}>{props.title}</p>
            <Box style={{width:'100%', flex:1}}>
                <Box style={{width:'100%', height:"60%", margin:"0px 0px -20px 0px"}}>
                    <GaugeView id={"inflow-gauge"} mode={GaugeViewMode.reduction} color={AppTheme.palette.globalInflowDark}
                               min={inflowParams.min} max={inflowParams.max}
                               current={inflowParams.current} reduction={inflowParams.reduction}/>
                </Box>
                <Box id={"inflow-gauge-index"} className={'gauge'} style={{width:'100%'}}>
                    <p>{formatNumber(inflowParams.current)}</p>
                    <p className={'gauge-legend-sel'}>{t("Simulator_InflowInfiltrationPercent")}</p>
                    <p className={'gauge-legend'}>{
                        (inflowParams.reduction <= 0) ?
                            t("Planning_Compare_Chart_No_Effect") :
                            t("Planning_Compare_Chart_Reduction") + " " + formatNumber(inflowParams.reduction) + ((props.useRelativeValues) ? "%" : " " + t("Unit_Hectar"))
                    }</p>
                </Box>
            </Box>

            <Box style={{width:'100%', flex:1}}>
                <Box style={{width:'100%', height:"60%", margin:"0px 0px -20px 0px"}}>
                    <GaugeView id={"overflow-gauge"} mode={GaugeViewMode.reduction} color={AppTheme.palette.globalOverflowDark}
                               min={overflowParams.min} max={overflowParams.max}
                               current={overflowParams.current} reduction={overflowParams.reduction}/>
                </Box>
                <Box id={"overflow-gauge-index"} className={'gauge'} style={{width:'100%'}}>
                    <p>{formatNumber(overflowParams.current)}</p>
                    <p className={'gauge-legend-sel'}>{t("Simulator_SewerOverflowPercent")}</p>
                    <p className={'gauge-legend'}>{
                        (overflowParams.reduction <= 0) ?
                            t("Planning_Compare_Chart_No_Effect") :
                            t("Planning_Compare_Chart_Reduction") + " " + formatNumber(overflowParams.reduction) + ((props.useRelativeValues) ? "%" : " m3")
                    }</p>
                </Box>
            </Box>
            <Box style={{width:'100%', flex:1}}>
                <Box style={{width:'100%', height:"60%", margin:"0px 0px -20px 0px"}}>
                    <GaugeView id={"partly-treated-gauge"} mode={GaugeViewMode.reduction} color={AppTheme.palette.globalTreatedDark}
                               min={partlyTreatedParams.min} max={partlyTreatedParams.max}
                               current={partlyTreatedParams.current} reduction={partlyTreatedParams.reduction}/>
                </Box>
                <Box id={"partly-treated-gauge-index"} className={'gauge'} style={{width:'100%'}}>
                    <p>{formatNumber(partlyTreatedParams.current)}</p>
                    <p className={'gauge-legend-sel'}>{t("Simulator_PartlyTreatedPercent")}</p>
                    <p className={'gauge-legend'}>{
                        (partlyTreatedParams.reduction <= 0) ?
                            t("Planning_Compare_Chart_No_Effect") :
                            t("Planning_Compare_Chart_Reduction") + " " + formatNumber(partlyTreatedParams.reduction) + ((props.useRelativeValues) ? "%" : " m3")
                    }</p>
                </Box>
            </Box>
        </Box>
    )
}
