import React from "react";
import {GraphOverflowView} from "../common/GraphOverflowView";
import {TimeUnitSelector} from "../common/TimeUnitSelector";
import {Box} from "@mui/material";

export function StatusGraphOverflowView() {
    const [timeUnit, setTimeUnit] = React.useState<string>("month");
    return (
        <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'center', width:"100%", height:"100%"}}>
            <Box sx={{width:'90%', flex:'1'}}>
                <GraphOverflowView id={"overflow"} timeUnit={timeUnit}/>
            </Box>
            <Box sx={{height:'60px'}}>
                <TimeUnitSelector timeUnit={timeUnit} onTimeUnitChange={(value)=>setTimeUnit(value)} />
            </Box>
        </Box>
    );
}
