import {Button, Checkbox, Divider, FormControlLabel} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {CreateNewHeaderView} from "./CreateNewHeaderView";
import {CreateNewExploitFieldsView} from "./CreateNewExploitFieldsView";
import {CreateNewDoubleInputView} from "./CreateNewDoubleInputView";
import {useTranslation} from "react-i18next";
import {Action, ActionEditMode, ActionTag, copyAction, copyActionSet} from "../../models/Action";
import Box from "@mui/material/Box";
import {
    FE_TIME_RANGE_YEAR,
    FlowDataContext, FlowDataEntry, FlowDataTags,
    getEmptyQueryData, getGlobalValueFromRegionalData,
    QueryData, queryFlowEngine
} from "../../models/FlowContext";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {DivWithRef} from "../common/DivWithRef";
import {siteIDToSiteName} from "../../models/SiteData";
import {formatCost} from "../../models/FlowContextUtils";

export interface CreateNewStep2Props extends React.HTMLProps<any>  {
    id:string;
    onCancel:()=>void;
    onPrevious:()=>void;
    onSave:()=>void;
}


export function CreateNewStep2View(props:CreateNewStep2Props) {
    const [selectedAction, setSelectedAction] = useState<Action | null>(null)
    const [selectedZones, setSelectedZones] = useState<Array<string>>([]);
    const {editActionSet, setEditActionSet, periodSelection} = useContext(FlowDataContext);
    const [absoluteValues, setAbsoluteValues] = useState<FlowDataEntry>({})

    const [totalCost, setTotalCost] = useState<number>(0);

    const {setScenarioEvent} = useContext(ScenarioDataContext)

    const {availableZones} = useContext(FlowDataContext);
    const {t} = useTranslation();

    useEffect( () => {
        // We may have one or many actions to change, but we're selecting the first one
        // and let it represent all of them. We copy back the data when done
        if (!editActionSet) {
            return;
        }
        let firstAction: Action | null = null;
        let zones:Array<string> = [];
        let newActions:Action[] = [];
        let actionsChanged = false;
        for (const a of editActionSet.actions) {
            if (a.editMode === ActionEditMode.edit || a.editMode === ActionEditMode.add) {
                if (!firstAction) {
                    firstAction = copyAction(a);
                }
                if (a.zone === "all") {
                    actionsChanged = true;
                    for (const available of availableZones) {
                        let newAction = copyAction(a);
                        newAction.zone = available.id;
                        newActions.push(newAction);
                        zones.push(available.id);
                    }
                } else {
                    newActions.push(a);
                    zones.push(a.zone);
                }
            }
        }
        if (actionsChanged) {
            let cpy = copyActionSet(editActionSet);
            cpy.actions = newActions;
            setEditActionSet(cpy);
        }
        setSelectedAction(firstAction);
        setSelectedZones(zones);

        // If we only have one zone we can find out the absolute values
        if (zones.length === 1) {
            let query:QueryData = getEmptyQueryData();
            query.id = zones[0];
            query.timeRange = FE_TIME_RANGE_YEAR;
            query.period = periodSelection;
            query.fields = [FlowDataTags.cost, FlowDataTags.separatedPipeLength, FlowDataTags.combinedPipeLength,
                FlowDataTags.hardSurfaceArea, FlowDataTags.drainagesFaultyConnected,
                FlowDataTags.inactiveDuplicateFraction];

            // Also, calculate cost for current action set
            if (editActionSet) {
                query.actionSet = editActionSet;
            }
            queryFlowEngine(query).then((fd) => {
                if (fd.years.length > 0) {
                    setAbsoluteValues(fd.years[0]);
                }
                setTotalCost(getGlobalValueFromRegionalData(fd, FlowDataTags.cost))
            });
        } else {
            // Just get total cost
            let query:QueryData = getEmptyQueryData();
            query.id = zones[0];
            query.timeRange = FE_TIME_RANGE_YEAR;
            query.period = periodSelection;
            query.fields = [FlowDataTags.cost];

            if (editActionSet) {
                query.actionSet = editActionSet;
            }
            queryFlowEngine(query).then((fd) => {
                setTotalCost(getGlobalValueFromRegionalData(fd, FlowDataTags.cost))
            });
        }


    }, [editActionSet, setEditActionSet, availableZones, periodSelection]);

    function clampPercentage(val:number) : number {
        return Math.max(Math.min(val, 1.0), 0.0)
    }
    function actionPropChanged(prop:string, value:any) {
        if (!selectedAction) {
            return;
        }
        let a = copyAction(selectedAction);
        let eventKey = prop;
        let eventVal:any = 0;
        switch (prop) {
            case "implementation":
                a.implementation = clampPercentage(Number(value) / 100.0);
                eventVal = a.implementation;
                // Values are copied on edit of implementation
                a.propertiesDisconnectedPercentage = a.implementation;
                a.imperviousSurfacePercentage = a.implementation;
                break;
            case "propertiesDisconnectedPercentage":
                // But not the other way around, implementation is not changed
                a.propertiesDisconnectedPercentage = clampPercentage(Number(value) / 100.0)
                eventVal = a.propertiesDisconnectedPercentage;
                break;
            case "imperviousSurfacePercentage":
                // But not the other way around, implementation is not changed
                a.imperviousSurfacePercentage = clampPercentage(Number(value) / 100.0)
                eventVal = a.imperviousSurfacePercentage;
                break;
            case "includeSPipes":
                a.includeSPipes = value as boolean;
                eventVal = a.includeSPipes;
                break;
            case "connectionPointExists":
                a.connectionPointExists = value as boolean;
                eventVal = a.connectionPointExists;
                break;
            case "extraCost":
                a.extraCost = Number(value)
                eventVal = a.extraCost;
                break;
            case "extraCostReason":
                a.extraCostReason = value
                eventVal = a.extraCostReason;
                break;
        }
        setSelectedAction(a);
        if (props.id.length > 0) {
            setScenarioEvent(props.id + "-" + eventKey, eventVal)
        }
    }
    function saveActionsAndReturn() {
        if (!editActionSet || !selectedAction) {
            return;
        }
        let cpy = copyActionSet(editActionSet);
        let newActions:Array<Action> = [];

        for (const a of cpy.actions) {
            if (a.editMode !== ActionEditMode.add && a.editMode !== ActionEditMode.edit) {
                newActions.push(a);
            } else {
                // Replace with the edit actions data
                let cpyAction = copyAction(selectedAction);
                // But keep zone and id from original
                cpyAction.zone = a.zone;
                cpyAction.id = a.id;
                cpyAction.editMode = ActionEditMode.none;
                newActions.push(cpyAction);
            }
        }
        cpy.actions = newActions;
        setEditActionSet(cpy);
        setScenarioEvent("create-2-save", true);

        // And navigate back
        props.onSave();
    }

    function cancelActionsAndReturn() {
        if (!editActionSet || !selectedAction) {
            return;
        }
        let cpy = copyActionSet(editActionSet);
        let newActions:Array<Action> = [];

        for (const a of cpy.actions) {
            if (a.editMode !== ActionEditMode.add) {
                // Remove all added actions, but keep the rest. Edit actions have not been changed yet so just reset edit mode
                a.editMode = ActionEditMode.none;
                newActions.push(a);
            }
        }
        cpy.actions = newActions;
        setEditActionSet(cpy);

        // And navigate back
        props.onCancel();
    }
    function deleteActionsAndReturn() {
        if (!editActionSet || !selectedAction) {
            return;
        }
        let cpy = copyActionSet(editActionSet);
        let newActions:Array<Action> = [];

        for (const a of cpy.actions) {
            if (a.editMode !== ActionEditMode.edit) {
                newActions.push(a);
            }
        }
        cpy.actions = newActions;
        setEditActionSet(cpy);
        setScenarioEvent("create-2-delete", true);

        // And navigate back
        props.onSave();
    }

    function isEditingExistingAction() : boolean {
        if (!selectedAction) {
            return false;
        }
        return selectedAction.editMode === ActionEditMode.edit && selectedZones.length === 1;

    }

    function ActionSpecificLayout() {
        if (!selectedAction) {
            return null;
        }
        let location:string
        if (selectedZones.length === 1) {
            location =  siteIDToSiteName(selectedZones[0]);
        } else {
            // TODO: No localization exist
            location = "flera områden";
        }
        let header:string = ""

        // Control what is visible in layout or not
        let showImplementation:boolean = false;
        let showDisconnected:boolean = false;
        let showImpervious:boolean = false;

        let showSeparatedCheckbox:boolean = false;
        let showMisconnectionsCheckbox:boolean = false;

        // Calculate the left hand absolute values
        let absImplementationValue:number = 0;
        let absImperviousValue:number;
        let absDisconnectedValue:number;

        absDisconnectedValue = (absoluteValues[FlowDataTags.drainagesFaultyConnected] ?? 0) * selectedAction.propertiesDisconnectedPercentage;
        absDisconnectedValue = Math.round(absDisconnectedValue);
        absImperviousValue = (absoluteValues[FlowDataTags.hardSurfaceArea] ?? 0) * selectedAction.imperviousSurfacePercentage;

        let implementationUnit = t("Unit_Meters");
        let implementationTitle = t("Simulation_EditAction_PipeLengthKLabel")

        switch (selectedAction.action) {
            case ActionTag.separatePipes:
                header = t("Planning_Create_ActionList_SeperatePipes");
                absImplementationValue = (absoluteValues[FlowDataTags.combinedPipeLength] ?? 0) * selectedAction.implementation;
                showImplementation = true;
                showSeparatedCheckbox = true;
                showDisconnected = true;
                showImpervious = true;
                break;
            case ActionTag.activateDuplicated:
                header = t("Planning_Create_ActionList_ActivateDuplicate");
                showDisconnected = true;
                showImpervious = true;
                break;
            case ActionTag.fixConnections:
                header = t("Planning_Create_ActionList_CorrectMiss");
                implementationTitle = t("Simulation_EditAction_NoPropertiesLabel");
                implementationUnit = t("Unit_Pieces");
                absImplementationValue = (absoluteValues[FlowDataTags.drainagesFaultyConnected] ?? 0) * selectedAction.implementation;
                showImplementation = true;
                showDisconnected = true;
                showImpervious = true;
                showMisconnectionsCheckbox = true;
                break;
            case ActionTag.reliningSeparated:
                header = t("Planning_Create_ActionList_SealSPipes");
                absImplementationValue = (absoluteValues[FlowDataTags.separatedPipeLength] ?? 0) * selectedAction.implementation;
                showImplementation = true;
                break;
            case ActionTag.reliningCombined:
                header = t("Planning_Create_ActionList_SealKPipes");
                absImplementationValue = (absoluteValues[FlowDataTags.combinedPipeLength] ?? 0) * selectedAction.implementation;
                showImplementation = true;
                break;
            case ActionTag.blueGreen:
                header = t("Planning_Create_ActionList_BlueGreenStormwater");
                implementationUnit = t("Unit_Hectar");
                implementationTitle = "";
                showImplementation = true;
                absImplementationValue = (absoluteValues[FlowDataTags.hardSurfaceArea] ?? 0) * selectedAction.implementation;
                showDisconnected = false;
                showImpervious = false;
                break;
            default:
        }

        return (
            <Box className={"box-col"}>
                <p className={"field-header"}>{header + " i " + location}</p>

                {/* The main implementation, used by all actions except activate duplicate */}
                {(showImplementation) ?
                    <CreateNewDoubleInputView
                        id={"create-implementation"}
                        heading={implementationTitle}
                        typeLeft={"numeric"}
                        valueLeft={absImplementationValue.toFixed(2)}
                        suffixLeft={implementationUnit}
                        typeRight={"numeric"}
                        valueRight={(selectedAction.implementation * 100.0).toFixed(0)}
                        suffixRight={"%"}
                        onBlurRight={(value)=>{actionPropChanged("implementation", value)}}
                    /> : null
                }

                {/* Checkbox used by separate pipes */}
                {(showSeparatedCheckbox) ? (
                    <DivWithRef id={"create-implementation-include"}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size={"small"} style={{height:"20px"}}
                                    checked={selectedAction.includeSPipes}
                                    onChange={(event) => {actionPropChanged("includeSPipes", event.target.checked)}}
                                />
                            }
                            label={<span style={{fontSize:"15px"}}>{t("Planning_Create_QuickEdit_SPipe")}</span>}
                        />
                    </DivWithRef>) : null
                }
                {/* Checkbox used by misconnections */}
                {(showMisconnectionsCheckbox) ? (
                <FormControlLabel
                    control={
                        <Checkbox
                            size={"small"} style={{height:"20px"}}
                            checked={selectedAction.connectionPointExists}
                            onChange={(event) => {actionPropChanged("connectionPointExists", event.target.checked)}}
                        />
                    }
                    label={<span style={{fontSize:"15px"}}>{t("Planning_Create_QuickEdit_Connection")}</span>}
                />) : ""}

                {(showImplementation && (showDisconnected || showMisconnectionsCheckbox)) ? (
                    <Box className={"box-content-row"}>
                        <Divider style={{width: "100%"}}/>
                    </Box>) : null
                }

                {(showDisconnected) ? (
                        <CreateNewDoubleInputView
                            id={"properties-disconnected_percentage"}
                            heading={t("Simulation_EditAction_NoDisconnectedPropertiesLabel")}
                            typeLeft={"numeric"}
                            typeRight={"numeric"}
                            suffixLeft={t("Unit_Pieces")}
                            valueLeft={absDisconnectedValue.toString()}
                            suffixRight={"%"}
                            valueRight={(selectedAction.propertiesDisconnectedPercentage * 100.0).toFixed(0)}
                            onBlurRight={(value)=>{actionPropChanged("propertiesDisconnectedPercentage", value)}}
                        />) : null
                }
                {(showImpervious) ? (
                    <CreateNewDoubleInputView
                        id={"impervious-surface-percentage"}
                        heading={t("Simulation_EditAction_HardMadeAreaLabel")}
                        typeLeft={"numeric"}
                        typeRight={"numeric"}
                        suffixLeft={t("Unit_Hectar")}
                        valueLeft={absImperviousValue.toString()}
                        suffixRight={"%"}
                        valueRight={(selectedAction.imperviousSurfacePercentage * 100.0).toFixed(0)}
                        onBlurRight={(value)=>{actionPropChanged("imperviousSurfacePercentage", value)}}
                    />) : null
                }
            </Box>
        );
    }

    function CostRow() {
        if (totalCost > 0) {
            return <p className={"cost"}><b>{t("Planning_Cost")}:</b>{formatCost(totalCost)}</p>
        }
        return null;
    }

    return (
        <Box className={"box-content-col-padding"} style={{gap:"0"}}>
            <CreateNewHeaderView step={2} />
            {/* ---------- Body ---------- */}
            <Box className={"box-content-auto"} style={{margin:"8px 0 8px 0"}}>
                <Box className={"box-col"}>
                    <CreateNewExploitFieldsView />
                    <ActionSpecificLayout />
                    <CreateNewDoubleInputView
                        id={"extra-cost"}
                        heading={"Extra kostnad"}
                        typeLeft={"numeric"}
                        labelLeft={t("Simulation_EditAction_AdditionalCostLabel")}
                        labelRight={t("Simulation_EditAction_ReasonForAdditionalCostLabel")}
                        onBlurLeft={(value)=>{actionPropChanged("extraCost", value)}}
                        onBlurRight={(value)=>{actionPropChanged("extraCostReason", value)}}
                    />
                </Box>
            </Box>
            {/* ---------- Footer ---------- */}
            <CostRow />
            <Box className={"footer-with-buttons"}>
                {
                    isEditingExistingAction() ?
                        <Button
                        onClick={()=>{deleteActionsAndReturn()}}
                        variant={"outlined"}
                        >{t("Planning_Create_EditAction_Delete")}</Button>
                        : null
                }
                <Button
                    onClick={()=>{cancelActionsAndReturn()}}
                    variant={"outlined"}
                >{t("Planning_Create_AddAction_Cancel")}</Button>
                <Button
                    onClick={()=>{props.onPrevious()}}
                    variant={"outlined"}
                >{t("Planning_Create_EditAction_Cancel")}</Button>
                <DivWithRef id={"create-2-save"}>
                    <Button
                        onClick={()=>{saveActionsAndReturn()}}
                        variant={"outlined"}
                        id={"create-2-save"}
                    >{t("Planning_Create_EditAction_Save")}
                    </Button>
                </DivWithRef>
            </Box>
        </Box>
    );
}
