import React, {useContext, useState} from "react";
import {Box, Button, ButtonGroup} from "@mui/material";
import { GraphGlobalView } from "../common/GraphGlobalView";
import {useTranslation} from "react-i18next";
import {FE_TYPE_CATCHMENTS, FE_TYPE_DISCHARGES, FlowDataContext} from "../../models/FlowContext";

export interface StatusCatchmentGraphsProps extends React.HTMLProps<any>  {
    editMode:boolean
}

export function StatusCatchmentGraphsView(props:StatusCatchmentGraphsProps) {
    const {inflowSelection, overflowSelection, systemSelection} = useContext(FlowDataContext);

    const {t} = useTranslation();
    const [toggle, setToggle] = useState<string>("inflow");

    return (
        <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'center', width:"100%", height:"100%"}}>
            <Box sx={{width:'90%', flex:'1'}}>
                {
                    (toggle === "inflow") ?
                        <GraphGlobalView id="inflow" label={""} type={FE_TYPE_CATCHMENTS} field={inflowSelection} system={systemSelection} editMode={props.editMode}/> :
                        <GraphGlobalView id="overflow" label={""} type={FE_TYPE_DISCHARGES} field={overflowSelection} system={"all"} editMode={props.editMode}/>
                }
            </Box>
            <Box sx={{height:'60px'}}>
                <ButtonGroup variant="outlined">
                    <Button
                        variant={(toggle==="inflow") ? "contained" : "outlined"}
                        style={{textTransform: 'none'}}
                        onClick={()=>{setToggle("inflow")}}
                    >{t("Simulator_InflowInfiltration")}
                    </Button>
                    <Button
                        variant={(toggle==="overflow") ? "contained" : "outlined"}
                        style={{textTransform: 'none'}}
                        onClick={()=>{setToggle("overflow")}}
                    >{t("Simulator_Overflow")}
                    </Button>
                </ButtonGroup>
            </Box>
        </Box>
    )
}
