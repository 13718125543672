import {useContext, useEffect, useRef} from "react";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export function DivWithRef(props:any) {
    const {setScenarioElement} = useContext(ScenarioDataContext)

    const componentRef = useRef<HTMLElement>()

    useEffect(() => {
        console.log("setScenarioElement:" + props.id);
        console.log(componentRef);
        setScenarioElement(props.id, componentRef)
    }, [setScenarioElement, props.id])

    return <div
        style={props.style}
        id = {props.id}
        ref={node => componentRef.current = node ? node : undefined}
    >
        {props.children}
    </div>



}
