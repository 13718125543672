import {Box, Paper} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    FlowDataContext,
    FlowDataSet,
    queryFlowEngineForScenarioStates
} from "../../models/FlowContext";
import {
    ScenarioDataContext
} from "../../models/ScenarioContext";
import {MissionSelectorView} from "./MissionSelectorView";
import {MissionPopup} from "./MissionPopup";
import {AppTheme} from "../../AppTheme";
import {useTranslation} from "react-i18next";
import {MissionMetersView} from "./MissionMetersView";
import {getDefaultActionSet} from "../../models/Action";

export function MissionBarView() {

    const {actionSets, currentActionSet, periodSelection} = useContext(FlowDataContext);
    const {scenarios, currentScenario, setCurrentScenario, setScenarioElement} = useContext(ScenarioDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext)

    const [selectorOpen, setSelectorOpen] = React.useState<boolean>(false);
    const [flowData, setFlowData] = useState<{[key:string]:FlowDataSet}>({});

    const {t} = useTranslation();

    useEffect(() => {
        if (!currentScenario) {
            // Hide all
            return;
        }
        let actionSet = (!currentActionSet) ? getDefaultActionSet() : currentActionSet;

        queryFlowEngineForScenarioStates([actionSet], periodSelection).then((result) => {
            setFlowData(result);
        });
    },[actionSets, currentActionSet, currentScenario, periodSelection])

    // Auto start the welcome scenario
    useEffect(() => {
        if (currentScenario) {
            return
        }
        for (const sc of scenarios) {
            if (sc.id === "01-welcome" && sc.savedLevel === 0) {
                setCurrentScenario(sc)
            }
        }
    },[currentScenario, scenarios, setCurrentScenario])

    function selectNewScenario() {
        // setCurrentScenario(null);
        setSelectorOpen(true);
    }


    let buttonRef = React.useRef<HTMLDivElement | null>(null);
    setScenarioElement("mission-button", buttonRef)

    let barRef = React.useRef<HTMLDivElement | null>(null);

    return(
        <Paper sx={{width:"100%", height:"100%"}}>
            <Box ref={barRef} sx={{
                display: "flex", flexDirection: 'row', justifyContent: 'flex-start',  alignItems: 'center',
                borderTop:"1px solid lightgray",
                borderBottom:"1px solid lightgray",
                width:"100%", height:"100%"}}
            >
                <Box
                    style={{display:"flex", justifyContent:"center", alignItems:"center",
                        height:"100%", width:"120px",
                        padding:"4px 16px 4px 16px",
                        borderRight:"1px solid lightgray",
                        color:(selectorOpen) ? "white" : AppTheme.palette.hilite,
                        backgroundColor:(selectorOpen) ? AppTheme.palette.hilite : "white"}}
                    id={"mission-button"}
                    ref={buttonRef}
                    onClick={() => {setScenarioEvent('mission-button', true);selectNewScenario()}}
                ><p style={{pointerEvents:"none"}}><b>{t("ScenarioUI:scenarios_scenario_button")}</b></p></Box>
                <Box>
                    <MissionMetersView
                        selectedActionSet={(currentActionSet) ? currentActionSet : getDefaultActionSet()}
                        flowData={flowData}
                    />
                </Box>
            </Box>
            <MissionSelectorView open={selectorOpen} onOK={()=>setSelectorOpen(false)} onCancel={()=>setSelectorOpen(false)}/>
            <MissionPopup />
        </Paper>
    )
}
