import React, {useContext, useEffect, useLayoutEffect} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {
    FE_FIELD_GROUP_INFLOW,
    FE_ID_GLOBAL, FE_TIME_RANGE_ALL,
    FlowDataContext, FlowDataEntry, FlowDataSet, getEmptyQueryData, InflowKeys, QueryData,
    queryFlowEngine
} from "../../models/FlowContext";
import { useTranslation } from 'react-i18next';
import {TimeUnit} from "@amcharts/amcharts5/.internal/core/util/Time";
import {Root} from "@amcharts/amcharts5";
import {AppTheme} from "../../AppTheme";
import {ColumnSeries} from "@amcharts/amcharts5/xy";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export interface InflowGraphProps extends React.HTMLProps<any>  {
    id: string;
    timeUnit: string;
}

export function GraphInflowView(props:InflowGraphProps) {
    const {currentActionSet, selectedCatchment, periodSelection} = useContext(FlowDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext);
    const {t} = useTranslation();
    const [root, setRoot] = React.useState<Root>();

    useLayoutEffect(() => {
        let r = am5.Root.new("graph-" + props.id);
        setRoot(r);
    }, [props.id]);

    useEffect(() => {
        if (!root || !t) {
            return;
        }

        let area = FE_ID_GLOBAL;
        let relativeValues = true;

        if (selectedCatchment) {
            area = selectedCatchment;
            relativeValues = false;
        }

        let query:QueryData = getEmptyQueryData();
        query.id = area;
        query.timeRange = FE_TIME_RANGE_ALL;
        query.period = periodSelection;
        query.fields = FE_FIELD_GROUP_INFLOW;
        queryFlowEngine(query).then((fd:FlowDataSet) => {

            let yMax = 100;
            let yLabel = ""
            if (!relativeValues) {
                switch (props.timeUnit) {
                    case "detail":
                        yLabel = "m3/" + t("Planning_Status_Graph_Resolution_Second");
                        yMax = calculateAbsValues(fd.detail);
                        // Round up to nearest 10
                        yMax = Math.ceil(yMax / 10) * 10;
                        break;
                    case "day":
                        yLabel = "m3/" + t("Planning_Status_Graph_Resolution_Day");
                        yMax = calculateAbsValues(fd.days);
                        // Round up to nearest 100
                        yMax = Math.ceil(yMax / 100) * 100;
                        break;
                    case "month":
                        yLabel = "m3/" + t("Planning_Status_Graph_Resolution_Month");
                        yMax = calculateAbsValues(fd.months);
                        // Round up to nearest 25000
                        yMax = Math.ceil(yMax / 1000) * 1000;
                        break;
                    case "year":
                        yLabel = "m3/" + t("Planning_Status_Graph_Resolution_Year");
                        yMax = calculateAbsValues(fd.years);
                        // Round up to nearest 100000
                        yMax = Math.ceil(yMax / 10000) * 10000;
                        break;
                }
            }
            root.container.children.clear();

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    layout: root.verticalLayout
                })
            );

            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    baseInterval: {
                        timeUnit: props.timeUnit as TimeUnit,
                        count: 1
                    }
                })
            );
            let xr = xAxis.get('renderer', null);
            if (xr) {
                xr.labels.template.set('fontSize', 12);
                xr.labels.template.set('paddingTop', 10);
            }

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    max: yMax,
                    strictMinMax: true,
                    numberFormatter:(relativeValues) ? am5.NumberFormatter.new(root, {numberFormat:"#'%'"}) : am5.NumberFormatter.new(root, {numberFormat:"#,###"}),
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );
            let yr = yAxis.get('renderer', null);
            if (yr) {
                yr.labels.template.set('fontSize', 12);
            }

            if (!relativeValues) {
                // OK, this is super hacky
                let xAbs = -70
                if (props.timeUnit === "detail") {
                    xAbs = -50
                } else if (props.timeUnit === "year") {
                    xAbs = -90
                }

                yAxis.axisHeader.children.push(am5.Label.new(root, {
                    text: yLabel,
                    rotation: -90,
                    fontSize: 12,
                    fontWeight: "bold",
                    position: "absolute",
                    x: xAbs,
                    y: 200
                }));
            }


            let slowSettings = {
                name: t("Planning_Status_Graph_InflowInfiltration_LeakDrain"),
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "percentageOfProducedFlowIsIndirectRainWater",
                valueXField: "date",
                stacked: true,
                fill: am5.color(AppTheme.palette.infiltration)
            };

            let sewerSettings = {
                name: t("Planning_Status_Graph_InflowInfiltration_Spill"),
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "percentageOfProducedFlowIsSewerWater",
                valueXField: "date",
                stacked: true,
                fill: am5.color(AppTheme.palette.sewage)
            };
            let fastSettings = {
                name: t("Planning_Status_Graph_InflowInfiltration_Stormwater"),
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "percentageOfProducedFlowIsDirectRainWater",
                valueXField: "date",
                stacked: true,
                fill: am5.color(AppTheme.palette.inflow)
            };

            if (props.timeUnit === "day" || props.timeUnit === "detail") {
                let slowSeries = chart.series.push(
                    // Slow/leakage
                    am5xy.LineSeries.new(root, slowSettings)
                );
                slowSeries.fills.template.setAll({
                    fillOpacity: 1.0,
                    visible: true
                });

                let sewerSeries = chart.series.push(
                    // Sewer
                    am5xy.LineSeries.new(root, sewerSettings)
                );
                sewerSeries.fills.template.setAll({
                    fillOpacity: 1.0,
                    visible: true
                });
                let fastSeries = chart.series.push(
                    // Fast or storm water
                    am5xy.LineSeries.new(root, fastSettings)
                );
                fastSeries.fills.template.setAll({
                    fillOpacity: 1.0,
                    visible: true
                });
                slowSeries.data.setAll((props.timeUnit === "day") ? fd.days : fd.detail);
                sewerSeries.data.setAll((props.timeUnit === "day") ? fd.days : fd.detail);
                fastSeries.data.setAll((props.timeUnit === "day") ? fd.days : fd.detail);
                // Also add zoom for daily & detail
                chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "zoomX"
                }));
                let cursor = chart.get("cursor");
                if (cursor) {
                    cursor.lineX.setAll({
                        visible: false
                    });
                    cursor.lineY.setAll({
                        visible: false
                    });
                }

            } else {
                let slowSeries = chart.series.push(
                    // Slow/leakage
                    am5xy.ColumnSeries.new(root, slowSettings)
                );
                let sewerSeries = chart.series.push(
                    // Sewer
                    am5xy.ColumnSeries.new(root, sewerSettings)
                );
                let fastSeries = chart.series.push(
                    // Fast or storm water
                    am5xy.ColumnSeries.new(root, fastSettings)
                );

                if (props.timeUnit === "month") {
                    slowSeries.data.setAll(fd.months);
                    sewerSeries.data.setAll(fd.months);
                    fastSeries.data.setAll(fd.months);
                } else {
                    slowSeries.data.setAll(fd.years);
                    sewerSeries.data.setAll(fd.years);
                    fastSeries.data.setAll(fd.years);
                }
            }

            // unshift adds it on top
            let legend = chart.children.unshift(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                y: am5.percent(-2),
            }));

            legend.data.setAll(chart.series.values);

            // Add custom functionality to legend
            legend.itemContainers.each(function(itemContainer) {
                // itemContainer.events.removeType("click");
                itemContainer.events.on("click", function(event) {
                    let dataItem = event.target.dataItem;
                    if (dataItem && dataItem.dataContext) {
                        let col = (dataItem.dataContext as ColumnSeries).get("valueYField", null)
                        if (col) {
                            setScenarioEvent(props.id + "-legend", col);
                        }
                    }
                });
            });
            legend.labels.template.setAll({
                fontSize: 12
            });

            // Make stuff animate on load
            chart.appear(1000, 100).then();
        });
    }, [root, t, props.timeUnit, props.id, setScenarioEvent, selectedCatchment, currentActionSet, periodSelection]);


    useEffect(() => {
        if (props.id && props.id.length > 0) {
            setScenarioEvent(props.id + "-time", props.timeUnit)
        }
    },[props.id, props.timeUnit, setScenarioEvent])


    function calculateAbsValues(dataArray:FlowDataEntry[]) : number {
        let max = 0;
        let total = 0;
        for (const entry of dataArray) {
            entry[InflowKeys.percentDirect] = (entry[InflowKeys.percentDirect] * entry[InflowKeys.totalAmount]) / 100
            entry[InflowKeys.percentIndirect] = (entry[InflowKeys.percentIndirect] * entry[InflowKeys.totalAmount]) / 100
            entry[InflowKeys.percentSewer] = (entry[InflowKeys.percentSewer] * entry[InflowKeys.totalAmount]) / 100
            total = entry[InflowKeys.percentDirect] + entry[InflowKeys.percentIndirect] + entry[InflowKeys.percentSewer];
            max = (total > max) ? total : max;
        }
        return max
    }


    return (
        <div id={"graph-" + props.id} style={{width:"100%", height:"100%"}} />
    );
}
