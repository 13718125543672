import React, {useContext, useEffect, useRef} from "react";
import {MenuItem, TextField} from "@mui/material";
import "../../App.css"
import {ScenarioDataContext} from "../../models/ScenarioContext";

export type SelectionEntry = {
    value:any;
    title:string;
}

export interface TextFieldSelectSmallProps extends React.HTMLProps<any>  {
    onSelectChange:(key:string, value:string)=>void;
    selectionList:Array<SelectionEntry>;
    value?: string;
    label?: string;
    style?: object;
    id?: string;
}

export function TextFieldSelectSmall(props:TextFieldSelectSmallProps) {
    const {setScenarioElement,setScenarioEvent} = useContext(ScenarioDataContext)
    const componentRef = useRef<HTMLElement>()

    useEffect(() => {
        if (props.id && props.id.length > 0) {
            setScenarioElement(props.id, componentRef);
            if (props.value) {
                setScenarioEvent(props.id, props.value)
            }
        }
    },[setScenarioElement, setScenarioEvent, props.id, props.value])


    /*
    function doSelectChange(id:string, value:string) {
        props.onSelectChange(id as string, value)
    }
     */

    function getMenuItems() : JSX.Element[] {
        return props.selectionList.map((value, idx) => {
            let id = props.id + "-" + value.value;
            return (
                <MenuItem value={value.value} id={id} key={id}>{value.title}</MenuItem>
            );
        });
    }



    return (
        <TextField
            id={props.id}
            ref={node => componentRef.current = node ? node : undefined}
            select
            value={props.value}
            label={props.label}
            style={props.style}
            size={"small"}
            variant="outlined"
            onChange={(event) => {
                props.onSelectChange(props.id as string, event.target.value)
            }}
        >
            {getMenuItems()}
        </TextField>
    )
}
