import './App.css';
import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import {StatusView} from "./components/status/StatusView";
import {LeftBarView} from "./components/LeftBarView";
import {CreateView} from "./components/create/CreateView";
import * as React from "react";
import {useEffect} from "react";
import {MissionBarView} from "./components/missions/MissionBarView";
import * as am5 from "@amcharts/amcharts5";
import {FlowContext} from "./models/FlowContext";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {AppTheme} from "./AppTheme"
import Box from "@mui/material/Box";
import "leaflet/dist/leaflet.css"
import {ScenarioContext} from "./models/ScenarioContext";
import {Paper} from "@mui/material";
import {CompareView} from "./components/compare/CompareView";
import "./utils/i18n.ts"
import {OverlayContext} from "./components/OverlayContext";
import {ModalContainer} from "./components/ModalContainer";
import {ScenariosView} from "./components/scenarios/ScenariosView";

export function App() {

    am5.addLicense("AM5C310203636");

    useEffect(() => {
    }, []);


    return (
        <BrowserRouter>
        <FlowContext>
            <ScenarioContext>
                <OverlayContext>
                <ThemeProvider theme={AppTheme}>
                    <div className="App" style={{width:"100vw", height:"100vh", backgroundColor:"#f8f8f8"}} >
                        {/* Grid for header, body, footer */}
                        <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
                            {/* Header */}
                            <Paper sx={{
                                display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                height:"60px", width:"100%", padding:"4px 8px 8px 16px"}}
                            >
                                <img style={{height:"30px", paddingTop:"4px"}} src={"fcf-logo.svg"} alt={"logo"}/>
                                <h2 style={{fontSize:20, color:"darkgray", paddingTop:"14px", paddingLeft:"16px"}}>Future City Flow Onboarding</h2>
                            </Paper>

                            {/* Body */}
                            <Box sx={{display: "flex", flex:"1", width:"100%"}}>
                                {/* Left menu */}
                                <LeftBarView/>
                                {/* Content */}
                                <Box sx={{flex:"1", height:"100%"}}>
                                    <Routes>
                                        <Route path="/"
                                            element={<Navigate to="/status" replace />}
                                        />
                                        <Route path="status" element={<StatusView/>} />
                                        <Route path="create" element={<CreateView />} />
                                        <Route path="show" element={<CompareView />} />
                                        <Route path="scenarios" element={<ScenariosView />} />
                                    </Routes>
                                </Box>

                            </Box>
                            <Box sx={{height:"72px", width:"100%"}}>
                                {/* Footer */}
                                <MissionBarView/>
                            </Box>
                        </Box>
                        <ModalContainer />
                    </div>
                </ThemeProvider>
                </OverlayContext>
            </ScenarioContext>
        </FlowContext>
        </BrowserRouter>
    );
}
export default App

