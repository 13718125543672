import React, {useState} from "react";
import {ActionSet} from "../models/Action";
import {Tile} from "./common/Tile";

export enum TileType {
    none,
    error
}

interface OverlayDataContextInterface {
    simulationActionSet:ActionSet | null
    setSimulationActionSet:(value:ActionSet | null) => void
    tileType:TileType;
    setTileType:(tt:TileType) => void;
    tileText:string;
    setTileText:(text:string) => void;
}


export const OverlayDataContext = React.createContext<OverlayDataContextInterface>({
    simulationActionSet:null,
    setSimulationActionSet:() => {},
    tileType:TileType.none,
    setTileType:() => {},
    tileText:"",
    setTileText:() => {}
});

export function OverlayContext(props:any) {
    const [simulationActionSet, setSimulationActionSet] = useState<ActionSet | null>(null)
    const [tileType, setTileType] = useState<TileType>(TileType.none)
    const [tileText, setTileText] = useState<string>("")

    const overlayContextValues = {
        simulationActionSet, setSimulationActionSet,
        tileType, setTileType,
        tileText, setTileText
    }
    return (
        <OverlayDataContext.Provider value={overlayContextValues}>{props.children}</OverlayDataContext.Provider>
    );
}
