import React from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup} from "@mui/material";

export interface TimeUnitSelectorProps extends React.HTMLProps<any>  {
    timeUnit: string;
    onTimeUnitChange:(value:string)=>void;
}

export function TimeUnitSelector(props:TimeUnitSelectorProps) {
    const {t} = useTranslation();

    return (
        <ButtonGroup variant="outlined">
            <Button
                variant={props.timeUnit === "year" ? "contained" : "outlined"}
                onClick={()=>props.onTimeUnitChange("year")}
            >{t("Planning_Status_Graph_Resolution_Year")}</Button>
            <Button
                variant={props.timeUnit === "month" ? "contained" : "outlined"}
                onClick={()=>props.onTimeUnitChange("month")}
            >{t("Planning_Status_Graph_Resolution_Month")}</Button>
            <Button
                variant={props.timeUnit === "day" ? "contained" : "outlined"}
                onClick={()=>props.onTimeUnitChange("day")}
            >{t("Planning_Status_Graph_Resolution_Day")}</Button>
            <Button
                variant={props.timeUnit === "detail" ? "contained" : "outlined"}
                onClick={()=>props.onTimeUnitChange("detail")}
            >{t("Planning_Status_Graph_Resolution_Detailed")}</Button>
        </ButtonGroup>
    );
}
