import React from "react";
import {Action} from "../../models/Action";
import {Box} from "@mui/material";
import {ActionIcon} from "../common/ActionIcon";
import ButtonBase from "@mui/material/ButtonBase";
import {siteIDToSiteName} from "../../models/SiteData";

export interface CreateActionRowProps extends React.HTMLProps<any>  {
    actionData:Action[];
    onSelectAction:(a:Action)=>void;
}

export function CreateActionRowView(props:CreateActionRowProps) {
    return (
        <Box className={"action-row"}>
            <Box className={"body"}>
                <p className={"text"}>{(props.actionData.length > 0) ? siteIDToSiteName(props.actionData[0].zone) : ""}</p>
            </Box>
            {
                props.actionData.map((action) => {
                    return (
                        <Box className={"icon"}>
                            <ButtonBase style={{width: "100%", height: "100%"}} onClick={() => {
                                props.onSelectAction(action)
                            }}>
                                <ActionIcon action={action.action}/>
                            </ButtonBase>
                        </Box>

                    )
                })
            }
        </Box>
    )
}
