import {Box, Button, Dialog, DialogContent} from "@mui/material";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {OverlayDataContext} from "../OverlayContext";
import {DivWithRef} from "../common/DivWithRef";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {FlowDataContext} from "../../models/FlowContext";

export interface CreateNewSimulationProps extends React.HTMLProps<any>  {
    open:boolean
    onClose:() => void
}

export function CreateNewSimulationDialog(props:CreateNewSimulationProps) {
    const {setScenarioEvent} = useContext(ScenarioDataContext)
    const {setSimulationActionSet} = useContext(OverlayDataContext)
    const {currentActionSet} = useContext(FlowDataContext)
    const {t} = useTranslation();

    function doSimulation() {
        setSimulationActionSet(currentActionSet)
        setScenarioEvent("run-simulation", true);
        props.onClose()

    }
    function showStatus() {
        props.onClose()
    }
    function showCompare() {
        props.onClose()
    }
    function createNew() {
        props.onClose()
    }
    function goBack() {
        props.onClose()
    }

    return (
    <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
        >
        <DialogContent>
            <Box className={"box-content-col"} style={{alignItems:"center"}}>


                <Box style={{width:"100%"}}>
                    <Button variant={"text"} onClick={()=>{goBack()}}>{t("Planning_Create_SaveModal_Button_GoBack")}</Button>
                </Box>

                <Box style={{paddingTop:"16px"}}><p className={"simulation-dialog-header"}>{t("Planning_Create_SaveModal_Title")}</p></Box>
                <Box style={{paddingTop:"16px"}}><p className={"simulation-dialog-body"}>{t("Planning_Create_SaveModal_SimulateText")}</p></Box>
                <DivWithRef id={"run-simulation"}>
                    <Button
                        onClick={()=>{doSimulation()}}
                        variant={"contained"}
                    >{t("Planning_Create_SaveModal_SimulateButton")}</Button>
                </DivWithRef>

                <Box style={{paddingTop:"32px"}}><p className={"simulation-dialog-body"}>{t("Planning_Create_SaveModal_Options")}</p></Box>
                <Box className={"box-row"} style={{justifyContent:"center"}}>
                    <Button
                        onClick={()=>{showStatus()}}
                        variant={"outlined"}
                    >{t("Planning_Create_SaveModal_Button_Status")}</Button>
                    <Button
                        onClick={()=>{showCompare()}}
                        variant={"outlined"}
                    >{t("Planning_Create_SaveModal_Button_Compare")}</Button>
                    <Button
                        onClick={()=>{createNew()}}
                        variant={"outlined"}
                    >{t("Planning_Create_SaveModal_Button_Create")}</Button>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
    )
}
