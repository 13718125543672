import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"

i18n
    .use(Backend)
    .use(XHR) // <---- add this
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        // lng:'sv-SE',
        fallbackLng: 'en',
        supportedLngs: ['sv', 'en'],
        ns: ['App', 'Missing','Scenarios', 'ScenarioUI'],
        defaultNS: 'App',
        debug: true,
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    }).then();


export default i18n;
