import {crc32} from "../utils/crc32";
import { v4 as uuidv4 } from 'uuid';
import {getI18n} from "react-i18next";

export enum ActionTag {
    none = "none",
    separatePipes = "separatePipes",
    activateDuplicated = "activateDuplicated",
    fixConnections = "fixConnections",
    reliningSeparated = "reliningSeparated",
    reliningCombined = "reliningCombined",
    blueGreen = "blueGreen"
}
export enum ActionEditMode {
    none = "none",
    edit = "edit",
    add = "add"
}

export enum FactorTag {
    factorInflow = "factorInflow",
    factorInfiltration = "factorInfiltration",
    factorSewage = "factorSewage",
}

export enum ActionSetState {
    created = "created",
    ongoing = "ongoing",
    simulated = "simulated",
}

export type ActionSet = {
    name:string;
    description:string;
    id:string;
    readOnly:boolean;
    createdAt: string;
    createdBy: string;
    states: {[key:string]:ActionSetState}
    progress: number;
    actions:Array<Action>
}
export function getEmptyActionSet(id = "") {
    return {
        name:"",
        description:"",
        id:id,
        readOnly:false,
        createdAt:new Date().toISOString().split('T')[0],  // SQL format date, sloppy since it doesn't care about time zone
        createdBy:"",
        states: {
            FE_PERIOD_WET:ActionSetState.created,
            FE_PERIOD_NORMAL:ActionSetState.created
        },
        progress:0,
        actions:[]
    }
}

export function getDefaultActionSet() : ActionSet {
    let d = getEmptyActionSet("default");
    d.name = getI18n().t("Planning_Create_Current_Situation");
    d.readOnly = true;
    d.states = {
        FE_PERIOD_WET:ActionSetState.simulated,
        FE_PERIOD_NORMAL: ActionSetState.simulated
    };
    return d;
}
export function copyActionSet(source:ActionSet): ActionSet {
    let result:ActionSet = {...source} as ActionSet
    result.actions = [];
    source.actions.forEach((value) => {
        result.actions.push(copyAction(value));
    });
    return result;
}

export function copyActionSetWithNewID(source:ActionSet): ActionSet {
    let result = copyActionSet(source);
    result.id = uuidv4();
    return result;
}

export async function simulateActionSet(as:ActionSet, stateKey:string, progressCallback:(progress:number) => void): Promise<ActionSet> {
    let cpy = copyActionSet(as);
    cpy.states[stateKey] = ActionSetState.ongoing;
    cpy.progress = 0
    for (let i = 0; i < 10; i++) {
        cpy.progress = i * 0.1;
        progressCallback(cpy.progress);
        await new Promise(resolve => setTimeout(resolve, 500))
    }
    cpy.progress = 1
    progressCallback(cpy.progress);
    cpy.states[stateKey] = ActionSetState.simulated;
    return cpy;
}
export function resetActionSetStates(set:ActionSet) {
    set.states ={
        FE_PERIOD_WET:ActionSetState.created,
        FE_PERIOD_NORMAL:ActionSetState.created
    }
    set.progress = 0;
}

// Resets the editing flags of an action set
export function resetActionSet(set:ActionSet) {
    for (const action of set.actions) {
        action.editMode = ActionEditMode.none;
    }
}


export type Action = {
    action:ActionTag;
    id:string;
    zone:string;
    // Fraction of total
    implementation:number;

    factorInflow:number;
    factorInfiltration:number;
    factorSewage:number;

    // These parameters are not all used by all actions
    includeSPipes:boolean;
    // These parameters are not all used by all actions
    connectionPointExists:boolean;
    // Fraction of total properties disconnected by action
    propertiesDisconnectedPercentage:number;
    // Fraction of total impervious surface removed by action
    imperviousSurfacePercentage:number;

    totalPipeLength: number;
    separatedPipeLength: number;
    combinedPipeLength: number;
    hardSurfaceArea: number;
    amountOfFaultyConnectedDrainages: number;
    amountOfCorrectlyConnectedDrainages: number;
    inactiveDuplicateFraction: number;

    // Advanced settings
    stormWaterEfficiencyPercentage: number,
    leakDrainEfficiencyPercentage: number,
    privateOwnedPercentage: number

    extraCost:number;
    extraCostReason:string;

    editMode:ActionEditMode;
}
    export function newAction(actionTag:ActionTag = ActionTag.none, id:string = "") : Action {
    let a:Action = {
        action: actionTag,
        id: id,
        zone: "",
        editMode: ActionEditMode.none,
        implementation: 0,
        connectionPointExists: true,
        includeSPipes: true,
        imperviousSurfacePercentage: 0,
        propertiesDisconnectedPercentage: 0,
        extraCost: 0,
        extraCostReason: "",
        // factors, defaults
        factorInfiltration: 1,
        factorInflow: 1,
        factorSewage: 1,

        totalPipeLength: 0,
        separatedPipeLength: 0,
        combinedPipeLength: 0,
        hardSurfaceArea: 0,
        amountOfFaultyConnectedDrainages: 0,
        amountOfCorrectlyConnectedDrainages: 0,
        inactiveDuplicateFraction: 0,

        // Advanced settings, defaults
        stormWaterEfficiencyPercentage: 1,
        leakDrainEfficiencyPercentage: 1,
        privateOwnedPercentage: 0.5
    };
    // Create a new ID if none is passed in
    if (id.length === 0) {
        a.id = uuidv4();
    }
    return a;
}

export function copyAction(a: Action) : Action {
    return ({...a} as Action);
}

export function copyActionWithNewID(a: Action) :Action {
    let cpy = copyAction(a);
    cpy.id = uuidv4();
    return cpy;
}

export function calculateActionChecksum(a:Action) : number {
    let str = a.action + a.implementation + a.zone;
    str += (a.factorInflow.toString() + a.factorInfiltration.toString() + a.factorSewage.toString());
    str += (a.includeSPipes.toString() + a.propertiesDisconnectedPercentage.toString() + a.imperviousSurfacePercentage.toString());
    str += (a.extraCost.toString() + a.extraCostReason.toString());

    return crc32(str);
}

export function calculateActionChecksums(actions:Array<Action> | null) : number {
    if (actions === null) {
        return 0;
    }
    let str = "";
    actions.forEach((value) =>{
        str += calculateActionChecksum(value).toString();
    });
    if (str.length === 0) {
        str = "empty";
    }
    return crc32(str);
}

