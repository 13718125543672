import {Box, Paper} from "@mui/material";
import {useContext, useState} from "react";
import {ScenariosTopBarView} from "./ScenariosTopBarView";
import {ScenariosListView} from "./ScenariosListView";
import {FlowDataContext} from "../../models/FlowContext";
import {ActionSet} from "../../models/Action";

export enum ScenarioSelectedView {
    all = "all",
    created = "created",
    ongoing = "ongoing",
    simulated = "simulated"
}

export function ScenariosView() {
    const [selectedView, setSelectedView] = useState<ScenarioSelectedView>(ScenarioSelectedView.all)
    const {actionSets, setActionSets} = useContext(FlowDataContext);

    function showFilters() {

    }
    function deleteScenario(id:string) {
        if (id === "default") {
            return;
        }
        let newSets:ActionSet[] = [];
        for (const set of actionSets) {
            if (set.id === id) {
                continue
            }
            newSets.push(set)
        }
        setActionSets(newSets)
    }

    function showScenario(id:string) {

    }
    function simulateScenario(id:string) {

    }
    return (
        <Paper className={"paper-full"}>
            <Box className={"box-content-col"}>
                <ScenariosTopBarView
                    selectedView={selectedView}
                    onSelectedViewChange={(value) => {setSelectedView(value)}}
                    onShowFilters={showFilters}
                />
                <ScenariosListView
                    style = {{flex:1, width:"calc(100% - 16px)", margin:"8px 8px 8px 8px"}}
                    selectedView={selectedView}
                    onDeleteScenario={deleteScenario}
                    onShowScenario={showScenario}
                    onSimulateScenario={simulateScenario}
                />
            </Box>
        </Paper>
    )
}
