import * as React from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {MapView} from "../common/MapView";
import {StatusCatchmentGraphsView} from "./StatusCatchmentGraphsView";
import {useTranslation} from "react-i18next";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {FlowDataContext} from "../../models/FlowContext";
import {DivWithRef} from "../common/DivWithRef";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export interface StatusCatchmentsProps extends React.HTMLProps<any>  {
    editMode:boolean;
    id:string;
}

export function StatusCatchmentsView(props:StatusCatchmentsProps) {
    const {selectedCatchment, setSelectedCatchment, selectedCatchmentsForEdit, setSelectedCatchmentsForEdit,
        selectedDischarge, setSelectedDischarge} = useContext(FlowDataContext);
    const {setScenarioEvent, addMessageListener, removeMessageListener} = useContext(ScenarioDataContext)
    const {t} = useTranslation();

    const [highlightedCatchments, setHighlightedCatchments] = useState<string[]>([])

    const tabHeight = "30px";
    const panelHeight = "calc(100% - 30px)";

    const selectedCatchmentsRef = useRef<string[]>([])

    const highlightMap = useCallback((val:any)=>  {
        let highlighted:string[] = [];
        if (val) {
            highlighted = (val as string).split(",")
        }
        setHighlightedCatchments(highlighted);

    }, []);

    useEffect(() => {
        addMessageListener("highlight-map", "map", highlightMap);
        return () => {
            removeMessageListener("highlight-map", "map")
        }
    }, [removeMessageListener, addMessageListener, highlightMap, props.editMode])

    function catchmentSelected(val:string) {
        if (props.editMode) {
            // We're in some weird scope where this is called,
            // I think the entire structure is copied, including selectedCatchmentsForEdit, it's always the same
            let c = [...selectedCatchmentsRef.current];
            let idx = c.findIndex((element) => (element === val));
            if (idx !== -1) {
                c.splice(idx,1);
            } else {
                c.push(val)
            }
            selectedCatchmentsRef.current = c;
            setSelectedCatchmentsForEdit(c)
        } else {
            setSelectedDischarge(null);
            setSelectedCatchment(val);
        }
    }
    function dischargeSelected(id:string) {
        if (props.editMode) {
            return;
        }
        setSelectedCatchment(null);
        setSelectedDischarge(id);
    }
    function getSelectedCatchments():string[] | null {
        if (props.editMode) {
            return selectedCatchmentsForEdit
        }
        return (selectedCatchment) ? [selectedCatchment] : null
    }
    function getSelectedDischarges():string[] | null {
        if (props.editMode) {
            return null;
        }
        return (selectedDischarge) ? [selectedDischarge] : null
    }

    function tabSelected(id:string) {
        setScenarioEvent(props.id + "-tab", id)
    }

    return (
        <DivWithRef id={props.id} style={{width:'100%', height:'100%'}}>
        <Tabs style={{width:'100%', height:'100%'}}>
            <TabList>
                <Tab onClick={() => tabSelected(props.id + "-map")} style={{width:"20%", height:tabHeight, fontSize:'15px'}}>{t("Planning_Status_Map")}</Tab>
                <Tab onClick={() => tabSelected(props.id + "-graph")} style={{width:"20%", height:tabHeight, fontSize:'15px'}}>{t("Planning_Compare_Chart")}</Tab>
            </TabList>
            <TabPanel style={{width:"100%", height:panelHeight}}>
                <MapView
                    id={"map-status"}
                    editMode={props.editMode}
                    highlightedCatchments={highlightedCatchments}
                    selectedCatchments={getSelectedCatchments()}
                    selectedDischarges={getSelectedDischarges()}
                    onSelectCatchment={catchmentSelected}
                    onSelectDischarge={dischargeSelected}
                />
            </TabPanel>
            <TabPanel style={{width:"100%", height:panelHeight}}>
                <StatusCatchmentGraphsView editMode={props.editMode}/>
            </TabPanel>
        </Tabs>
        </DivWithRef>
    );

}
