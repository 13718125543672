import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as solidStar} from "@fortawesome/free-solid-svg-icons";
import {AppTheme} from "../../AppTheme";
import {faStar as outlineStar} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import {Box} from "@mui/material";

export interface StarsProps extends React.HTMLProps<any>  {
    maxLevel: number;
    level: number;
}

export function Stars(props:StarsProps) {

    function getStars(total: number, filled: number) {
        let stars = []
        for (let i = 0; i < total; i++) {
            if (i < filled) {
                stars.push(<FontAwesomeIcon id={"level_" + i} icon={solidStar} style={{color: AppTheme.palette.mapDarkYellow}}/>)
            } else {
                stars.push(<FontAwesomeIcon id={"level_" + i} icon={outlineStar} style={{color: AppTheme.palette.mapDarkYellow}}/>)
            }
        }
        return stars
    }

    return (
        <Box className={"box-row"} style={{justifyContent:"flex-end", width:"80px", paddingBottom:"6px"}}>
            {getStars(props.maxLevel, props.level)}
        </Box>
    )
}
