import {GeoJSON, MapContainer, TileLayer} from "react-leaflet";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import "leaflet/dist/leaflet.css"
import {httpGet} from "../../api/HttpServices";
import L, {GeoJSON as LeafletGeoJSON, LatLng, Layer, LeafletEvent} from "leaflet";
import {AppTheme} from "../../AppTheme";

import {
    FE_ID_LOCAL,
    FE_TIME_RANGE_YEAR, FE_TYPE_CATCHMENTS,
    FE_TYPE_DISCHARGES,
    FE_TYPE_TREATMENT_PLANT,
    FlowDataContext,
    FlowDataSet,
    getEmptyFlowDataSet,
    getEmptyQueryData,
    QueryData,
    queryFlowEngine
} from "../../models/FlowContext";
import {siteThresholdLevels} from "../../models/SiteData";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export interface MapViewProps extends React.HTMLProps<any>  {
    id:string;
    highlightedCatchments?:string[];
    selectedCatchments:Array<string> | null;
    selectedDischarges:Array<string> | null;
    onSelectCatchment: (catchmentID:string) => void;
    onSelectDischarge: (dischargeID:string) => void;
    editMode: boolean;
}

export function MapView(props:MapViewProps) {
    const {inflowSelection, overflowSelection, editActionSet, systemSelection} = useContext(FlowDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext);

    const [catchments, setCatchments] = useState<any>(null);
    const [discharges, setDischarges] = useState<any>(null);

    const [flowData, setFlowData] = useState<FlowDataSet>(getEmptyFlowDataSet());
    const [typeMap, setTypeMap] = useState<{ [key: string]: string }>({});
    const [zoom, setZoom] = useState<number>(12)

    const geoJsonLayerRef = useRef<LeafletGeoJSON>(null);

    useEffect(() => {
        let query: QueryData = getEmptyQueryData();
        query.id = FE_ID_LOCAL;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.fields = ["type", inflowSelection, overflowSelection];

        if (props.editMode && editActionSet) {
            query.actionSet = editActionSet;
        }
        if (props.editMode) {
            query.system = systemSelection
        }


        queryFlowEngine(query).then((fd) => {
            setFlowData(fd);

            let map: { [key: string]: string } = {};
            for (const feature of fd.years) {
                map[(feature["id"] ?? "unknown")] = feature["type"] ?? "unknown"
            }
            setTypeMap(map);

            Promise.all([
                httpGet("/catchments/catchments.geojson"),
                httpGet("/catchments/discharges.geojson"),
            ]).then((responses) => {
                if (responses[0].status === 200) {
                    setCatchments(responses[0].result);
                }
                if (responses[1].status === 200) {
                    setDischarges(responses[1].result);
                }
            });
        });

    }, [props.editMode, inflowSelection, overflowSelection, editActionSet, systemSelection]);

    useEffect(() => {
        if (props.highlightedCatchments && props.highlightedCatchments.length > 0) {
            setZoom(13)
        } else {
            setZoom(12);
        }
    }, [props.highlightedCatchments])


    const getTooltip = useCallback((id: string, symbol: string): string => {
        let result = "";
        for (const entry of flowData.years) {
            if (entry.id !== id) {
                continue;
            }
            let name = entry["name"] ?? "No name";
            let val = entry[symbol] ?? 0;
            // TODO: We have to get the correct texts depending on inflow selected
            result = "";
            if (name === id) {
                result = "<p style='margin: 0 0 0 0'><b>" + name + "</b></p>";
            } else {
                result = "<p style='margin: 0 0 0 0'><b>" + name + "</b> (" + id + ")</p>";
            }
            result += "<p style='margin: 0 0 0 0'>" + val.toFixed(1) + "</p>";
        }
        return result;
    }, [flowData.years]);

    const getFeatureColor = useCallback((id: string, type: string, symbol: string): string => {
        let useDark = (type === "overflow");
        let result = (useDark) ? AppTheme.palette.mapDarkGreen : AppTheme.palette.mapGreen;
        for (const entry of flowData.years) {
            if (entry.id !== id) {
                continue;
            }
            let val = entry[symbol] ?? 0;
            let thresholds = siteThresholdLevels[symbol] ?? null;
            if (thresholds) {
                if (val >= thresholds.red) {
                    result = (useDark) ? AppTheme.palette.mapDarkRed : AppTheme.palette.mapRed;
                } else if (val >= thresholds.yellow) {
                    result = (useDark) ? AppTheme.palette.mapDarkYellow : AppTheme.palette.mapYellow;
                }
                break;
            }
        }
        return result;
    }, [flowData.years]);

    const getStyleForCatchment = useCallback((feature: any): any => {
        let id = feature.properties.Id ?? "";
        let stroke = 1;
        let strokeCol = "darkgray"
        if (props.selectedCatchments && props.selectedCatchments.includes(id)) {
            // selected
            stroke = 2;
            strokeCol = "black"
        } else if (props.highlightedCatchments && props.highlightedCatchments.includes(id)) {
            stroke = 2;
            strokeCol = "purple"
        }
        return {
            stroke: true,
            weight: stroke,
            color: strokeCol,
            fill: true,
            fillColor: getFeatureColor(id, "inflow", inflowSelection),
            fillOpacity: 0.9
        }
    }, [getFeatureColor, inflowSelection, props.highlightedCatchments, props.selectedCatchments]);

    function getStyleForDischarge(feature: any): any {
        // I have no idea why Id isn't used here. WeirId it is...
        let id = feature.properties.WeirId ?? "";
        let type = typeMap[id] ?? "unknown";

        let visible = feature.properties.ShowInMap ?? false;
        if (type !== FE_TYPE_DISCHARGES && type !== FE_TYPE_TREATMENT_PLANT) {
            visible = false;
        }

        if (!visible) {
            return {
                fill: false,
                stroke: false
            }
        }
        let stroke = 1;
        let strokeCol = "darkgray"

        if (props.selectedDischarges) {
            if (props.selectedDischarges.includes(id)) {
                // selected
                stroke = 2;
                strokeCol = "black"
            }
        }

        if (type === FE_TYPE_TREATMENT_PLANT) {
            return {
                stroke: true,
                weight: stroke,
                color: strokeCol,
                radius: 8,
                fill: true,
                fillColor: AppTheme.palette.overflow,
                fillOpacity: 0.9
            }
        }

        return {
            stroke: true,
            weight: stroke,
            color: strokeCol,
            radius: 8,
            fill: true,
            fillColor: getFeatureColor(id, "overflow", overflowSelection),
            fillOpacity: 0.9
        }
    }

    function onEachDischarge(feature: GeoJSON.Feature, layer: Layer) {
        // I have no idea why Id isn't used here. WeirId it is...
        if (!feature.properties) {
            return;
        }
        if (!(feature.properties.ShowInMap ?? false)) {
            return;
        }
        let id = (feature.properties.WeirId ?? "unknown");

        let type = typeMap[id] ?? "unknown"
        if (type !== FE_TYPE_DISCHARGES && type !== FE_TYPE_TREATMENT_PLANT) {
            return;
        }

        layer.on({
            mouseover: function () {
                layer.bindTooltip(getTooltip(id, overflowSelection)).openTooltip()
            },
            click: clickDischarge
        });
    }

    function pointToLayer(feature: GeoJSON.Feature, latlng: LatLng): Layer {
        let id = (feature.properties) ? (feature.properties.Id ?? "unknown") : "unknown";
        let type = typeMap[id] ?? "unknown"
        if (type === FE_TYPE_DISCHARGES) {
            return L.circleMarker(latlng, {});
        } else if (type === FE_TYPE_TREATMENT_PLANT) {
            // TODO: Should be icon marker here, can't get it to work
            return L.circleMarker(latlng, {});
            /*
            let myIcon = L.icon({
                iconUrl: 'my-icon.png',
                shadowUrl: 'my-icon.png',
                iconSize:     [25, 25], // width and height of the image in pixels
                shadowSize:   [35, 20], // width, height of optional shadow image
                iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
                shadowAnchor: [12, 6],  // anchor point of the shadow. should be offset
                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
            })
            return L.marker(latlng, { icon: myIcon })
            */
        }
        // Return invisible marker
        return L.circleMarker(latlng, {fill: false, stroke: false});
    }

    const clickCatchment = useCallback((e: LeafletEvent) => {
        let id = e.target.feature.properties.Id ?? "";
        props.onSelectCatchment(id);
        // We announce what is clicked to scenario engine
        if (props.id && props.id.length > 0) {
            setScenarioEvent(props.id, id)
        }
    }, [props, setScenarioEvent]);

    const onEachCatchment = useCallback((feature: GeoJSON.Feature, layer: Layer) => {
        let id = (feature.properties) ? (feature.properties.Id ?? "unknown") : "unknown";
        let type = typeMap[id] ?? "unknown"
        if (type !== FE_TYPE_CATCHMENTS) {
            return;
        }
        layer.on({
            mouseover: function () {
                layer.bindTooltip(getTooltip(id, inflowSelection)).openTooltip()
            },
            click: (e) => clickCatchment(e)
        });
    }, [clickCatchment, getTooltip, inflowSelection, typeMap]);


    function clickDischarge(e: LeafletEvent) {
        let id = e.target.feature.properties.WeirId ?? "";
        props.onSelectDischarge(id);
        // We announce what is clicked to scenario engine
        if (props.id && props.id.length > 0) {
            setScenarioEvent(props.id, id)
        }
    }


    return (
        <MapContainer
            key={"map-container-" + zoom.toString()}
            center={{lat: 58.28732822122984, lng: 12.286253717359122}}
            zoom={zoom}
            scrollWheelZoom={true}
            style={{height: "calc(100% - 12px)", margin: "0px 1px 0px 1px"}}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=ggOJC2KWHlXwyfrkq1KS"
            />

            {/* The key thing looks odd but is the recommended way to update the layers once we have catchments loaded */}
            <GeoJSON key={(catchments) ? "catchments" : "empty-catchments"}
                     data={catchments}
                     style={getStyleForCatchment}
                     ref={geoJsonLayerRef}
                     onEachFeature={(feature: GeoJSON.Feature, layer: Layer) => onEachCatchment(feature, layer)}

            >
            </GeoJSON>

            <GeoJSON key={(discharges) ? "discharges" : "empty-discharges"}
                     data={discharges}
                     style={getStyleForDischarge}
                     onEachFeature={(feature: GeoJSON.Feature, layer: Layer) => onEachDischarge(feature, layer)}
                     pointToLayer={pointToLayer}

            />

        </MapContainer>
    );
}
