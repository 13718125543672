import {Box, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useContext} from "react";
import {OverlayDataContext, TileType} from "../OverlayContext";
import {faStar as outlineStar} from "@fortawesome/free-regular-svg-icons";
import {AppTheme} from "../../AppTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";


export function Tile() {
    const {setTileType, tileType, tileText} = useContext(OverlayDataContext);

    function closeTile() {
        setTileType(TileType.none)
    }

    if (tileType === TileType.none) {
        return null
    }

    return (
        <Box className={"error-tile"}>
            <Box style={{width:"100%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start", pointerEvents:"auto"}}>
                <Box style={{height:"100%", display:"flex", padding:"0 16px 0 16px", flexDirection:"column", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faExclamation} size={"lg"} color={"#A34C4C"}/>
                </Box>
                <p style={{flex:1}}>{tileText}</p>
                <IconButton className={"icon"} size={"small"} onClick={() => closeTile()}>
                    <CloseIcon style={{color:"#A34C4C"}} />
                </IconButton>
            </Box>
        </Box>
    )
}
