import createTheme from "@mui/material/styles/createTheme";

declare module '@mui/material/styles' {

    interface Palette {
        hilite: string;
        showHilite: string;
        showInfoHilite: string;

        overflow: string;
        inflow: string;
        infiltration: string;
        sewage: string;

        globalInflow: string;
        globalInflowDark: string;
        globalOverflow: string;
        globalOverflowDark: string;
        globalTreated: string;
        globalTreatedDark: string;

        treatedLower: string;
        treatedUpper: string;
        mapGreen: string;
        mapDarkGreen: string;
        mapYellow: string;
        mapDarkYellow: string;
        mapRed: string;
        mapDarkRed: string;
        gaugeRed: string;
        gaugeDarkRed: string;
        gaugeGreen: string;
        gaugeDarkGreen: string;
    }
    interface PaletteOptions {
        hilite: string;
        showHilite: string;
        showInfoHilite: string;
        overflow: string;
        inflow: string;
        infiltration: string;
        sewage: string;

        globalInflow: string;
        globalInflowDark: string;
        globalOverflow: string;
        globalOverflowDark: string;
        globalTreated: string;
        globalTreatedDark: string;

        treatedLower: string;
        treatedUpper: string;
        mapGreen: string;
        mapDarkGreen: string;
        mapYellow: string;
        mapDarkYellow: string;
        mapRed: string;
        mapDarkRed: string;
        gaugeRed: string;
        gaugeDarkRed: string;
        gaugeGreen: string;
        gaugeDarkGreen: string;

    }
}

export const AppTheme = createTheme({
    palette:{
        hilite:"#4778bb",
        showHilite: "#929edd",
        showInfoHilite: "#4388cf",
        overflow: "#89b6fa",
        inflow: "#95d1d5",
        infiltration: "#89b6fa",
        sewage: "#bf7bf9",

        globalInflow:"#95d1d5",
        globalInflowDark: "#65a0a4",

        globalOverflow: "#bf7bf9",
        globalOverflowDark: "#8c4cc5",

        globalTreated: "#929edd",
        globalTreatedDark: "#4778bb",

        treatedLower:"#89b6fa",
        treatedUpper:"#bf7bf9",

        mapGreen: "#bafc8e",
        mapDarkGreen: "#89fb4f",
        mapYellow: "#fbe183",
        mapDarkYellow: "#f7c945",
        mapRed: "#ef8683",
        mapDarkRed: "#eb3527",

        gaugeRed: "#ff9090",
        gaugeDarkRed: "#c96063",
        gaugeGreen: "#adf3b7",
        gaugeDarkGreen: "#7cc087"

    },

    components: {
        // Not really sure what this changes
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: '15px'
                }
            }
        },
        // Text input text
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '15px',
                }
            }
        },
        // Text input label
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '15px',
                }
            }
        },
        // Text input selection, menu item
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '15px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    }
});
