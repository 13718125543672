import {Box, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {OverlayDataContext} from "../OverlayContext";
import {FE_PERIOD_NORMAL, FlowDataContext} from "../../models/FlowContext";
import {AppTheme} from "../../AppTheme";
import CloseIcon from '@mui/icons-material/Close';
import {ActionSet, ActionSetState, simulateActionSet} from "../../models/Action";


export function SimulationProgressDialog() {
    const {t} = useTranslation();
    const {simulationActionSet, setSimulationActionSet} = useContext(OverlayDataContext);
    const {periodSelection, currentActionSet, setCurrentActionSet} = useContext(FlowDataContext);

    const [progress, setProgress] = useState<number>(0.0 );
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        if (!simulationActionSet ||
            simulationActionSet.states[periodSelection] === ActionSetState.simulated) {  // To avoid eternal loop on currentActionSet change
            return;
        }
        let titleString = t("Planning_Scenarios_RunningHeader")
        titleString = titleString.replace("{0}", (simulationActionSet) ? simulationActionSet.name : "")
        titleString = titleString.replace("{1}", (periodSelection === FE_PERIOD_NORMAL) ? t("Period_NormalYear") : t("Period_WetYear"))
        setTitle(titleString);
        simulateActionSet(simulationActionSet, periodSelection, setProgress).then((as:ActionSet) => {
            if (currentActionSet && currentActionSet.id === as.id) {
                // Triggers a change event for currentActionSet
                setSimulationActionSet(as);
                setCurrentActionSet(as);
            }
        })

    },[currentActionSet, periodSelection, setCurrentActionSet, setSimulationActionSet, simulationActionSet, t])

    function closeProgress() {
        setSimulationActionSet(null);
    }

    function getOngoingText(progress:number) : string {
        if (progress >= 1.0) {
            return t("Planning_Scenarios_SimulationComplete")
        }
        return "1 " + t("Planning_Scenarios_OngoingSimulations")
    }

    if (!simulationActionSet) {
        return null
    }
    return (
        <Box style={{position:"absolute", left:"16px", bottom:"16px", pointerEvents:"auto"}}>
            <Box style={{display:"flex", flexDirection:"column", alignItems:"center", width:"480px", backgroundColor:"white", border:"1px solid lightgray"}}>
                <Box style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"top"}}>
                    <IconButton size={"small"} onClick={() => closeProgress()}><CloseIcon/></IconButton>
                </Box>
                <Box style={{display:"flex", flexDirection:"column", width:"100%", padding:"4px 16px 16px 16px"}}>
                    <p className={"simulation-dialog-title"}>{title}</p>
                    <Box style={{position:"relative", height:"24px", width:"100%"}}>
                        <Box style={{position:"absolute", left:0, top:0, height:"24px", width:(progress * 100) + "%", borderRadius:"4px", backgroundColor:AppTheme.palette.gaugeDarkGreen}} />
                        <Box style={{position:"absolute", left:0, top:0, height:"24px", width:"100%", borderRadius:"4px", border:"1px solid darkgray"}} />
                    </Box>
                    <p className={"simulation-dialog-body"}>{getOngoingText(progress)}</p>
                    <p className={"simulation-dialog-body"}>{t("Planning_Scenarios_RunningHelptext")}</p>
                </Box>
            </Box>
        </Box>
    )
}
