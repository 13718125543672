import * as React from "react";
import {useContext, useEffect, useLayoutEffect} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {StatusGraphInflowView} from "./StatusGraphInflowView";
import {StatusGraphOverflowView} from "./StatusGraphOverflowView";
import {StatusGraphPartlyTreatedView} from "./StatusGraphPartlyTreatedView";
import {FlowDataContext, FlowDataSet, FlowDataTags} from "../../models/FlowContext";

import 'react-tabs/style/react-tabs.css';
import {StatusGaugeView} from "./StatusGaugeView";
import {useTranslation} from "react-i18next";
import {checkFlowConditionsWithFlowData, ScenarioDataContext} from "../../models/ScenarioContext";
import {
    getEmptyScenarioCondition,
    ScenarioCondition,
    ScenarioConditionLogic,
    ScenarioConditionType
} from "../../models/ScenarioModels";
import {getEmptyActionSet} from "../../models/Action";
import {DivWithEventDispatch} from "../common/DivWithEventDispatch";

export interface StatusGraphsProps extends React.HTMLProps<any> {
    flowData:{[key:string]:FlowDataSet};
}

export function StatusGraphsView(props:StatusGraphsProps) {
    const {currentActionSet} = useContext(FlowDataContext);
    const {currentScenario} = useContext(ScenarioDataContext);
    const {selectedCatchment, setSelectedCatchment, selectedDischarge, setSelectedDischarge, partlyTreatedSelection} = useContext(FlowDataContext);

    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    const [inflowMin] = React.useState<number>(0);
    const [inflowMax] = React.useState<number>(100);
    const [inflowGoal, setInflowGoal] = React.useState<number>(0);
    const [inflowCurrent, setInflowCurrent] = React.useState<number>(0);

    const [overflowMin] = React.useState<number>(0);
    const [overflowMax, setOverflowMax] = React.useState<number>(100);
    const [overflowGoal, setOverflowGoal] = React.useState<number>(0);
    const [overflowCurrent, setOverflowCurrent] = React.useState<number>(0);

    const [partlyMin] = React.useState<number>(0);
    const [partlyMax] = React.useState<number>(100);
    const [partlyGoal, setPartlyGoal] = React.useState<number>(0);
    const [partlyCurrent, setPartlyCurrent] = React.useState<number>(0);

    const {t} = useTranslation();

    useEffect(() => {
        // Hacky function setting the gauge max depending on current value
        function getMax(current:number) : number {
            if (current <= 2.0) {
                return 4.0;
            } else if (current <= 6.0) {
                return 8.0;
            } else if (current <= 20.0) {
                return 50.0;
            }
            return 100.0;
        }

        // Reuse the checkFlowConditions to set the gauges
        // in order to pick up the goals from a (potentially) ongoing scenario

        // Always add all three conditions (inflow, overflow, treated) with default values
        let conditions:Array<ScenarioCondition> = [];
        let condition = getEmptyScenarioCondition();
        condition.type = ScenarioConditionType.inflow;
        condition.value = 0.0;
        condition.logic = ScenarioConditionLogic.reducedRelative;
        conditions.push({...condition} as ScenarioCondition);
        condition = getEmptyScenarioCondition();
        condition.type = ScenarioConditionType.overflow;
        condition.value = 0.0;
        condition.logic = ScenarioConditionLogic.reducedRelative;
        conditions.push({...condition} as ScenarioCondition);
        condition = getEmptyScenarioCondition();
        condition.type = (partlyTreatedSelection === FlowDataTags.partiallyUpper) ? ScenarioConditionType.treatedUpper : ScenarioConditionType.treatedLower;
        condition.value = 0.0;
        condition.logic = ScenarioConditionLogic.reducedRelative;
        conditions.push({...condition} as ScenarioCondition);

        // But use goal values from existing scenario, if any
        if (currentScenario) {
            for (const c1 of currentScenario.winConditions) {
                for (const c2 of conditions) {
                    if (c1.type === c2.type) {
                        c2.logic = c1.logic;
                        c2.value = c1.value;
                    }
                }
            }
        }
        let as = getEmptyActionSet("default");
        let conditionValues = checkFlowConditionsWithFlowData(props.flowData, conditions, as, partlyTreatedSelection)
        for (const conditionValue of conditionValues) {
            if (conditionValue.type === ScenarioConditionType.inflow) {
                setInflowCurrent(conditionValue.current);
                setInflowGoal((conditionValue.goal) ? conditionValue.current - conditionValue.current * conditionValue.goal : conditionValue.current);
            } else if (conditionValue.type === ScenarioConditionType.overflow) {
                setOverflowCurrent(conditionValue.current);
                setOverflowGoal((conditionValue.goal) ? conditionValue.current - conditionValue.current * conditionValue.goal : conditionValue.current);
                setOverflowMax(getMax(conditionValue.current));
            } else if (conditionValue.type === ScenarioConditionType.treatedUpper || conditionValue.type === ScenarioConditionType.treatedLower) {
                setPartlyCurrent(conditionValue.current);
                setPartlyGoal((conditionValue.goal) ? conditionValue.current - conditionValue.current * conditionValue.goal : conditionValue.current);
            }
        }


        /*
        let currentActionId= (currentActionSet) ? currentActionSet.id : "default"
        let flowSet = props.flowData[currentActionId] ?? null;
        if (!flowSet) {
            return
        }

        setInflowCurrent(getGlobalValueFromRegionalData(flowSet, FlowDataTags.totalInflowPercentage))
        setOverflowCurrent(getGlobalValueFromRegionalData(flowSet, FlowDataTags.totalOverflowPercentage))
        setPartlyCurrent(getGlobalValueFromRegionalData(flowSet, FlowDataTags.totalPartiallyUpperPercentage))
        // setInflowGoal(conditionValue.goal);
        */

        /*
        let as = (currentActionSet) ? currentActionSet : getEmptyActionSet();
        checkFlowConditions(conditions, as).then((results) => {

            for (const conditionValue of results) {
                if (conditionValue.type === ScenarioConditionType.inflow) {
                    setInflowCurrent(conditionValue.current);
                    setInflowGoal(conditionValue.goal);
                } else if (conditionValue.type === ScenarioConditionType.overflow) {
                    setOverflowCurrent(conditionValue.current);
                    setOverflowGoal(conditionValue.goal);
                    setOverflowMax(getMax(conditionValue.current));
                } else if (conditionValue.type === ScenarioConditionType.treated) {
                    setPartlyCurrent(conditionValue.current);
                    setPartlyGoal(conditionValue.goal);
                }
            }
         */
    }, [currentActionSet, currentScenario, props.flowData, partlyTreatedSelection]);


    // Switches tab automatically when
    useLayoutEffect(() => {
        if (selectedDischarge || selectedCatchment) {
            setSelectedTab(3);
        }
    },[selectedDischarge, selectedCatchment]);


    function setSelected(val:number) {
        setSelectedCatchment(null);
        setSelectedDischarge(null);
        setSelectedTab(val);
    }
    const tabHeight = "180px";
    const panelHeight = "calc(100% - 180px)";

    return (
        <Tabs style={{width:'100%', height:'100%'}}
              selectedIndex={selectedTab}
              onSelect={(val) => setSelected(val)}
              id={"gauge-tabs"}
        >
            <TabList>
                <Tab id={"tab-inflow"} style={{width:"33%", height:tabHeight}}>
                    <DivWithEventDispatch id={"tab-click-inflow"} style={{width:"100%", height:"100%"}}>
                        <StatusGaugeView
                            label={t("Planning_Status_Gauges_InflowInfiltration_Title")}
                            id={"gauge-inflow"}
                            min={inflowMin} max={inflowMax} current={inflowCurrent} goal={inflowGoal}
                        />
                    </DivWithEventDispatch>
                </Tab>
                <Tab id={"tab-overflow"} style={{width:"33%", height:tabHeight}}>
                    <DivWithEventDispatch id={"tab-click-overflow"} style={{width:"100%", height:"100%"}}>
                        <StatusGaugeView
                            label={t("Planning_Status_Gauges_Overflow_Title")}
                            id={"gauge-overflow"}
                            min={overflowMin} max={overflowMax} current={overflowCurrent} goal={overflowGoal}
                        />
                    </DivWithEventDispatch>
                </Tab>
                <Tab id={"tab-partly"} style={{width:"33%", height:tabHeight}}>
                    <DivWithEventDispatch id={"tab-click-partly"} style={{width:"100%", height:"100%"}}>
                        <StatusGaugeView
                            label={t("Planning_Status_Gauges_PartlyTreated_Title")}
                            id={"gauge-partly"}
                            min={partlyMin} max={partlyMax} current={partlyCurrent} goal={partlyGoal}
                        />
                    </DivWithEventDispatch>
                </Tab>
                {(selectedCatchment || selectedDischarge) ?
                    <Tab style={{display:"none"}}>
                    </Tab>
                    : null
                }
            </TabList>
            <TabPanel style={{width:'100%', height:panelHeight}}>
                <StatusGraphInflowView/>
            </TabPanel>
            <TabPanel style={{width:'100%', height:panelHeight}}>
                <StatusGraphOverflowView/>
            </TabPanel>
            <TabPanel style={{width:'100%', height:panelHeight}}>
                <StatusGraphPartlyTreatedView/>
            </TabPanel>
            {(selectedCatchment) ?
                <TabPanel style={{width: '100%', height: panelHeight}}>
                    <StatusGraphInflowView/>
                </TabPanel>
                : null
            }
            {(selectedDischarge && !selectedCatchment) ?
                <TabPanel style={{width:'100%', height:panelHeight}}>
                    <StatusGraphOverflowView/>
                </TabPanel>
                : null
            }
        </Tabs>
    )
}
