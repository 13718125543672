import React from "react";
import {TextFieldSmall} from "../common/TextFieldSmall";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

export function CreateNewExploitFieldsView() {
    const {t} = useTranslation();
    return (
        <Box className={"box-content-row"}>
            <Box className={"box-col"}>
                <Box className={"box-row"}>
                    <p className={"field-label"}>{t("Planning_Create_QuickEdit_ExplTab")}</p>
                </Box>
                <Box className={"box-row"}>
                    <TextFieldSmall style={{width:"100%"}} label={t("Planning_Create_GrowthFactor_SRC").toString()} value={"1"}/>
                    <TextFieldSmall style={{width:"100%"}} label={t("Planning_Create_GrowthFactor_FRC").toString()} value={"1"}/>
                    <TextFieldSmall style={{width:"100%"}} label={t("Planning_Create_GrowthFactor_Spill").toString()} value={"1"}/>
                </Box>
            </Box>
        </Box>
    );

}
