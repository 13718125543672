import {Box} from "@mui/material";
import {SimulationProgressDialog} from "./common/SimulationProgressDialog";
import {Tile} from "./common/Tile";

export function ModalContainer() {

    return (
        <Box style={{position:"absolute", left:"0px", top:"0px", width:"100%", height:"100%", pointerEvents:"none"}}>
            <SimulationProgressDialog />
            <Tile />
        </Box>
    )
}
