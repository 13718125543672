import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import {DropDownBarView} from "../common/DropDownBarView";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {CompareScenarioSelectView} from "./CompareScenarioSelectView";
import {CompareScenarioInfoView} from "./CompareScenarioInfoView";
import {CompareTabView} from "./CompareTabView";
import {
    FlowDataContext,
    FlowDataSet,
    queryFlowEngineForScenarioStates
} from "../../models/FlowContext";
import {ActionSet} from "../../models/Action";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {CompareGaugeView} from "./CompareGaugeView";

export function CompareView() {
    const {actionSets, periodSelection} = useContext(FlowDataContext)
    const {setScenarioEvent} = useContext(ScenarioDataContext)

    const [selectedActionSets, setSelectedActionSets] = useState<string[]>(["default"]);
    const [infoActionSet, setInfoActionSet] = useState<string>("");
    const [flowData, setFlowData] = useState<{[key:string]:FlowDataSet}>({});
    const [useRelativeValues, setUseRelativeValues] = useState<boolean>(false)

    useEffect(() => {
        let actionSetsToGet:ActionSet[] = [];
        for (const actionSet of actionSets) {
            if (actionSet.id === "default") {
                continue; // The default action set is always included in query so no need to add it twice
            }
            if (selectedActionSets.includes(actionSet.id)) {
                actionSetsToGet.push(actionSet);
            }
        }
        queryFlowEngineForScenarioStates(actionSetsToGet, periodSelection).then((result) => {
            setFlowData(result);
        });

    },[actionSets, selectedActionSets, periodSelection])

    function toggleInfoActionSet(id:string) {
        if (infoActionSet === id) {
            setInfoActionSet("")
            setScenarioEvent("compare-select-info", false);
            return
        }
        let sel = [...selectedActionSets];
        let idx = sel.indexOf(id);
        if (idx === -1) {
            // Select it as well if it isn't already
            toggleSelectedActionSets(id)
        }
        setInfoActionSet(id);
        setScenarioEvent("compare-select-info", true);
    }
    function toggleSelectedActionSets(id:string) {
        if (id === "default") {
            return
        }
        let sel = [...selectedActionSets];
        let idx = sel.indexOf(id);
        if (idx > -1) {
            sel.splice(idx, 1);
            // Turn off info button as well if applicable
            if (id === infoActionSet) {
                toggleInfoActionSet(id);
            }
        } else {
            sel.push(id);
        }
        setSelectedActionSets(sel);
        setScenarioEvent("show-select-actions-count", sel.length);

    }

    function editActionSet(id:string) {

    }
    function getActionSetName(sets:ActionSet[], id:string) : string {
        for (const set of sets) {
            if (set.id === id) {
                return set.name
            }
        }
        return "";
    }

    return (
        <Box sx={{display: "flex", flexDirection: 'column', gap: "8px", padding:"8px 8px 8px 8px", justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
            <Paper style={{width:"100%"}}>
                <DropDownBarView
                    show={["period", "inflow", "overflow", "sewer-plant"]}
                    compareMode={true}
                />
            </Paper>
            <Box sx={{display: "flex", flexDirection: 'row', gap: "8px", justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
                <Box sx={{flex:4, height:'100%'}}>
                    <Box className={"box-content-col"}>
                        <Paper style={{flex:1, width:"100%"}} id="show-left-top">
                            <CompareScenarioSelectView
                                selectedActionSets={selectedActionSets}
                                infoSelection={infoActionSet}
                                onToggleSelection={toggleSelectedActionSets}
                                onEdit={editActionSet}
                                onSetInfoSelection={(val:string) => toggleInfoActionSet(val)}
                            />
                        </Paper>
                        <Paper style={{flex:1, width:"100%"}} id="show-left-bottom">
                            <CompareScenarioInfoView
                                infoSelection={infoActionSet}
                            />
                        </Paper>
                    </Box>
                </Box>
                <Box sx={{flex:12, height:'100%'}}>
                    <Paper style={{width:"100%", height:"100%"}}>
                        <CompareTabView
                            id={"map-graph-tab-view"}
                            editMode={true}
                            flowData={flowData}
                            useRelativeValues={useRelativeValues}
                            onRelativeValuesChanged={setUseRelativeValues}
                        />
                    </Paper>
                </Box>
                { (infoActionSet.length > 0) ?
                    <Box sx={{flex:3, height:'100%'}}>
                        <Paper style={{width:"100%", height:"100%"}}>
                            <CompareGaugeView
                                id={"show-gauge-view"}
                                title={getActionSetName(actionSets, infoActionSet)}
                                flowData={flowData}
                                selectedId={infoActionSet}
                                useRelativeValues={useRelativeValues}
                           />
                        </Paper>
                    </Box>
                    : null
                }
            </Box>
        </Box>
    );
}

