import React from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    Switch
} from "@mui/material";
import {useTranslation} from "react-i18next";

export interface ShowResultSwitchesProps extends React.HTMLProps<any>  {
    resultSettings: {[key:string]:boolean}
    onResultSettingChanged:(key:string, value:boolean) => void
}
export function CompareResultSwitches(props:ShowResultSwitchesProps) {
    const {t} = useTranslation()

    return (
        <Box style={{display:"flex", flexDirection:"column"}}>
            <FormGroup>
                <FormControlLabel
                    label={t("Planning_Compare_CompareGraph_Filter_Inflow").toString()}
                    control={
                    <Switch
                        id ={"inflow"}
                        checked={props.resultSettings["inflow"] ?? false}
                        onChange={(event) => {props.onResultSettingChanged("inflow", event.target.checked)}}
                    />}
                />
                <FormControlLabel
                    label={t("Planning_Compare_CompareGraph_Filter_Overflow").toString()}
                    control={
                        <Switch
                            id ={"overflow"}
                            checked={props.resultSettings["overflow"] ?? false}
                            onChange={(event) => {props.onResultSettingChanged("overflow", event.target.checked)}}
                        />}
                />
                <FormControlLabel
                    label={t("Planning_Compare_CompareGraph_Filter_TreatmentPlants").toString()}
                    control={
                        <Switch
                            id ={"partlyTreated"}
                            checked={props.resultSettings["partlyTreated"] ?? false}
                            onChange={(event) => {props.onResultSettingChanged("partlyTreated", event.target.checked)}}
                        />}
                />
                <FormControlLabel
                    label={t("Planning_Compare_CompareGraph_Filter_AbsoluteValues").toString()}
                    control={
                        <Switch
                            id ={"absolute"}
                            checked={props.resultSettings["absolute"] ?? false}
                            onChange={(event) => {props.onResultSettingChanged("absolute", event.target.checked)}}
                        />}
                />
            </FormGroup>
        </Box>
    )
}
