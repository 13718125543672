import * as React from "react";
import {FactorTag} from "../../models/Action";
import {Box} from "@mui/material";
import {TextFieldSmall} from "../common/TextFieldSmall";
import {QuickEditFactorRow} from "./CreateQuickEdit";
import {useEffect, useState} from "react";



export interface CreateQuickEditFactorRowProps extends React.HTMLProps<any>  {
    firstColWidth:number;
    catchmentID:string;
    catchmentName:string;
    onValueChange:(id:string, tag:FactorTag, value:number) => void;
    values:QuickEditFactorRow;
}


export function CreateQuickEditFactorRow(props:CreateQuickEditFactorRowProps) {
    const [rowValues, setRowValues] = useState<{ [key:string]:string }>({})
    const [actionOrder] = useState<Array<string>>(Object.keys(props.values));

    useEffect(() => {
        let asStrings: { [key:string]:string } = {};
        let val: number | undefined
        val = props.values[FactorTag.factorInflow]
        asStrings[FactorTag.factorInflow as string] = (val) ? val.toString() : "0";
        val = props.values[FactorTag.factorInfiltration]
        asStrings[FactorTag.factorInfiltration as string] = (val) ? val.toString() : "0";
        val = props.values[FactorTag.factorSewage]
        asStrings[FactorTag.factorSewage as string] = (val) ? val.toString() : "0";
        setRowValues(asStrings);

    },[props.values])

    function valueChanged(tag:FactorTag, value:string) {
        let vals = {...rowValues}
        vals[tag] = value
        setRowValues(vals);
    }

    function doBlur(tag:FactorTag) {
        let val = parseFloat(rowValues[tag])
        props.onValueChange(props.catchmentID, tag, val)
    }

    return (
        <Box style={{height:"48px"}} className={"box-content-row-padding"}>
            <Box style={{width:props.firstColWidth + "px"}}>
                <p style={{margin:"4px 0px 4px 0px", fontSize:"14px", fontWeight:(props.catchmentID === "all") ? "bold" : "normal"}}>{props.catchmentName}</p>
            </Box>
            {
                actionOrder.map((tag) => {
                    let ft:FactorTag = tag as FactorTag;
                    return <TextFieldSmall
                        key={props.catchmentID + "-" + tag}
                        id={props.catchmentID + "-" + tag}
                        onChange={(event) => {valueChanged(ft, event.currentTarget.value)}}
                        onBlur={() => doBlur(ft)}
                        value={(rowValues[ft] ?? 0).toString()}
                        style={{flex:1, fontSize:"12px"}}
                    />
                })
            }
        </Box>
    )


}
