import * as React from "react";
import {Stack} from "@mui/material";
import {SelectionEntry, TextFieldSelectSmall} from "./TextFieldSelectSmall";
import { useTranslation } from 'react-i18next';
import {useContext} from "react";
import {FE_PERIOD_NORMAL, FE_PERIOD_WET, FlowDataContext, FlowDataTags} from "../../models/FlowContext";
import "../../App.css";

export interface DropDownBarProps extends React.HTMLProps<any>  {
    show: Array<string>;
    compareMode?: boolean;
}

export function DropDownBarView(props:DropDownBarProps) {
    const {
        periodSelection, setPeriodSelection,
        overflowSelection, setOverflowSelection,
        inflowSelection, setInflowSelection,
        systemSelection, setSystemSelection,
        partlyTreatedSelection, setPartlyTreatedSelection
    } = useContext(FlowDataContext);

    const {t} = useTranslation();

    let entries = props.show.map((key) =>{
        let list:Array<SelectionEntry>;
        switch (key) {
            case "period":
                list = [
                    {value:FE_PERIOD_WET, title:t("Period_WetYear")},
                    {value:FE_PERIOD_NORMAL, title:t("Period_NormalYear")},
                ];
                return (
                    <TextFieldSelectSmall
                        id={"drop-period"}
                        key={"drop-period"}
                        style={{width:"100%"}}
                        value={periodSelection}
                        label={t("Planning_Head_Period")}
                        onSelectChange={(id, v)=>{setPeriodSelection(v);}}
                        selectionList={list}
                    />
                );
            case "inflow":
                if (props.compareMode) {
                    list = [
                        {value: "totalRainHA", title: t("KPI_TotalRainImpact")}, // Key needs to be calculated, doesn't exist in data
                        {value: "fastRainHA", title: t("KPI_FastRainImpactPerHa")},
                        {value: "slowRainHA", title: t("KPI_SlowRainImpactPerHa")},
                        {value: "groundWaterHA", title: t("KPI_GroundWaterImpactPerHa")},
                        {value: "totalDrainageHA", title: t("KPI_TotalDrainageArea")},  // Key needs to be calculated, doesn't exist in data
                        {value: "totalAmountOfInflowVolume", title: t("KPI_QAdditionalVolume")}, // Key needs to be calculated, doesn't exist in data
                    ];
                } else {
                    list = [
                        {value: "fastRainHA", title: t("KPI_FastRainImpactPerHa")},
                        {value: "slowRainHA", title: t("KPI_SlowRainImpactPerHa")},
                        {value: "groundWaterHA", title: t("KPI_GroundWaterImpactPerHa")},
                        {value: "fastRainM2PerM2", title: t("KPI_FastRainImpactPerM2")},
                        {value: "slowRainM2PerM2", title: t("KPI_SlowRainImpactPerM2")},
                        {value: "groundWaterM2PerM2", title: t("KPI_GroundWaterImpactPerM2")},
                        {value: "fastRainM2PerPe", title: t("KPI_FastRainImpactPerPopulation")},
                        {value: "slowRainM2PerPe", title: t("KPI_SlowRainImpactPerPopulation")},
                        {value: "groundWaterM2PerPe", title: t("KPI_GroundWaterImpactPerPopulation")},
                        {value: "fastRainM2PerM", title: t("KPI_FastRainImpactPerM")},
                        {value: "slowRainM2PerM", title: t("KPI_SlowRainImpactPerM")},
                        {value: "groundWaterM2PerM", title: t("KPI_GroundWaterImpactPerM")},
                    ];
                }
                return (
                    <TextFieldSelectSmall
                        id={"drop-inflow"}
                        key={"drop-inflow"}
                        style={{width:"100%"}}
                        value={inflowSelection}
                        label={t("Planning_Head_AreaEffect")}
                        onSelectChange={(id, v)=>{setInflowSelection(v);}}
                        selectionList={list}
                    />
                );
            case "system":
                list = [
                    {value:"all", title:t("SystemType_All")},
                    {value:"combined", title:t("SystemType_AreaFractionCombined")},
                    {value:"inactive", title:t("SystemType_AreaFractionInactiveDuplicate")},
                    {value:"duplicate", title:t("SystemType_AreaFractionDuplicate")},
                ];
                return (
                    <TextFieldSelectSmall
                        id={"drop-system"}
                        key={"drop-system"}
                        style={{width:"100%"}}
                        value={systemSelection}
                        label={t("SystemType_Label")}
                        onSelectChange={(id, v)=>{setSystemSelection(v);}}
                        selectionList={list}
                    />
                );

            case "overflow":
                if (props.compareMode) {
                    list = [
                        {value:FlowDataTags.overflow, title:t("KPI_OverflowVolume")}
                    ];
                } else {
                    list = [
                        {value:FlowDataTags.overflow, title:t("KPI_OverflowVolume")},
                        {value:FlowDataTags.totalOverflowPercentage, title:t("KPI_PercentageOfTotalOverflow")},
                    ];
                }
                return (
                    <TextFieldSelectSmall
                        id={"drop-overflow"}
                        key={"drop-overflow"}
                        style={{width:"100%"}}
                        value={overflowSelection}
                        label={t("Planning_Head_OverflowPoints")}
                        onSelectChange={(id, v)=>{setOverflowSelection(v);}}
                        selectionList={list}
                    />
                );
            case "sewer-plant":
                // Hard coded for now... and maybe forever
                let lowerTitle = t("KPI_USGVolumeGreaterThanLimitA").replace("{0}", "0.17");
                let upperTitle = t("KPI_USGVolumeGreaterThanLimitB").replace("{0}", "0.5");
                list = [
                    {value:FlowDataTags.partiallyLower, title:lowerTitle},
                    {value:FlowDataTags.partiallyUpper, title:upperTitle},
                ];
                return (
                    <TextFieldSelectSmall
                        id={"drop-sewer-plant"}
                        key={"drop-sewer-plant"}
                        style={{width:"100%"}}
                        value={partlyTreatedSelection}
                        label={t("Planning_Head_ARV")}
                        onSelectChange={(id, v)=>{setPartlyTreatedSelection(v);}}
                        selectionList={list}
                    />
                );
            default:
                return (
                    <div>Non-existing option</div>
                );
        }
    });

    return (
        <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between" style={{margin:"16px 8px 8px 8px"}}>
            {entries}
        </Stack>
    )


}
