import * as React from "react";
import {Paper} from "@mui/material";
import {DropDownBarView} from "../common/DropDownBarView";
import {StatusCatchmentsView} from "../status/StatusCatchmentsView";
import {CreateScenarioView} from "./CreateScenarioView";
import {CreateNewStep1View} from "./CreateNewStep1View";
import {CreateNewStep2View} from "./CreateNewStep2View";
import Box from "@mui/material/Box";
import {useState} from "react";
import { CreateQuickEdit } from "./CreateQuickEdit";

export function CreateView() {

    const [step, setStep] = useState<string>("main");

    function onCancel() {
        gotoMain();
    }

    function gotoMain() {
        setStep("main");
    }
    function gotoNewAction() {
        setStep("step1");
    }
    function gotoEditAction() {
        setStep("step2");
    }
    function toggleQuickEdit(val:boolean) {
        if (val) {
            setStep("quickEdit");
        } else {
            setStep("main");
        }
    }
    function CurrentStep() {
        switch (step) {
            case "step1":
                return <CreateNewStep1View
                    id="create-1"
                    onCancel={onCancel}
                    onNext={gotoEditAction}
                />;
            case "step2":
                return <CreateNewStep2View id="create-2" onCancel={onCancel} onPrevious={gotoNewAction} onSave={gotoMain}/>;
            case "quickEdit":
                return <CreateQuickEdit onQuickEditToggle={toggleQuickEdit} onCancel={onCancel} onSave={gotoMain}/>;
            default:
                return <CreateScenarioView onQuickEditToggle={toggleQuickEdit} onEditActionSelected={gotoEditAction} onNewActionSelected={gotoNewAction}
                />;
        }
    }

    return (
        <Box id={"create-view"} sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
            <Paper className={"paper-area"}>
                <DropDownBarView
                    show={["period", "inflow", "system", "overflow", "sewer-plant"]}
                />
            </Paper>
            <Box sx={{display: "flex", flexDirection: 'row', justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
                <Box sx={{flex:1, height:'100%'}}>
                    <Paper className={"paper-left"} id="createLeft">
                        <CurrentStep/>
                    </Paper>
                </Box>
                <Box sx={{flex:1, height:'100%'}}>
                    <Paper className={"paper-right"}>
                        <StatusCatchmentsView
                            id="create-catchments-view"
                            editMode={true}
                        />
                    </Paper>
                </Box>
            </Box>
        </Box>
    );

}
