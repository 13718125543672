
export function formatNumber(n:number) : string {
    let digits = 0
    if (n < 100) {
        digits = 1
    }
    return n.toLocaleString("sv-SE",{ maximumFractionDigits: digits, minimumFractionDigits:0})
}

export function getTextWidth(text:string, font: string) : number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }
    context.font = font || getComputedStyle(document.body).font;
    return context.measureText(text).width;
}
