import {ActionSet, ActionSetState, ActionTag, getDefaultActionSet, getEmptyActionSet} from "./Action";
import {getI18n, useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {httpPost, httpStatusCodeIsOK} from "../api/HttpServices";

export const FE_ID_GLOBAL = "global";
export const FE_ID_LOCAL = "local";
export const FE_ID_CATCHMENTS = "catchments";
export const FE_ID_DISCHARGES = "discharges";

// Uses the backend types as filter
export const FE_TYPE_CATCHMENTS = "zone";
export const FE_TYPE_DISCHARGES = "overflowPoint";
export const FE_TYPE_TREATMENT_PLANT = "treatmentPlant";


export const FE_TIME_RANGE_YEAR = "Year"; // Using symbols from backend
export const FE_TIME_RANGE_MONTHS = "Month";
export const FE_TIME_RANGE_DAYS = "Day";
export const FE_TIME_RANGE_DETAIL = "Detail";
export const FE_TIME_RANGE_ALL = "All";

export const FE_PERIOD_NORMAL = "NormalYear"
export const FE_PERIOD_WET = "WetYear"

export enum FlowDataTags {
    cost = "cost",      // Not part of a set, returned at base level

    totalRain = "totalRainHA", // Needs to be calculated (fastRain + slowRain)
    fastRain = "fastRainHA",
    slowRain = "slowRainHA",
    groundWater = "groundWaterHA",
    totalVolume = "flowProducedAmount",

    fastRainInflowVolume = "fastRainFlowProducedAmount",
    infiltrationVolume = "slowRainFlowProducedAmount",  // slowRainFlowProducedAmount isn't really correctly named,
    // it returns both slow and groundwater ( = infiltration)

    inflow = "totalAmountOfInflowVolume",  // Needs to be calculated (fastRainInflowVolume + infiltrationVolume)
    overflow = "totalAmountOfOverflowVolume",

    partiallyTreated = "partiallyTreatedVolume",
    partiallyLower = "partiallyTreatedLower", // Needs to be calculated (partiallyTreated * partiallyLowerPercentage)
    partiallyUpper = "partiallyTreatedUpper", // Needs to be calculated (partiallyTreated * partiallyUpperPercentage)
    partiallyLowerPercentage = "partiallyTreatedLowerPercentage",  // These are the fraction of partiallyTreated, not of total
    partiallyUpperPercentage = "partiallyTreatedUpperPercentage",  // These are the fraction of partiallyTreated, not of total

    totalOverflowPercentage = "totalOverflowPercentage",  // Needs to be calculated
    totalInflowPercentage = "percentageOfProducedFlowIsRainWater",
    totalPartiallyUpperPercentage = "totalPartiallyUpperPercentage", // Needs to be calculated, these are the fraction of total
    totalPartiallyLowerPercentage = "totalPartiallyLowerPercentage", // Needs to be calculated, these are the fraction of total

    // Used when applying actions, to get absolute values
    totalPipeLength = "totalPipeLength",
    separatedPipeLength = "separatedPipeLength",
    combinedPipeLength = "combinedPipeLength",

    hardSurfaceArea = "hardSurfaceArea",
    drainagesFaultyConnected = "amountOfFaultyConnectedDrainages",
    drainagesCorrectlyConnected = "amountOfCorrectlyConnectedDrainages",

    inactiveDuplicateFraction = "inactiveDuplicateFraction"

}

export type AvailableAction = {
    name:string;
    id:string;
    disabled:boolean
}

export type AvailableZone = {
    name:string;
    id:string;
    disabled:boolean
}

interface FlowDataContextInterface {

    currentActionSet:ActionSet | null;
    setCurrentActionSet:(value:ActionSet | null) => void;
    editActionSet:ActionSet | null;
    setEditActionSet:(value:ActionSet | null) => void;

    // All action sets
    actionSets:Array<ActionSet>;
    setActionSets:(value:Array<ActionSet>) => void;

    // Selected catchments and discharges
    selectedCatchment:string | null;
    setSelectedCatchment:(value:string | null) => void;
    selectedDischarge:string | null;
    setSelectedDischarge:(value:string | null) => void;
    selectedCatchmentsForEdit:Array<string> | null;
    setSelectedCatchmentsForEdit:(value:Array<string> | null) => void;

    availableActions:Array<AvailableAction>;
    setAvailableActions:(value:Array<AvailableAction>) => void;
    availableZones:Array<AvailableZone>;
    setAvailableZones:(value:Array<AvailableZone>) => void;

    periodSelection:string;
    setPeriodSelection:(value:string) => void;
    inflowSelection:string;
    setInflowSelection:(value:string) => void;
    systemSelection:string;
    setSystemSelection:(value:string) => void;
    overflowSelection:string;
    setOverflowSelection:(value:string) => void;
    partlyTreatedSelection:string;
    setPartlyTreatedSelection:(value:string) => void;
}

export const FlowDataContext = React.createContext<FlowDataContextInterface>({
    currentActionSet:null,
    setCurrentActionSet:() => {},
    editActionSet:null,
    setEditActionSet:() => {},

    selectedCatchment:null,
    setSelectedCatchment:() => {},
    selectedDischarge:null,
    setSelectedDischarge:() => {},
    selectedCatchmentsForEdit:null,
    setSelectedCatchmentsForEdit:() => {},

    actionSets:[],
    setActionSets:() => {},
    availableActions:[],
    setAvailableActions:() => {},
    availableZones:[],
    setAvailableZones:() => {},

    periodSelection:FE_PERIOD_NORMAL,
    setPeriodSelection:() => {},
    inflowSelection:"fastRainHA",
    setInflowSelection:() => {},
    systemSelection:"all",
    setSystemSelection:() => {},
    overflowSelection:FlowDataTags.overflow,
    setOverflowSelection:() => {},
    partlyTreatedSelection:FlowDataTags.partiallyLower,
    setPartlyTreatedSelection:() => {},
});


export function FlowContext(props:any) {
    const [currentActionSet, setCurrentActionSet] = useState<ActionSet | null>(null);
    const [editActionSet, setEditActionSet] = useState<ActionSet | null>(null);

    const [selectedCatchment, setSelectedCatchment] = useState<string | null>(null);
    const [selectedDischarge, setSelectedDischarge] = useState<string | null>(null);
    const [selectedCatchmentsForEdit, setSelectedCatchmentsForEdit] = useState<Array<string> | null>(null);


    const [actionSets, setActionSets] = useState<Array<ActionSet>>([]);
    const [availableActions, setAvailableActions] = useState<Array<AvailableAction>>([]);
    const [availableZones, setAvailableZones] = useState<Array<AvailableZone>>([]);
    const [periodSelection, setPeriodSelection] = useState<string>(FE_PERIOD_WET);
    const [inflowSelection, setInflowSelection] = useState<string>("fastRainHA");
    const [systemSelection, setSystemSelection] = useState<string>("all");
    const [overflowSelection, setOverflowSelection] = useState<string>(FlowDataTags.overflow);
    const [partlyTreatedSelection, setPartlyTreatedSelection] = useState<string>(FlowDataTags.partiallyLower);

    const flowContextValues = {
        currentActionSet, setCurrentActionSet,
        editActionSet, setEditActionSet,

        selectedCatchment, setSelectedCatchment,
        selectedDischarge, setSelectedDischarge,
        selectedCatchmentsForEdit, setSelectedCatchmentsForEdit,

        actionSets, setActionSets,
        availableActions, setAvailableActions, availableZones, setAvailableZones,
        periodSelection, setPeriodSelection,
        inflowSelection, setInflowSelection, systemSelection, setSystemSelection,
        overflowSelection, setOverflowSelection, partlyTreatedSelection, setPartlyTreatedSelection
    }

    const {t} = useTranslation();


    // Triggers once, loads default flows and inits actions and places
    useEffect( () => {
        if (t !== undefined) { // Wait until translation has started
            initActionSets();
            initPlaces();
        }

        function initActionSets() {
            // Creates the single default action set when created
            let d = getDefaultActionSet();
            setActionSets([d]);

            let a:AvailableAction;
            let aa:Array<AvailableAction> = [];
            a = {
                name:t("Planning_Create_ActionList_SeperatePipes"),
                id:"separatePipes",
                disabled:false
            };
            aa.push(a);

            a = {
                name:t("Planning_Create_ActionList_ActivateDuplicate"),
                id:"activateDuplicated",
                disabled:false
            };
            aa.push(a);

            a = {
                name:t("Planning_Create_ActionList_CorrectMiss"),
                id:"fixConnections",
                disabled:false
            };
            aa.push(a);

            a = {
                name:t("Planning_Create_ActionList_SealSPipes"),
                id:"reliningSeparated",
                disabled:false
            };
            aa.push(a);

            a = {
                name:t("Planning_Create_ActionList_SealKPipes"),
                id:"reliningCombined",
                disabled:false
            };
            aa.push(a);

            a = {
                name:t("Planning_Create_ActionList_BlueGreenStormwater"),
                id:"blueGreen",
                disabled:false
            };
            aa.push(a);

            setAvailableActions(aa);
        }

        function initPlaces() {
            let query: QueryData = getEmptyQueryData();
            query.id = FE_ID_CATCHMENTS;
            query.timeRange = FE_TIME_RANGE_YEAR;
            query.period = periodSelection;
            query.fields = FE_FIELD_GROUP_NAMES;
            queryFlowEngine(query).then((fd) => {
                let az: Array<AvailableZone> = [];
                let z: AvailableZone;
                fd.years.forEach((value: any) => {
                    z = {
                        name: value.name,
                        id: value.id,
                        disabled: false,
                    };
                    az.push(z);
                });
                az.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                setAvailableZones(az);
            });
        }
    },[t, periodSelection]);

    return (
        <FlowDataContext.Provider value={flowContextValues} >{props.children}</FlowDataContext.Provider>
    );
}

// Combinations:
// --- Gauges
// range:year
// fields:
//     inflow:percentageOfProducedFlowIsDirectRainWater, percentageOfProducedFlowIsIndirectRainWater,
//     overflow: percentageOfTotalOverflow
//     treated: partiallyTreatedLowerPercentage

// -- Tab graphs
// zones: global
// range: all
// fields:
//     inflow: percentageOfProducedFlowIsDirectRainWater, percentageOfProducedFlowIsIndirectRainWater, percentageOfProducedFlowIsSewerWater
//     overflow: percentageOfTotalOverflow
//     treated: partiallyTreatedUpperPercentage, partiallyTreatedLowerPercentage

// -- Map graph, Inflow
// zones: all
// range: year
// fields: selected in "Area effect" popup

// -- Map graph, Overflow
// zones: all
// range: year
// fields: selected in "Overflow points" popup

// -- Catchment selected
// zones: selected
// range: all
// fields:
//     inflow: percentageOfProducedFlowIsDirectRainWater, percentageOfProducedFlowIsIndirectRainWater, percentageOfProducedFlowIsSewerWater

// -- Discharge selected
// zones: selected
// range: all
// fields:
//     overflow: percentageOfTotalOverflow


// The global data doesn't always exist, use this to extract a value from local data
export function getGlobalValueFromRegionalData(regionalData:FlowDataSet, tag:string) : number {
    if (tag === FlowDataTags.cost) {
        return regionalData.cost
    }
    let data = regionalData.years
    let type = FE_TYPE_CATCHMENTS;
    switch (tag) {
        case FlowDataTags.overflow:
            type = FE_TYPE_DISCHARGES;
            break
        case FlowDataTags.totalOverflowPercentage:
        case FlowDataTags.totalPartiallyUpperPercentage:
        case FlowDataTags.totalPartiallyLowerPercentage:
            type = ""
            break;
        case FlowDataTags.partiallyTreated:
        case FlowDataTags.partiallyLower:
        case FlowDataTags.partiallyUpper:
        case FlowDataTags.partiallyLowerPercentage:
        case FlowDataTags.partiallyUpperPercentage:
            type = FE_TYPE_TREATMENT_PLANT;
            break;
    }

    let value: number = 0;
    let total: number = 0;
    let counter: number = 0
    for (const catchment of data) {
        if (type.length > 0 && catchment["type"] !== type) {
            continue;
        }
        counter++;
        switch (tag) {
            case FlowDataTags.totalRain:
                value += ((catchment[FlowDataTags.fastRain] ?? 0) + (catchment[FlowDataTags.slowRain] ?? 0));
                break;
            case FlowDataTags.inflow:
                value += ((catchment[FlowDataTags.fastRainInflowVolume] ?? 0) + (catchment[FlowDataTags.infiltrationVolume] ?? 0));
                break;
            case FlowDataTags.partiallyLower:
                value += ((catchment[FlowDataTags.partiallyTreated] ?? 0) * ((catchment[FlowDataTags.partiallyLowerPercentage] ?? 0) / 100));
                break;
            case FlowDataTags.partiallyUpper:
                value += ((catchment[FlowDataTags.partiallyTreated] ?? 0) * ((catchment[FlowDataTags.partiallyUpperPercentage] ?? 0) / 100));
                break;
            case FlowDataTags.totalOverflowPercentage:
                value += catchment[FlowDataTags.overflow] ?? 0;
                total += catchment[FlowDataTags.totalVolume] ?? 0;
                break;
            case FlowDataTags.totalPartiallyUpperPercentage:
                value += ((catchment[FlowDataTags.partiallyTreated] ?? 0) * ((catchment[FlowDataTags.partiallyUpperPercentage] ?? 0) / 100.0));
                total += catchment[FlowDataTags.totalVolume] ?? 0;
                break;
            case FlowDataTags.totalPartiallyLowerPercentage:
                value += ((catchment[FlowDataTags.partiallyTreated] ?? 0) * ((catchment[FlowDataTags.partiallyLowerPercentage] ?? 0) / 100.0));
                total += catchment[FlowDataTags.totalVolume] ?? 0;
                break;
            default:
                value += catchment[tag] ?? 0;
        }
    }
    // Postprocessing of some values
    switch (tag) {
        case FlowDataTags.totalInflowPercentage:
            if (counter > 0) {
                value = value / counter
            }
            break;
        case FlowDataTags.totalOverflowPercentage:
        case FlowDataTags.totalPartiallyUpperPercentage:
        case FlowDataTags.totalPartiallyLowerPercentage:
            if (total > 0)  {
                value = (value / total) * 100.0
            } else {
                value = 1
            }
            break;
    }
    return value
}

export const FE_FIELD_GROUP_INFLOW = [
    "percentageOfProducedFlowIsDirectRainWater",
    "percentageOfProducedFlowIsIndirectRainWater",
    "percentageOfProducedFlowIsSewerWater",
    "flowProducedAmount"
]
export enum InflowKeys {
    percentDirect = "percentageOfProducedFlowIsDirectRainWater",
    percentIndirect = "percentageOfProducedFlowIsIndirectRainWater",
    percentSewer = "percentageOfProducedFlowIsSewerWater",
    totalAmount = "flowProducedAmount"
}

// We need to artificially create "percentageOfTotalOverflow" from these
export const FE_FIELD_GROUP_OVERFLOW = [
    FlowDataTags.overflow,
    "flowProducedAmount"
]

export const FE_FIELD_GROUP_TREATED = [
    "partiallyTreatedLowerPercentage",
    "partiallyTreatedUpperPercentage"
]

export const FE_FIELD_GROUP_NAMES = [
    "name",
    "type"
]


export type QueryData = {
    id:string;
    timeRange:string;
    fields:Array<string>;
    system: string;
    period: string;
    actionSet:ActionSet;
}
export function getEmptyQueryData():QueryData {
    return {
        id:"",
        timeRange:"",
        fields:[],
        system:"",
        period: FE_PERIOD_WET,
        actionSet:getEmptyActionSet()
    }
}

export async function queryFlowEngineForScenarioStates(actionSets:ActionSet[], periodSelection:string) : Promise<FlowDataMap> {
    let promises:Promise<FlowDataSet>[] = [];
    let result:FlowDataMap = {};

    // Always add a default, empty set to have a base state to compare to
    let defaultSet = getEmptyActionSet("default");
    actionSets.push(defaultSet);

    for (const as of actionSets) {
        let query: QueryData = getEmptyQueryData();
        query.id = FE_ID_LOCAL;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.period = periodSelection;
        query.fields = [
            "type",
            FlowDataTags.fastRain, FlowDataTags.slowRain, FlowDataTags.groundWater,
            FlowDataTags.totalInflowPercentage,
            FlowDataTags.overflow,
            FlowDataTags.totalVolume,
            FlowDataTags.partiallyTreated, FlowDataTags.partiallyLowerPercentage, FlowDataTags.partiallyUpperPercentage
        ];
        query.actionSet = as;
        promises.push(queryFlowEngine(query));
    }
    let responses = await Promise.all(promises);
    for (const response of responses) {
        result[response.id] = response
    }
    return result;
}

export async function queryFlowEngine(queryData:QueryData) : Promise<FlowDataSet> {
    if (queryData.id === FE_ID_GLOBAL) {
        // A global query
        return await getGlobalData(queryData);
    } else {
        return await getLocalData(queryData);
    }
}


async function getGlobalData(queryData:QueryData) : Promise<FlowDataSet> {
    let intervals:Array<string> = [];
    if (queryData.timeRange !== FE_TIME_RANGE_ALL) {
        intervals = [queryData.timeRange];
    }
    let url = "/api/noria/FlowEngineGlobalResult";
    let body:{[key:string]:any} = {
        "actions": actionSetToQueryData(queryData.actionSet),
        "period": queryData.period,
        "filterOptions": {
            "unfilteredZones": [],
            "unfilteredFields": queryData.fields,
            "unfilteredDays": [],
            "unfilteredIntervals": intervals,
            "system": ""
        }
    }
    let response = await httpPost(url, body, null, "getGlobalData");
    let flowData = getEmptyFlowDataSet();
    flowData.id = queryData.actionSet.id
    if (httpStatusCodeIsOK(response.status)) {
        // Update data and return it
        flowData = convertGlobalData(response.result, queryData.timeRange, queryData.actionSet.id);
    }
    return flowData;
}

async function getLocalData(queryData:QueryData) : Promise<FlowDataSet> {
    let intervals:Array<string> = [];
    if (queryData.timeRange !== FE_TIME_RANGE_ALL) {
        intervals = [queryData.timeRange];
    }
    let zones:Array<string> = [];
    let type:string = ""
    switch (queryData.id) {
        case FE_ID_GLOBAL:
        case FE_ID_LOCAL:
            break;
        case FE_ID_CATCHMENTS:
            type = FE_TYPE_CATCHMENTS;
            break;
        case FE_ID_DISCHARGES:
            type = FE_TYPE_DISCHARGES;
            break;
        default:
            zones = [queryData.id];
    }
    if (type.length > 0) {
        // Have to fetch type as well if we should compare
        queryData.fields.push("type");
    }
    if (queryData.system.length > 0) {
        if (queryData.system === "all") {
            queryData.system = ""
        }
    }


    let url = "/api/noria/FlowEngineInterpretedResult";
    let body:{[key:string]:any} = {
        "actions": actionSetToQueryData(queryData.actionSet),
        "period": queryData.period,
        "filterOptions": {
            "unfilteredZones": zones,
            "unfilteredFields": queryData.fields,
            "unfilteredDays": [],
            "unfilteredIntervals": intervals,
            "system": queryData.system,
        }
    }
    let response = await httpPost(url, body, null, "getLocalData");
    let flowData = getEmptyFlowDataSet();
    flowData.id = queryData.actionSet.id
    if (httpStatusCodeIsOK(response.status)) {
        // Update data and return it
        flowData = convertLocalData(response.result, queryData.timeRange, type, queryData.actionSet.id);
    }
    return flowData;
}

export type FlowDataEntry = {[key: string]: any};

export type FlowDataSet = {
    detail:Array<FlowDataEntry>
    days:Array<FlowDataEntry>
    months:Array<FlowDataEntry>
    years:Array<FlowDataEntry>
    cost:number
    id:string
}

export function getEmptyFlowDataSet():FlowDataSet {
    return {
        detail:[],
        days:[],
        months:[],
        years:[],
        cost:0,
        id:"",
    }
}

export type FlowDataMap = {[key:string]:FlowDataSet}

// Converting to the quirks of the API

type APIAction = {
    action:string;
    id:string;
    implementation:number;
    includeSPipes:boolean;
    imperviousSurfacePercentage:number;
    propertiesDisconnectedPercentage:number;
    stormWaterEfficiencyPercentage:number;
    leakDrainEfficiencyPercentage:number;
    privateOwnedPercentage:number;
}
function getEmptyAPIAction() :APIAction {
    return {
        action: "",
        id: "",
        implementation:0,
        includeSPipes:false,
        imperviousSurfacePercentage:0,
        propertiesDisconnectedPercentage:0,
        stormWaterEfficiencyPercentage:0,
        leakDrainEfficiencyPercentage:0,
        privateOwnedPercentage:0,
    }
}
const actionTagConverter:{[key in ActionTag]:string} = {
    [ActionTag.none]:"none",
    [ActionTag.activateDuplicated]:"ActivateDuplicate",
    [ActionTag.blueGreen]:"BlueGreenDayWaterSolution",
    [ActionTag.fixConnections]:"FixFaultyPipeConnections",
    [ActionTag.reliningCombined]:"FixKPipesIntegrity",
    [ActionTag.reliningSeparated]:"FixSPipesIntegrity",
    [ActionTag.separatePipes]:"SeparatePipes"
}

function actionSetToQueryData(as:ActionSet) : Array<APIAction> {
    let result:Array<APIAction> = [];
    for (const a of as.actions) {
        let apiAction = getEmptyAPIAction();
        apiAction.action = actionTagConverter[a.action] ?? "none";
        apiAction.implementation = a.implementation * 100.0;
        apiAction.id = a.zone;
        apiAction.includeSPipes = a.includeSPipes;

        apiAction.propertiesDisconnectedPercentage = a.propertiesDisconnectedPercentage * 100.0;
        apiAction.imperviousSurfacePercentage = a.imperviousSurfacePercentage * 100.0;

        // Advanced settings
        apiAction.leakDrainEfficiencyPercentage = a.leakDrainEfficiencyPercentage * 100.0;
        apiAction.stormWaterEfficiencyPercentage = a.stormWaterEfficiencyPercentage * 100.0
        apiAction.privateOwnedPercentage = a.privateOwnedPercentage * 100.0;

        // ...and more, sync with Marcus
        result.push(apiAction);
    }
    return result;
}

function convertGlobalData(raw:any, timeRange:string, id:string):FlowDataSet {
    let result = getEmptyFlowDataSet();
    let yearData:any = null;
    let monthData:any = null;
    let dayData:any = null;
    let detailData:any = null;
    if (timeRange === FE_TIME_RANGE_YEAR || timeRange === FE_TIME_RANGE_ALL) {
        yearData = (raw['globalYearData'] ?? null) as any;
    }
    if (timeRange === FE_TIME_RANGE_MONTHS || timeRange === FE_TIME_RANGE_ALL) {
        monthData = (raw['globalMonthDatas'] ?? []) as Array<any>;
    }
    if (timeRange === FE_TIME_RANGE_DAYS || timeRange === FE_TIME_RANGE_ALL) {
        dayData = (raw['globalDayDatas'] ?? []) as Array<any>;
    }
    if (timeRange === FE_TIME_RANGE_DETAIL || timeRange === FE_TIME_RANGE_ALL) {
        detailData = (raw['globalDetailDatas'] ?? []) as Array<any>;
    }

    let date: Date = new Date(2018, 0, 1);

    if (detailData) {
        detailData.forEach((value:any) => {
            let d: Date = new Date(2018, 0, 1);
            d.setDate(date.getDate() + ((value['day'] ?? 0) - 1));
            result.detail.push(convertEntry(value, d.valueOf()));
        });
    }

    if (dayData) {
        dayData.forEach((value:any) => {
            let d: Date = new Date(2018, 0, 1);
            d.setDate(date.getDate() + ((value['day'] ?? 0) - 1));
            result.days.push(convertEntry(value, d.valueOf()));
        });
    }
    if (monthData) {
        monthData.forEach((value:any, idx:number) => {
            let d = new Date(date.getFullYear(), idx, 1, 0, 0, 0, 0);
            result.months.push(convertEntry(value, d.valueOf()));
        });
    }
    if (yearData) {
        let d = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
        result.years.push(convertEntry(yearData, d.valueOf()));
    }
    result.cost = (raw['totalActionCosts'] ?? 0) as number
    result.id = id;

    return result;
}

function convertLocalData(raw:any, timeRange:string, type:string, id:string):FlowDataSet {
    let result = getEmptyFlowDataSet();
    let yearData:any = null;
    let monthData:any = null;
    let dayData:any = null;
    let detailData:any = null;
    if (timeRange === FE_TIME_RANGE_YEAR || timeRange === FE_TIME_RANGE_ALL) {
        yearData = (raw['interpretedYearData'] ?? null) as any;
    }
    if (timeRange === FE_TIME_RANGE_MONTHS || timeRange === FE_TIME_RANGE_ALL) {
        monthData = (raw['interpretedMonthDatas'] ?? []) as Array<any>;
    }
    if (timeRange === FE_TIME_RANGE_DAYS || timeRange === FE_TIME_RANGE_ALL) {
        dayData = (raw['interpretedDayDatas'] ?? []) as Array<any>;
    }
    if (timeRange === FE_TIME_RANGE_DETAIL || timeRange === FE_TIME_RANGE_ALL) {
        detailData = (raw['interpretedDetailDatas'] ?? []) as Array<any>;
    }

    let date: Date = new Date(2018, 0, 1);
    if (detailData) {
        detailData.forEach((value: any) => {
            let d: Date = new Date(2018, 0, 1);
            d.setDate(date.getDate() + ((value['day'] ?? 0) - 1));
            d.setHours(value['hour'] ?? 0)
            let entries: any = value['interpretedDatas'] ?? [];
            entries.forEach((entry: any) => {
                if (type.length === 0 || entry.type === type) {
                    result.detail.push(convertEntry(entry, d.valueOf()));
                }
            });
        });
    }
    if (dayData) {
        dayData.forEach((value:any) => {
            let d: Date = new Date(2018, 0, 1);
            d.setDate(date.getDate() + ((value['day'] ?? 0) - 1));
            let entries:any = value['interpretedDatas'] ?? [];
            entries.forEach((entry:any) => {
                if (type.length === 0 || entry.type === type) {
                    result.days.push(convertEntry(entry, d.valueOf()));
                }
            });
        });
    }
    if (monthData) {
        monthData.forEach((value:any, idx:number) => {
            let d = new Date(date.getFullYear(), idx, 1, 0, 0, 0, 0);
            let entries:any = value['interpretedDatas'] ?? [];
            entries.forEach((entry:any) => {
                if (type.length === 0 || entry.type === type) {
                    result.months.push(convertEntry(entry, d.valueOf()));
                }
            });
        });
    }
    if (yearData) {
        let d = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
        let entries: any = yearData['interpretedDatas'] ?? [];
        entries.forEach((entry: any) => {
            if (type.length === 0 || entry.type === type) {
                result.years.push(convertEntry(entry, d.valueOf()));
            }
        });
    }
    result.cost = (raw['totalActionCosts'] ?? 0) as number
    result.id = id;
    return result;
}

function convertEntry(rawEntry:any, date:number) : FlowDataEntry {
    let result: {[key:string]:any} = {};
    result.date = date;
    for (const prop in rawEntry) {
        if (prop === "day") {
            continue;
        }  else if (prop === "month") {
            continue;
        }
        result[prop] = rawEntry[prop];
    }
    if (rawEntry['totalAmountOfOverflowVolume'] !== undefined && rawEntry['flowProducedAmount'] !== undefined) {
        // TODO: Work around for missing backend stuff
        let part = ((rawEntry.flowProducedAmount === 0.0) ? 0.0 : rawEntry.totalAmountOfOverflowVolume / rawEntry.flowProducedAmount) * 100.0;
        part = (part > 100.0) ? 100 : part;
        result['percentageOfTotalOverflow'] = part;
    }

    return result;
}

