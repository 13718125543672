import {Box, Button} from "@mui/material";
import {SelectionEntry, TextFieldSelectSmall} from "../common/TextFieldSelectSmall";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {FlowDataContext} from "../../models/FlowContext";
import {ScenarioSelectedView} from "./ScenariosView";

export interface ScenariosTopBarProps extends React.HTMLProps<any>  {
    selectedView:ScenarioSelectedView;
    onSelectedViewChange:(value:ScenarioSelectedView)=>void;
    onShowFilters:() => void;
}

export function ScenariosTopBarView(props:ScenariosTopBarProps) {
    const {t} = useTranslation()
    const {
        periodSelection, setPeriodSelection,
    } = useContext(FlowDataContext);

    function getDropList():SelectionEntry[] {
        return [
            {value:"wet", title:t("Period_WetYear")},
            {value:"normal", title:t("Period_NormalYear")},
        ];
    }
    function isFilterOn() : boolean {
        return true
    }

    return (
        <Box className={"box-content-row-padding"}>
            <TextFieldSelectSmall
                id={"drop-period"}
                key={"drop-period"}
                style={{width:"140px"}}
                value={periodSelection}
                label={t("Planning_Head_Period")}
                onSelectChange={(id, v)=>{setPeriodSelection(v);}}
                selectionList={getDropList()}
            />
            <Button
                variant={props.selectedView === ScenarioSelectedView.all ? "contained" : "outlined"}
                onClick={()=>props.onSelectedViewChange(ScenarioSelectedView.all)}
            >{t("Planning_Scenarios_Status_All")}</Button>
            <Button
                variant={props.selectedView === ScenarioSelectedView.created ? "contained" : "outlined"}
                onClick={()=>props.onSelectedViewChange(ScenarioSelectedView.created)}
            >{t("Planning_Scenarios_Status_NotSimulated")}</Button>
            <Button
                variant={props.selectedView === ScenarioSelectedView.ongoing ? "contained" : "outlined"}
                onClick={()=>props.onSelectedViewChange(ScenarioSelectedView.ongoing)}
            >{t("Planning_Scenarios_Status_OnGoing")}</Button>
            <Button
                variant={props.selectedView === ScenarioSelectedView.simulated ? "contained" : "outlined"}
                onClick={()=>props.onSelectedViewChange(ScenarioSelectedView.simulated)}
            >{t("Planning_Scenarios_Status_Simulated")}</Button>
            <div style={{flex:1}} />
            <Button
                style={{alignSelf:"flex-end"}}
                variant={isFilterOn() ? "contained" : "outlined"}
                onClick={()=>props.onShowFilters()}
            >{t("Planning_Filter_TriggerButton_FilterOn")}</Button>

        </Box>
    )
}
