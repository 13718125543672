import React, {useContext, useEffect, useLayoutEffect} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {
    FE_FIELD_GROUP_OVERFLOW,
    FE_ID_GLOBAL,
    FE_TIME_RANGE_ALL,
    FlowDataContext, getEmptyQueryData, QueryData,
    queryFlowEngine
} from "../../models/FlowContext";
import {useTranslation} from "react-i18next";
import {Root} from "@amcharts/amcharts5";
import {TimeUnit} from "@amcharts/amcharts5/.internal/core/util/Time";

export interface OverflowGraphProps extends React.HTMLProps<any>  {
    id: string;
    timeUnit: string;
}

export function GraphOverflowView(props:OverflowGraphProps) {
    const {currentActionSet, selectedDischarge, overflowSelection, periodSelection} = useContext(FlowDataContext);
    const {t} = useTranslation();
    const [root, setRoot] = React.useState<Root>();

    useLayoutEffect(() => {
        let r = am5.Root.new("graph-" + props.id);
        setRoot(r);
    }, [props.id]);


    useEffect(() => {
        if (!root || !t) {
            return;
        }

        let area = FE_ID_GLOBAL;
        let symbol = "percentageOfTotalOverflow";
        let numberFormat = "#'%'";
        if (selectedDischarge) {
            area = selectedDischarge
            symbol = overflowSelection;
            numberFormat = "#";
        }
        let query:QueryData = getEmptyQueryData();
        query.id = area;
        query.timeRange = FE_TIME_RANGE_ALL;
        query.period = periodSelection;
        query.fields = FE_FIELD_GROUP_OVERFLOW;
        queryFlowEngine(query).then((fd) => {

            root.container.children.clear();
            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    layout: root.verticalLayout
                })
            );
            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    baseInterval: {
                        timeUnit: props.timeUnit as TimeUnit,
                        count: 1
                    }
                })
            );
            let xr = xAxis.get('renderer', null);
            if (xr) {
                xr.labels.template.set('fontSize', 12);
                xr.labels.template.set('paddingTop', 10);
            }

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    numberFormatter: am5.NumberFormatter.new(root, {numberFormat: numberFormat}),
                })
            );
            let yr = yAxis.get('renderer', null);
            if (yr) {
                yr.labels.template.set('fontSize', 12);
            }

            let dataSettings = {
                name: t("Planning_Status_Graph_Overflow_Overflow"),
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: symbol,
                valueXField: "date",
                fill: am5.color("#89b6fa")
            };

            if (props.timeUnit === "day" || props.timeUnit === "detail") {
                let series = chart.series.push(
                    am5xy.LineSeries.new(root, dataSettings)
                );
                series.fills.template.setAll({
                    fillOpacity: 1.0,
                    visible: true
                });
                series.data.setAll((props.timeUnit === "day") ? fd.days : fd.detail);
                // Also add zoom for daily & detail
                chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "zoomX"
                }));
                let cursor = chart.get("cursor");
                if (cursor) {
                    cursor.lineX.setAll({
                        visible: false
                    });
                    cursor.lineY.setAll({
                        visible: false
                    });
                }
            } else {
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, dataSettings)
                );

                if (props.timeUnit === "month") {
                    series.data.setAll(fd.months);
                } else {
                    series.data.setAll(fd.years);
                }
            }

            // unshift adds it on top
            let legend = chart.children.unshift(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                y: am5.percent(-2),
            }));

            legend.data.setAll(chart.series.values);

            legend.labels.template.setAll({
                fontSize: 12
            });
            // Make stuff animate on load
            chart.appear(1000, 100).then();
        });
    }, [props.timeUnit, root, t, currentActionSet, selectedDischarge, overflowSelection, periodSelection]);


    return (
        <div id={"graph-" + props.id} style={{width:"100%", height:"100%"}} />
    );
}
