import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio} from "@mui/material";
import * as React from "react";
import {ReactElement, useContext, useEffect, useState} from "react";
import {CreateNewHeaderView} from "./CreateNewHeaderView";
import {
    AvailableZone,
    FE_ID_CATCHMENTS,
    FE_TIME_RANGE_YEAR,
    FlowDataContext,
    FlowDataEntry,
    FlowDataSet,
    FlowDataTags,
    getEmptyQueryData, getGlobalValueFromRegionalData,
    QueryData,
    queryFlowEngine
} from "../../models/FlowContext";
import {useTranslation} from "react-i18next";
import {Action, ActionEditMode, ActionTag, copyAction, newAction} from "../../models/Action";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {DivWithRef} from "../common/DivWithRef";
import {formatCost} from "../../models/FlowContextUtils";

export interface CreateNewStep1Props extends React.HTMLProps<any>  {
    id:string;
    onCancel:()=>void;
    onNext:()=>void;

}


export function CreateNewStep1View(props:CreateNewStep1Props) {
    const {editActionSet, availableActions, availableZones, selectedCatchmentsForEdit, setSelectedCatchmentsForEdit, periodSelection} = useContext(FlowDataContext);
    const {t} = useTranslation();
    const {setScenarioEvent} = useContext(ScenarioDataContext)
    const [absoluteFlowValues, setAbsoluteFlowValues] = useState<{[id:string]:FlowDataEntry} | null>(null)


    const [actionBeingEdited, setActionBeingEdited] = useState<Action | null>(null);
    const [zonesToDisplay, setZonesToDisplay] = useState<Array<AvailableZone>>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    useEffect(() => {
        // Reset all selections
        setSelectedCatchmentsForEdit([]);
        // Update events
        setScenarioEvent(props.id + "-zones", "")

        let query:QueryData = getEmptyQueryData();
        // Get KPIs for all catchments to calculate whether an action should be enabled or not
        query.id = FE_ID_CATCHMENTS;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.period = periodSelection;
        query.fields = [FlowDataTags.cost, FlowDataTags.separatedPipeLength, FlowDataTags.combinedPipeLength,
            FlowDataTags.hardSurfaceArea, FlowDataTags.drainagesFaultyConnected,
            FlowDataTags.inactiveDuplicateFraction
        ];
        // Also, calculate cost for current action set
        if (editActionSet) {
            query.actionSet = editActionSet;
        }
        queryFlowEngine(query).then((fd: FlowDataSet) => {
            if (fd.years.length > 0) {
                let entries:{[id:string]:FlowDataEntry} = {}
                for (const entry of fd.years) {
                    entries[entry.id] = entry
                }
                setAbsoluteFlowValues(entries);
            }
            setTotalCost(getGlobalValueFromRegionalData(fd, FlowDataTags.cost))
        });
    },[setSelectedCatchmentsForEdit, periodSelection, editActionSet])

    // Changes the list of zones - you can't add a new action of same kind if it already exists on the zone
    useEffect( () => {
        if (editActionSet === null || availableZones.length === 0 || absoluteFlowValues === null) {
            return;
        }
        if (actionBeingEdited === null) {
            // Show all zones until an action has been selected
            setZonesToDisplay(availableZones);
            return;
        }

        // If a zone already has the selected action applied to it, it shouldn't be shown in the zone list
        let zonesAlreadyInEdit:Array<string> = [];
        for (const a of editActionSet.actions) {
            if (a.id !== actionBeingEdited.id && a.action === actionBeingEdited.action) {
                // These are the zones that should be hidden
                zonesAlreadyInEdit.push(a.zone);
            }
        }

        let toDisplay: Array<AvailableZone> = [];
        let selected = (selectedCatchmentsForEdit !== null) ? [...selectedCatchmentsForEdit] : [];
        let selectedChanged = false;

        for (const zone of availableZones) {
            let deselect = false;
            if (!zonesAlreadyInEdit.includes(zone.id)) {
                // These are the zones to display
                toDisplay.push(zone);
            } else {
                // These should be hidden, make sure they aren't selected
                deselect = true;
            }
            if (isDisabled(zone.id, actionBeingEdited, absoluteFlowValues)) {
                deselect = true;
            }
            if (deselect) {
                let idx = selected.indexOf(zone.id);
                if (idx >= 0) {
                    selected.splice(idx, 1)
                    selectedChanged = true
                }
            }
        }
        setZonesToDisplay(toDisplay);
        if (selectedChanged) {
            setSelectedCatchmentsForEdit(selected);
        }

    }, [editActionSet, actionBeingEdited, availableZones, absoluteFlowValues, selectedCatchmentsForEdit, setSelectedCatchmentsForEdit]);

    useEffect(() => {
        // Update events
        let eventVal = (selectedCatchmentsForEdit) ? ("|" + selectedCatchmentsForEdit.join("|") + "|") : "";
        setScenarioEvent(props.id + "-zones", eventVal)
    }, [props.id, selectedCatchmentsForEdit, setScenarioEvent])


    function isDisabled(id:string, action:Action | null, flowValues:FlowDataEntry | null): boolean {
        if (flowValues === null || action === null) {
            return false
        }
        let flowEntry = flowValues[id] ?? null;
        if (flowEntry === null) {
            return false;
        }
        let result = false;
        switch (action.action) {
            case ActionTag.separatePipes:
                result = (flowEntry[FlowDataTags.combinedPipeLength] ?? 0) === 0
                break;
            case ActionTag.fixConnections:
                result = (flowEntry[FlowDataTags.drainagesFaultyConnected] ?? 0) === 0
                break;
            case ActionTag.reliningCombined:
                result = (flowEntry[FlowDataTags.combinedPipeLength] ?? 0) === 0
                break;
            case ActionTag.reliningSeparated:
                result = (flowEntry[FlowDataTags.separatedPipeLength] ?? 0) === 0
                break;
            case ActionTag.activateDuplicated:
                result = (flowEntry[FlowDataTags.inactiveDuplicateFraction] ?? 0) === 0
                break;
            case ActionTag.blueGreen:
                result = (flowEntry[FlowDataTags.hardSurfaceArea] ?? 0) === 0
                break;
        }
        return result
    }
    /*
    // Makes sure no zones are selected that are disabled
    useEffect( () => {
        if (!selectedCatchmentsForEdit) {
            return;
        }
        let newSelection:Array<string> = [];
        let changed = false;
        for (const zone of selectedCatchmentsForEdit) {
            let found = false;
            if (zone === "all") {
                newSelection.push(zone);
                found = true;
            } else {
                for (const availableZone of zonesToDisplay) {
                    if (availableZone.id === zone) {
                        newSelection.push(zone);
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                changed = true;
            }
        }
        if (changed) {
            setSelectedCatchmentsForEdit(newSelection);
        }

    }, [zonesToDisplay, availableZones, selectedCatchmentsForEdit, setSelectedCatchmentsForEdit])
    */

    function updateSelectedAction(actionTag:ActionTag) {
        if (props.id.length > 0) {
            setScenarioEvent(props.id + "-action", actionTag)
        }
        let a = newAction(actionTag);
        setActionBeingEdited(a);
    }

    function updateSelectedZones(id:string, value:boolean) {
        let a = (!selectedCatchmentsForEdit) ? [] : [...selectedCatchmentsForEdit];
        if (id === "all") {
            if (a.includes(id)) {
                a = [];
            } else {
                a = [id];
            }
        } else if (value && !a.includes(id)) {
            a.push(id);
        } else if (!value && a.includes(id)) {
            a.splice(a.findIndex((v) => v === id), 1);
        }
        setSelectedCatchmentsForEdit(a);
    }

    function saveActionAndContinue() {
        if (!editActionSet || !selectedCatchmentsForEdit || selectedCatchmentsForEdit.length === 0) {
            return;
        }
        if (!actionBeingEdited) {
            return;
        }
        /// Create a number of new actions and push them onto editActionSet
        // editActionSet.actions = [];
        for (const zone of selectedCatchmentsForEdit) {
            let a = copyAction(actionBeingEdited);
            a.zone = zone;
            a.editMode = ActionEditMode.add;

            // This won't trigger a change event for editActionSet,
            // but that is a good thing since we are navigating away from this page. (Right?)
            editActionSet.actions.push(a);
        }

        setScenarioEvent("create-1-next", true);

        props.onNext();
    }

    // Don't really feel for learning React themes right now...
    function DefaultRadioRow(rowProps:any) {
        return (
            <FormControlLabel
                control={<Radio
                    size={"small"}
                    style={{height:"20px"}}
                    checked={rowProps.id === ((actionBeingEdited) ? actionBeingEdited.action : "")}
                    onChange={() => {updateSelectedAction(rowProps.id)}}
                />}
                label={<span style={{fontSize:"15px"}}>{rowProps.text}</span>}
                id={rowProps.id + "-label"}
            />

        );
    }

    function DefaultCheckboxRow(rowProps:any) {
        return (
            <FormControlLabel
                control={<Checkbox
                    size={"small"}
                    style={{height:"20px"}}
                    checked={(selectedCatchmentsForEdit) ? selectedCatchmentsForEdit.includes(rowProps.id) : false}
                    disabled={isDisabled(rowProps.id, actionBeingEdited, absoluteFlowValues)}
                    onChange={(event)=>{updateSelectedZones(rowProps.id, event.target.checked)}}
                />}
                label={<span style={{fontSize:"15px"}}>{rowProps.text}</span>}
                id={rowProps.id}
                key={rowProps.id}
            />
        );
    }

    function getZones():Array<ReactElement> {
        let zones:Array<ReactElement> = [];
        zones.push(<DefaultCheckboxRow text={t("Planning_Create_AddAction_All")} id={"all"} key={"zone-all"}/>);
        zonesToDisplay.forEach((value) => {
            zones.push(<DefaultCheckboxRow text={value.name} id={value.id} key={"zone-" + value.id}/>);
        });
        return zones;
    }

    function CostRow() {
        if (totalCost > 0) {
            return <p className={"cost"}><b>{t("Planning_Cost")}:</b>{formatCost(totalCost)}</p>
        }
        return null;
    }
    return (
        <Box className={"box-content-col-padding"} style={{gap:"0"}}>
            <CreateNewHeaderView step={1} />
            {/* ---------- Body ---------- */}
            <Box className={"box-content-auto"} style={{margin:"8px 0 8px 0"}}>
                <FormControl style={{width:"100%"}}>
                    <FormLabel component={"legend"}><b>{t("Simulation_Legend_SelectAction")}</b></FormLabel>
                    <FormGroup id="select-action" style={{margin:"0px 0px 0px 0px", padding:"0px 0px 0px 0px"}}>
                        {
                            availableActions.map((value) =>
                                <DefaultRadioRow text={value.name} id={value.id} key={value.id} />
                            )
                        }
                    </FormGroup>
                    <Divider style={{width:"100%", margin:"16px 0px 16px 0px"}} />
                    <FormLabel component={"legend"}><b>{t("Simulation_Legend_SelectCatchment")}</b></FormLabel>
                    <FormGroup id="select-area" >
                        {
                            getZones()
                        }
                    </FormGroup>
                </FormControl>
            </Box>
            {/* ---------- Footer ---------- */}
            <CostRow />
            <Box className={"footer-with-buttons"}>
                <Button
                    onClick={()=>{props.onCancel()}}
                    variant={"outlined"}
                >{t("Planning_Create_AddAction_Cancel")}</Button>
                <DivWithRef id={"create-1-next"}>
                <Button
                    onClick={()=>{saveActionAndContinue()}}
                    variant={"outlined"}
                    id={"create-1-next"}
                >{t("Planning_Create_AddAction_Next")}</Button>
                </DivWithRef>
            </Box>
        </Box>
    );
}
