import * as React from "react";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

export interface CreateNewStepHeaderProps extends React.HTMLProps<any>  {
    step:number
}

export function CreateNewHeaderView(props:CreateNewStepHeaderProps) {
    const {t} = useTranslation();

    let stepString = t("Planning_Create_AddAction_StepOfStep");
    // There is probably some kind of library that handles this, but oh well
    stepString = stepString.replace("{0}", props.step.toString());
    stepString = stepString.replace("{1}", "2");

    return (
        <Box sx={{display:"flex", width:"100%", flexDirection:"row", alignItems:"baseline", justifyContent:"space-between"}}>
            <div style={{fontSize:"20px", fontWeight:"bold"}}>{(props.step === 1) ? t("Planning_Create_Actions_AddNew") : t("Planning_Create_EditAction_Title")}</div>
            <div style={{fontSize:"16px", fontWeight:"bold"}}>{stepString}</div>
        </Box>
    );
}

