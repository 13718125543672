import React, {useCallback, useContext, useEffect, useLayoutEffect} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {useTranslation} from "react-i18next";
import {
    FE_ID_CATCHMENTS, FE_ID_DISCHARGES, FE_TIME_RANGE_YEAR, FE_TYPE_CATCHMENTS, FE_TYPE_DISCHARGES,
    FlowDataContext, FlowDataSet,
    getEmptyQueryData,
    QueryData, queryFlowEngine
} from "../../models/FlowContext";
import {AppTheme} from "../../AppTheme";
import {Axis} from "@amcharts/amcharts5/.internal/charts/xy/axes/Axis";
import {AxisRenderer} from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";
import {sortFlowDataByCatchmentId} from "../../models/FlowContextUtils";

export interface GraphGlobalProps extends React.HTMLProps<any>  {
    id: string;
    label: string;
    field: string;
    type: string;
    system: string;
    editMode: boolean;
}


export function GraphGlobalView(props:GraphGlobalProps) {
    const {editActionSet, periodSelection} = useContext(FlowDataContext);
    const {t} = useTranslation();
    const [chart, setChart] = React.useState<am5xy.XYChart>();

    // ---- Utility functions to create charts and chart parts
    const createChart = useCallback((containerID:string) : am5xy.XYChart => {
        let root = am5.Root.new(containerID);
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout,
                maxTooltipDistance: -1
            })
        );

        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(chart.root, {
                renderer: am5xy.AxisRendererX.new(chart.root, {
                }),
                categoryField: "id"
            })
        );
        let xr = xAxis.get('renderer', null);
        if (xr) {
            xr.set('minGridDistance', 0);
            xr.labels.template.setAll({
                fontSize:10,
                rotation:90,
                centerY: am5.p50,
                centerX: am5.p0,
                textAlign:"left",
                paddingLeft:8,
                paddingRight:8,
                location:0.5
            })
        }

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(chart.root, {
                renderer: am5xy.AxisRendererY.new(chart.root, {
                })
            })
        );
        let yr = yAxis.get('renderer', null);
        if (yr) {
            yr.labels.template.set('fontSize', 12);
        }
        let cursor = am5xy.XYCursor.new(chart.root, {});
        cursor.lineX.setAll({
            visible:false
        });
        cursor.lineY.setAll({
            visible:false
        });

        chart.set("cursor", cursor);
        return chart;

    },[]);

    const getTooltip = useCallback((root:am5.Root, label:string) : am5.Tooltip => {
        let tooltip = am5.Tooltip.new(root, {
            autoTextColor: false,
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            labelText: "[bold]" + label + "[/]\n{name} {id}\n[bold]{valueY.formatNumber('#.0')}[/]",
        });
        let bg = tooltip.get("background");
        if (bg) {
            bg.setAll({
                fill: am5.color(0xffffff),
                fillOpacity: 0.8,
            });
        }
        tooltip.label.setAll({
            textAlign:"center",
            fill:am5.color(0x0),
            fontSize:12
        });
        return tooltip
    },[]);


    // ---- Create and add the chart ----
    useLayoutEffect(() => {
        // One time only, creates the initial chart
        let ch = createChart("global-graph-" + props.id);
        setChart(ch);
        return (() => {
            if (ch.root) {
                ch.root.dispose();
            }
        });
    },[props.id, createChart]);


    // ---- Update the chart with data ----
    useEffect(() => {
        if (!t || !chart) {
            return;
        }

        chart.series.clear();

        let showEdit = (props.editMode && editActionSet && editActionSet.actions.length > 0);

        let color = (props.type === FE_TYPE_CATCHMENTS) ? AppTheme.palette.globalInflow : AppTheme.palette.globalOverflow;
        let editColor = color;
        if (showEdit) {
            color = (props.type === FE_TYPE_CATCHMENTS) ? AppTheme.palette.globalInflowDark : AppTheme.palette.globalOverflowDark;
        }

        let series = chart.series.push(
            am5xy.ColumnSeries.new(chart.root, {
                name: t("Planning_Status_Graph_InflowInfiltration_Stormwater"),
                xAxis: (chart.xAxes.getIndex(0) as Axis<AxisRenderer>),
                yAxis: (chart.yAxes.getIndex(0) as Axis<AxisRenderer>),
                categoryXField:"id",
                valueYField: props.field,
                clustered: !showEdit,
                fill: am5.color(color),
            })
        );
        let editSeries: am5xy.ColumnSeries | null = null;
        if (showEdit) {
            editSeries = chart.series.push(
                am5xy.ColumnSeries.new(chart.root, {
                    name: t("Planning_Status_Graph_InflowInfiltration_Stormwater"),
                    xAxis: (chart.xAxes.getIndex(0) as Axis<AxisRenderer>),
                    yAxis: (chart.yAxes.getIndex(0) as Axis<AxisRenderer>),
                    categoryXField: "id",
                    valueYField: props.field,
                    clustered: !showEdit,
                    fill: am5.color(editColor),
                })
            );
        }

        // Query for data
        let promises:Array<Promise<FlowDataSet>> = [];
        let query:QueryData = getEmptyQueryData();
        // This should be refactored, stupid to use id to filter types....
        query.id = (props.type === FE_TYPE_DISCHARGES) ? FE_ID_DISCHARGES : FE_ID_CATCHMENTS;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.period = periodSelection;
        query.fields = [props.field];
        query.system = props.system;
        promises.push(queryFlowEngine(query));

        // Query the same but with the edit action set
        if (showEdit && editActionSet) {
            let editQuery = {...query} as QueryData;
            editQuery.actionSet = editActionSet;
            promises.push(queryFlowEngine(editQuery));
        }

        Promise.all(promises).then((results) => {
            let fd = results[0];
            sortFlowDataByCatchmentId(fd);

            let xAxis = chart.xAxes.getIndex(0);
            if (xAxis) {
                xAxis.data.setAll(fd.years);
            }

            series.data.setAll(fd.years);
            series.set("tooltip", getTooltip(chart.root, t("Planning_Create_Current_Situation")));


            if (results.length > 1 && editSeries) { // Edit mode
                let fdEdit = results[1];
                editSeries.data.setAll(fdEdit.years);
                editSeries.set("tooltip", getTooltip(chart.root, (editActionSet) ? editActionSet.name : ""));
            }

            // Make stuff animate on load
            chart.appear(500, 0).then();
        });


    }, [props.id, props.editMode, props.field, props.type, props.system, t, chart, editActionSet, getTooltip, periodSelection]);



    return (
        <div id={"global-graph-" + props.id} style={{width:"100%", height:"100%"}} />
    );

}
