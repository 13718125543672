import * as React from "react";
import {siteIDToSiteName} from "../../models/SiteData";
import {Box, ThemeProvider} from "@mui/material";
import {CreateQuickEditRow} from "./CreateQuickEditRow";
import {QuickEditColumns} from "./CreateQuickEdit";
import {ActionTag} from "../../models/Action";
import {useTranslation} from "react-i18next";
import createTheme from "@mui/material/styles/createTheme";

export interface CreateQuickEditTableProps extends React.HTMLProps<any>  {
    tableData:QuickEditColumns;
    actionOrder:string[];
    firstColWidth:number;
    onValueChange:(id:string, tag:ActionTag, value:number) => void;
}

export function CreateQuickEditTable(props:CreateQuickEditTableProps) {
    const {t} = useTranslation()

    // Create a custom theme to have less padding in the inputs
    function getTheme() {
        return createTheme({
            components: {
                // Text input text
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: '13px',
                        },
                        inputSizeSmall: {
                            padding: "6px 6px 6px 6px !important",
                            textAlign: 'right'
                        }
                    }
                }
            }
        });
    }

    function rows() : Array<JSX.Element> {
        let result:Array<JSX.Element> = [];
        let rowsForSort:{[key:string]:string}[] = []

        // Add to an array to be able to sort correctly
        for (const key in props.tableData) {
            rowsForSort.push({
                key : key,
                name: (key === "all") ? "00" : siteIDToSiteName(key)
            });
        }
        rowsForSort.sort((a, b) => {
            return a["name"].localeCompare(b["name"])
        })

        for (const keyObj of rowsForSort) {
            const key = keyObj["key"];
            const row = props.tableData[key];
            result.push(
                <CreateQuickEditRow
                    key={key}
                    catchmentID={key}
                    catchmentName={key === "all" ? t("Planning_Create_QuickEdit_AllAreas") : siteIDToSiteName(key)}
                    values={row}
                    actionOrder={props.actionOrder}
                    onValueChange={props.onValueChange}
                    firstColWidth={props.firstColWidth}
                />
            )
        }
        return result;
    }
    return (
        <Box className={"box-col"}>
            <ThemeProvider theme={getTheme}>

            {
                rows()
            }
            </ThemeProvider>
        </Box>
    )
}
