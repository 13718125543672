import React, {RefObject, useContext, useLayoutEffect, useState} from "react";
import {persistScenarioData, ScenarioDataContext} from "../../models/ScenarioContext";
import {Box, Button} from "@mui/material";
import {
    Scenario,
    ScenarioStep,
    scenarioStepIsWaitForWin,
    ScenarioStepPlacement,
    ScenarioStepType
} from "../../models/ScenarioModels";
import {useTranslation} from "react-i18next";
import {Markup} from "interweave";
import "./MissionPopup.css"
import {Stars} from "../common/Stars";

export function MissionPopup() {
    const {currentScenario, currentScenarioStep, getScenarioElement} = useContext(ScenarioDataContext);
    const [open, setOpen] = useState<boolean>(false);


    const {t} = useTranslation();

    useLayoutEffect( () => {
        if (!currentScenarioStep) {
            setOpen(false);
            return;
        }
        function showDoneStep() : boolean {
            if (!currentScenarioStep || !currentScenario) {
                return false
            }
            return currentScenarioStep.type === ScenarioStepType.done; // && currentScenario.completedLevel > currentScenario.savedLevel
        }

        if (currentScenarioStep.type === ScenarioStepType.pop ||
            currentScenarioStep.type === ScenarioStepType.step ||
            showDoneStep()) {
            setOpen(true);
        } else {
            setOpen(false);
            return;
        }
    }, [currentScenarioStep,currentScenario])

    function getAnchorTag(step:ScenarioStep | null) : string {
        let tag = "popup-center";
        if (!step) {
            return tag;
        }
        switch (step.placement) {
            case null:
            case ScenarioStepPlacement.empty:
            case ScenarioStepPlacement.center:
                tag = "popup-center";
                break;
            case ScenarioStepPlacement.left:
            case ScenarioStepPlacement.centerLeft:
                tag = "popup-left";
                break;
            case ScenarioStepPlacement.right:
            case ScenarioStepPlacement.centerRight:
                tag = "popup-right";
                break;
            case ScenarioStepPlacement.centerRightLow:
                tag = "popup-right-low";
                break;
            case ScenarioStepPlacement.top:
            case ScenarioStepPlacement.topCenter:
            case ScenarioStepPlacement.topLeft:
                tag = "popup-top";
                break;
            case ScenarioStepPlacement.topRight:
                tag = "popup-top-right";
                break;
            case ScenarioStepPlacement.bottom:
            case ScenarioStepPlacement.bottomCenter:
            case ScenarioStepPlacement.bottomLeft:
            case ScenarioStepPlacement.bottomRight:
                tag = "popup-bottom";
                break;
        }
        return tag;
    }
    function getAnchorLeft(step:ScenarioStep | null): number {
        let interactionRef: RefObject<HTMLElement | undefined> | null = null;
        if (step && step.anchor.length > 0) {
            interactionRef = getScenarioElement(step.anchor);
        }
        if (!step || !interactionRef || !interactionRef.current) {
            return window.innerWidth / 2
        }

        let rect = interactionRef.current.getBoundingClientRect();
        switch (step.placement) {
            case null:
            case ScenarioStepPlacement.center:
            case ScenarioStepPlacement.top:
            case ScenarioStepPlacement.topCenter:
            case ScenarioStepPlacement.bottom:
            case ScenarioStepPlacement.bottomCenter:
                return rect.left + rect.width * 0.5
            case ScenarioStepPlacement.left:
            case ScenarioStepPlacement.centerLeft:
            case ScenarioStepPlacement.topLeft:
            case ScenarioStepPlacement.bottomLeft:
                return rect.left;
            case ScenarioStepPlacement.right:
            case ScenarioStepPlacement.centerRight:
            case ScenarioStepPlacement.centerRightLow:
            case ScenarioStepPlacement.topRight:
            case ScenarioStepPlacement.bottomRight:
                return rect.right;
        }
        return 0;
    }
    function getAnchorTop(step:ScenarioStep | null): number {
        let interactionRef: RefObject<HTMLElement | undefined> | null = null;
        if (step && step.anchor.length > 0) {
            interactionRef = getScenarioElement(step.anchor);
        }
        if (!step || !interactionRef || !interactionRef.current) {
            return window.innerHeight / 2;
        }
        let rect = interactionRef.current.getBoundingClientRect();
        switch (step.placement) {
            case null:
            case ScenarioStepPlacement.center:
            case ScenarioStepPlacement.left:
            case ScenarioStepPlacement.centerLeft:
            case ScenarioStepPlacement.right:
            case ScenarioStepPlacement.centerRight:
            case ScenarioStepPlacement.centerRightLow:
                return rect.top + rect.height * 0.5;
            case ScenarioStepPlacement.top:
            case ScenarioStepPlacement.topCenter:
            case ScenarioStepPlacement.topLeft:
            case ScenarioStepPlacement.topRight:
                return rect.top;
            case ScenarioStepPlacement.bottom:
            case ScenarioStepPlacement.bottomCenter:
            case ScenarioStepPlacement.bottomLeft:
            case ScenarioStepPlacement.bottomRight:
                return rect.bottom;
        }
        return 0;
    }
    function getBodyText(scenario:Scenario | null, step:ScenarioStep | null) : string {
        if (!scenario || !step) {
            return "";
        }

        let key = step.body;
        if (scenario.levels > 1) {
            // If the scenario has many levels, the body may contain more than one key, each per level
            let keys = key.split("|");
            let idx = scenario.completedLevel - 1;
            if (idx < 0 || idx >= keys.length) {
                key = keys[0];
            } else {
                key = keys[idx];
            }
        }
        return t("Scenarios:" + key);
    }

    function handleContinue() {  // Saves and goes back one step (presumably to wait for win conditions)
        if (!currentScenario) {
            return;
        }
        currentScenario.savedLevel = currentScenario.completedLevel;
        persistScenarioData([currentScenario])

        // Rewind to win conditions step
        let winFound = false;
        for (let i = currentScenario.steps.length - 1; i <= 0; i--) {
            let step = currentScenario.steps[i]
            if (winFound) {
                // Wait to let next step appear on an already drawn screen
                setTimeout(() => {
                    if (step.stepCallback) {
                        step.stepCallback();
                    }
                }, 100);
                break;
            } else if (scenarioStepIsWaitForWin(step)) {
                winFound = true;
            }
        }

    }

    function handleDone() {

        if (currentScenario) {
            currentScenario.savedLevel = currentScenario.completedLevel;
            persistScenarioData([currentScenario])
        }
        handleNext();
    }

    function handleNext() {
        if (currentScenarioStep && currentScenarioStep.stepCallback) {
            // Wait to let next step appear on an already drawn screen
            setTimeout(() => {
                if (currentScenarioStep && currentScenarioStep.stepCallback) {
                    currentScenarioStep.stepCallback();
                }
            }, 100)
        }
    }

    if (!open) {
        return null
    }
    // Also, switch pointer-events between none and auto depending on if it has a next button or not
    return (
        /* Background blocking clicks */
        <Box
            className={"popup-background"}
            style={{pointerEvents:(currentScenarioStep && currentScenarioStep.type === ScenarioStepType.pop) ? "none" : "auto"}}
        >
            {/* Anchor for popup. Top-left is the origin for the popup */}
            <Box className={"popup-anchor"}
                 style={{
                     top:getAnchorTop(currentScenarioStep) + "px",
                     left:getAnchorLeft(currentScenarioStep) + "px",
                     width:"400px"
            }}>
                <Box className={getAnchorTag(currentScenarioStep)}>
                    <Box className={"popup-content"}>
                        {(currentScenarioStep && currentScenarioStep.title.length > 0) ?
                            <p><b>{t("Scenarios:" + currentScenarioStep.title)}</b></p> : null
                        }
                        <p style={{paddingTop:"4px"}}>
                            <Markup content={(currentScenarioStep) ? getBodyText(currentScenario, currentScenarioStep) : ""} />
                        </p>

                        { (currentScenarioStep && currentScenarioStep.type === ScenarioStepType.step) ?
                            <Button style={{marginLeft:"auto", marginRight:"0px"}} onClick={handleNext}>{t("ScenarioUI:scenarios_next_step_button")}</Button>
                            : ""
                        }
                        { (currentScenario && currentScenarioStep && currentScenarioStep.type === ScenarioStepType.done) ?
                            <Box className={"box-col"}>
                                <Box className={"box-row"}>
                                    {
                                        (currentScenario.levels > 1) ?
                                            <Stars level={currentScenario.completedLevel} maxLevel={currentScenario.levels}/> : null
                                    }
                                </Box>
                                <Box className={"box-row"}>
                                    <Box style={{flex:1}} />
                                    {
                                        (currentScenario.completedLevel < currentScenario.levels) ?
                                            <Button
                                                onClick={handleContinue}>{t("ScenarioUI:scenarios_continue_button")}
                                            </Button>
                                            :
                                            null
                                    }
                                    <Button onClick={handleDone}>{t("ScenarioUI:scenarios_done_button")}</Button>
                                </Box>
                            </Box>
                            : ""
                        }
                    </Box>
                </Box>
            </Box>


        </Box>
    );


}
