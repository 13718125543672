import * as React from "react";
import Box from "@mui/material/Box";
import {Button, FormControlLabel, Switch, Tab, Tabs} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CreateQuickEditTable} from "./CreateQuickEditTable";
import {
    Action,
    ActionTag,
    copyActionSet,
    FactorTag,
    getEmptyActionSet,
    newAction,
    resetActionSetStates
} from "../../models/Action";
import {sortedCatchments} from "../../models/SiteData";
import {FlowDataContext} from "../../models/FlowContext";
import {CreateQuickEditIconHeader} from "./CreateQuickEditIconHeader";
import {CreateQuickEditFactorHeader} from "./CreateQuickEditFactorHeader";
import {CreateQuickEditFactorTable} from "./CreateQuickEditFactorTable";
import {DivWithRef} from "../common/DivWithRef";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export interface CreateQuickEditProps extends React.HTMLProps<any>  {
    onCancel:()=>void;
    onSave:()=>void;
    onQuickEditToggle:(val:boolean)=>void;
}

export type QuickEditRow = {[key in ActionTag]?: number};
export type QuickEditColumns = {[key:string]:QuickEditRow};

export type QuickEditFactorRow = {[key in FactorTag]?: number};
export type QuickEditFactorColumns = {[key:string]:QuickEditFactorRow};

export function CreateQuickEdit(props:CreateQuickEditProps) {
    const [quickEdit, setQuickEdit] = useState<boolean>(true);
    const [quickEditTable, setQuickEditTable] = useState<QuickEditColumns>({});
    const [quickEditFactorTable, setQuickEditFactorTable] = useState<QuickEditFactorColumns>({});
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [firstColWidth] = useState<number>(180);
    const {t} = useTranslation();
    const {editActionSet, setEditActionSet} = useContext(FlowDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext);

    const actionOrder:string[] = [ActionTag.separatePipes, ActionTag.activateDuplicated,
        ActionTag.fixConnections, ActionTag.reliningSeparated,
        ActionTag.reliningCombined, ActionTag.blueGreen];

    useEffect(() => {

        const actionOrder = [
            ActionTag.separatePipes,
            ActionTag.activateDuplicated,
            ActionTag.fixConnections,
            ActionTag.reliningCombined,
            ActionTag.reliningSeparated,
            ActionTag.blueGreen
        ];
        // Init an empty table, except when actions exist already
        let columns:QuickEditColumns = {};
        let factorColums:QuickEditFactorColumns = {};

        let catchments = sortedCatchments();
        let editActions:Array<Action> = (editActionSet) ? editActionSet.actions : [];

        let row:QuickEditRow = {};
        for (const tag of actionOrder) {
            row[tag] = 0;
        }
        columns["all"] = row;
        factorColums["all"] = {
            [FactorTag.factorInfiltration]:1,
            [FactorTag.factorInflow]:1,
            [FactorTag.factorSewage]:1,
        };

        for (const catchment of catchments) {
            let row:QuickEditRow = {};
            for (const tag of actionOrder) {
                let val = 0;
                for (const action of editActions) {
                    if (action.zone === catchment.id && action.action === tag) {
                        val = action.implementation;
                        break;
                    }
                }
                row[tag] = val;
            }

            let factorRow:QuickEditFactorRow = {
                [FactorTag.factorInfiltration]:1,
                [FactorTag.factorInflow]:1,
                [FactorTag.factorSewage]:1,
            };

            for (const action of editActions) {
                if (action.zone === catchment.id) {
                    factorRow.factorInfiltration = action.factorInfiltration
                    factorRow.factorInflow = action.factorInflow
                    factorRow.factorSewage = action.factorSewage
                }
            }

            columns[catchment.id] = row;
            factorColums[catchment.id] = factorRow
        }

        setQuickEditTable(columns);
        setQuickEditFactorTable(factorColums);

    },[editActionSet])

    const handleTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    function saveQuickEdit() {
        let cpyActionSet = (editActionSet) ? copyActionSet(editActionSet) : getEmptyActionSet();
        let newActions: Array<Action> = [];
        for (const id in quickEditTable) {
            if (id === "all") {
                continue;
            }
            let row = quickEditTable[id];
            for (const tag in row) {
                let val = row[tag as ActionTag];
                if (val) {
                    let a = newAction(tag as ActionTag);
                    a.zone = id;
                    a.implementation = val;
                    // Always set default as the same
                    a.propertiesDisconnectedPercentage = a.implementation;
                    a.imperviousSurfacePercentage = a.implementation;
                    newActions.push(a);
                }
            }
        }
        cpyActionSet.actions = newActions;
        resetActionSetStates(cpyActionSet);
        setEditActionSet(cpyActionSet);
        props.onSave();
        setScenarioEvent("quick-edit-save", true);

    }

    function setQuickEditRowValue(id:string, tag:ActionTag, value:number) {
        let table = {...quickEditTable}
        if (id === "all") {
            for (const key in table) {
                table[key][tag] = value
            }
        } else {
            table[id][tag] = value
        }
        setQuickEditTable(table)
        setScenarioEvent(id + "-" + tag, value)
    }
    function setQuickEditRowFactorValue(id:string, tag:FactorTag, value:number) {
        let table = {...quickEditFactorTable}
        if (id === "all") {
            for (const key in table) {
                table[key][tag] = value
            }
        } else {
            table[id][tag] = value
        }
        setQuickEditFactorTable(table)
    }

    return (
        <Box className={"box-content-col"}>
            <Box className={"box-content-row-padding"}>
                <Box sx={{display:"flex", justifyContent:"flex-start", alignContent:"center", flex:1}}>
                    <b>{t("Planning_Create_Actions_QuickEdit")}</b>
                </Box>
                <Box sx={{display:"flex", justifyContent:"flex-end", alignContent:"center", flex:1}}>
                    <FormControlLabel control={<Switch checked={quickEdit} onChange={() => {setQuickEdit(false); props.onQuickEditToggle(false)}}/>} label={t("Planning_Create_Actions_QuickEdit") as string}/>
                </Box>
            </Box>
            <Box>
                <Tabs value={selectedTab} onChange={handleTab}>
                    <Tab style={{textTransform:"none"}} label={t("Planning_Create_QuickEdit_ActionTab")} />
                    <Tab style={{textTransform:"none"}} label={t("Planning_Create_QuickEdit_ExplTab")} />
                </Tabs>
            </Box>
            {/* ---------- Actions tab ---------- */}
            <Box style={{flex:1, display:(selectedTab !== 0) ? "none" : "flex", flexDirection:"column"}}>
                <CreateQuickEditIconHeader
                    firstColWidth={firstColWidth}
                    text={t("Planning_Create_QuickEdit_Areas")}
                />
                <DivWithRef style={{flex:1, width:"100%"}} id={"quick-edit-table"}>
                    <Box className={"box-col"} style={{width:"100%", height:"100%"}}>
                            <Box className={"box-content-auto"}>
                                <CreateQuickEditTable
                                    firstColWidth={firstColWidth}
                                    tableData={quickEditTable}
                                    actionOrder={actionOrder}
                                    onValueChange={setQuickEditRowValue}
                                />
                            </Box>
                    </Box>
                </DivWithRef>
            </Box>
            <Box style={{flex:1, display:(selectedTab !== 1) ? "none" : "flex", flexDirection:"column"}}>
                <CreateQuickEditFactorHeader firstColWidth={firstColWidth} text={t("Planning_Create_QuickEdit_Areas")} />
                <Box className={"box-col"} sx={{flex:1}}>
                    <Box className={"box-content-auto"}>
                        <CreateQuickEditFactorTable firstColWidth={firstColWidth} tableData={quickEditFactorTable} onValueChange={setQuickEditRowFactorValue} />
                    </Box>
                </Box>
            </Box>
            {/* ---------- Footer ---------- */}
            <Box className={"footer-with-buttons"}>
                <Button
                    onClick={()=>{props.onCancel()}}
                    variant={"outlined"}
                >{t("Cancel")}</Button>
                <DivWithRef id={"quick-edit-save"}>
                <Button
                    onClick={()=>{saveQuickEdit()}}
                    variant={"outlined"}
                >{t("Planning_Create_QuickEdit_SaveChanges")}</Button>
                </DivWithRef>
            </Box>
        </Box>
    );
}
