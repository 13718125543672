import React from 'react';

import {Box} from "@mui/material";
import {formatNumber} from "../../utils/textUtils";
import {AppTheme} from "../../AppTheme";

export interface MissionMeterProps extends React.HTMLProps<any>  {
    id: string;
    min: number;
    max: number;
    current: number;
    goal: number;
    color: string;
    width: number;
    height: number;
}

export function MissionMeterView(props:MissionMeterProps) {
    let goalWidth = 0
    let currentWidth = 0;
    let greenWidth= 0;
     let redWidth = 0;
     let range =  props.max - props.min;
     if (range !== 0) {
         goalWidth = props.width * (props.goal / range);
         currentWidth = props.width * (props.current / range);
     }
    if (currentWidth <= goalWidth) {
        greenWidth = currentWidth;
        redWidth = 0
    } else {
        greenWidth = goalWidth;
        redWidth = currentWidth - goalWidth;
    }

    return (
        <Box style={{width:props.width + "px", height:props.height + "px"}}>
            <Box style={{position:"relative"}}>
                <Box style={{
                    position:"absolute",
                    left:0, top:0,
                    width:greenWidth + "px",
                    height:props.height + "px",
                    backgroundColor:AppTheme.palette.gaugeGreen
                }}
                />
                <Box style={{
                    position:"absolute",
                    left:greenWidth + "px", top:0,
                    width:redWidth + "px",
                    height:props.height + "px",
                    backgroundColor:AppTheme.palette.gaugeRed
                }}
                />
                <Box style={{
                    position:"absolute",
                    left:goalWidth + "px", top:0,
                    width:"1px",
                    height:props.height + "px",
                    borderRight:"1px solid darkgray"
                }}
                />
                <Box style={{
                    position:"absolute", left:0, top:0, width:props.width + "px", height:props.height + "px", border:"1px solid darkgray"
                }}
                />
                <Box className={"gauge"} style={{position:"absolute", left:0, top:0, width:props.width + "px", height:props.height + "px"}}>
                    <p className={"gauge-legend-sel"}>{formatNumber(props.current)}</p>
                </Box>
            </Box>
        </Box>
    );
}
