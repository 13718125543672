import {Button, FormControlLabel, Switch} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {TextFieldSmall} from "../common/TextFieldSmall";
import {SelectionEntry, TextFieldSelectSmall} from "../common/TextFieldSelectSmall";
import {useTranslation} from "react-i18next";
import {
    FE_ID_CATCHMENTS,
    FE_TIME_RANGE_YEAR,
    FlowDataContext,
    FlowDataSet,
    FlowDataTags,
    getEmptyQueryData,
    getGlobalValueFromRegionalData,
    QueryData,
    queryFlowEngine
} from "../../models/FlowContext";
import Box from "@mui/material/Box";
import "./../../App.css"
import {CreateActionRowView} from "./CreateActionRowView";
import {
    Action,
    ActionEditMode,
    ActionSet,
    copyActionSet,
    copyActionSetWithNewID,
    resetActionSetStates
} from "../../models/Action";
import {DivWithRef} from "../common/DivWithRef";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {CreateNewSimulationDialog} from "./CreateNewSimulationDialog";
import {formatCost} from "../../models/FlowContextUtils";
import {OverlayDataContext, TileType} from "../OverlayContext";

export interface CreateScenarioProps extends React.HTMLProps<any>  {
    onNewActionSelected:()=>void;
    onEditActionSelected:()=>void;
    onQuickEditToggle:(val:boolean)=>void;

}


export function CreateScenarioView(props:CreateScenarioProps) {
    // All saved action sets
    const {actionSets, setActionSets, periodSelection} = useContext(FlowDataContext);

    // The currently selected action set and the set being edited
    const {currentActionSet, setCurrentActionSet, editActionSet, setEditActionSet} = useContext(FlowDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext)

    // Drop down list to select current action set
    const [actionSetsDrop, setActionSetsDrop] = useState<Array<SelectionEntry>>([]);
    const [quickEdit, setQuickEdit] = useState<boolean>(false);

    const [simulationDialogVisible, setSimulationDialogVisible] = useState<boolean>(false);
    const [totalCost, setTotalCost] = useState<number>(0);

    const {setTileType, setTileText} = useContext(OverlayDataContext);


    const {t} = useTranslation();

    useEffect( () => {
        // Make sure currentActionSet still exist
        let currentFound:boolean = false;
        let editFound:boolean = false;
        for (const as of actionSets) {
            if (currentActionSet && as.id === currentActionSet.id) {
                currentFound = true;
            }
            if (editActionSet && as.id === editActionSet.id) {
                editFound = true;
            }
        }

        // If no current set exist
        if (!currentFound) {
            for (const as of actionSets) {
                if (as.id === "default") {
                    setCurrentActionSet(as);
                }
            }
        }
        if (!editFound) {
            for (const as of actionSets) {
                if (as.id === "default") {
                    setEditActionSet(as);
                }
            }
        }

    }, [currentActionSet, editActionSet, actionSets, setCurrentActionSet, setEditActionSet]);

    // Set the drop down list based on available action sets
    useEffect(() => {
        if (actionSets.length === 0) {
            return;
        }
        let dropList:Array<SelectionEntry> = [];
        actionSets.forEach((set) => {
            dropList.push({
                title:set.name,
                value:set.id
            });
        });
        setActionSetsDrop(dropList);

    },[actionSets]);

    useEffect(() => {
        if (!editActionSet || editActionSet.actions.length === 0) {
            setTotalCost(0);
            return;
        }
        let query: QueryData = getEmptyQueryData();
        // Get KPIs for all catchments to calculate whether an action should be enabled or not
        query.id = FE_ID_CATCHMENTS;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.period = periodSelection;
        query.fields = [FlowDataTags.cost];
        query.actionSet = editActionSet;
        queryFlowEngine(query).then((fd: FlowDataSet) => {
            let cost = getGlobalValueFromRegionalData(fd, FlowDataTags.cost)
            setTotalCost(cost);
        });
    }, [editActionSet, periodSelection])


    // A new action set is selected from the drop down
    function onActionSetSelectorChange(key:string, val:string) {
        for (const set of actionSets) {
            if (set.id === val) {
                setCurrentActionSet(set);
                let cpy = copyActionSet(set);
                setEditActionSet(cpy);
                break;
            }
        }
    }
    function setActionSetName(val:string) {
        if (editActionSet) {
            let as = copyActionSet(editActionSet);
            as.name = val;
            setEditActionSet(as);
        }
    }
    function setActionSetDesc(val:string) {
        if (editActionSet) {
            let as = copyActionSet(editActionSet);
            as.description = val;
            setEditActionSet(as);
        }
    }

    function selectActionForEdit(a:Action) {
        // This sets the original object but won't trigger a change event.
        a.editMode = ActionEditMode.edit;
        // ... but we move on so it doesn't matter
        props.onEditActionSelected();
    }

    function newActionForEdit() {
        // Go to next step
        setScenarioEvent('add-action', true)
        props.onNewActionSelected();
    }

    function onSaveCurrent() {
        if (!currentActionSet || !editActionSet) {
            // Error dialog
            return;
        }
        if (currentActionSet.readOnly) {
            // Error dialog
            return;
        }

        let editSet = copyActionSet(editActionSet);
        // Remove possible whitespaces
        editSet.name = editSet.name.trim();
        // Resets the simulation flags, now counts as not simulated again
        resetActionSetStates(editSet);
        // Replace current with edit
        let newSets:Array<ActionSet> = [];
        for (const as of actionSets) {
            if (as.id === currentActionSet.id) {
                newSets.push(editSet);
            } else {
                newSets.push(copyActionSet(as));
            }
        }
        setActionSets(newSets);
        // And set the edit set as current
        setEditActionSet(editSet);
        setCurrentActionSet(copyActionSet(editSet));
        setScenarioEvent("create-save", true);
        setSimulationDialogVisible(true);
    }

    function onSaveNew() {
        if (!currentActionSet || !editActionSet) {
            // Error dialog
            return;
        }

        let editSet = copyActionSetWithNewID(editActionSet);
        // Remove possible whitespaces
        editSet.name = editSet.name.trim();
        // Resets the simulation flags, now counts as not simulated again
        resetActionSetStates(editSet);

        let exists = false;

        let newSets:Array<ActionSet> = [];
        for (const as of actionSets) {
            newSets.push(copyActionSet(as));
            if (editSet.name === as.name) {
                let text = t("Missing:Save_name_error")
                text = text.replace("${name}", as.name)
                exists = true;
                setTileText(text);
                setTileType(TileType.error);
                break;
            }
        }
        if (exists) {
            return;
        }

        // Add edit to sets
        editSet.readOnly = false;
        // editSet.state = ActionSetState.simulated
        newSets.push(editSet);
        setActionSets(newSets);
        // And set the edit set as current
        setEditActionSet(editSet);
        setCurrentActionSet(copyActionSet(editSet));
        setScenarioEvent("create-save-new", true);
        setSimulationDialogVisible(true);
    }


    function onCancelAll() {

    }
    function onCloseSimulationDialog() {
        setSimulationDialogVisible(false)
    }
    function doSetQuickEdit(val:boolean) {
        setScenarioEvent("quick-edit", val);
        setQuickEdit(val);
    }

    function ToolRow() {
        return (
            <Box className={"box-content-row"}>
                <Box sx={{display:"flex", justifyContent:"flex-start", alignContent:"center", flex:1}}>
                    <b>{t("Planning_Create_Actions_Title")}</b>
                </Box>
                <Box sx={{display:"flex", justifyContent:"flex-end", alignContent:"center", flex:1}}>
                    <DivWithRef
                        id = {"quick-edit"}
                    >
                        <FormControlLabel control={<Switch checked={quickEdit} onChange={() => {doSetQuickEdit(true); props.onQuickEditToggle(true)}}/>} label={t("Planning_Create_Actions_QuickEdit") as string}/>
                    </DivWithRef>
                    <DivWithRef
                        id = {"add-action"}
                        >
                        <AddCircleIcon
                            id = {"add-action-button"}
                            className={"button-blue"}
                            style={{width:"32px", height:"32px"}}
                            onClick={()=>{newActionForEdit()}}
                        />
                    </DivWithRef>
                </Box>
            </Box>
        )
    }

    // Rows of existing actions in the selected edit set
    function getActionRows() : JSX.Element[] {
        if (!editActionSet) {
            return []
        }
        let groupedActions:{ [zone:string]:Action[] } = {}
        let zones:string[] = []
        for (const action of editActionSet.actions) {
            if ((action.zone in groupedActions)) {
                groupedActions[action.zone].push(action);
            } else {
                zones.push(action.zone)
                groupedActions[action.zone] = [action];
            }
        }


        let result:JSX.Element[] = [];
        for (const zone of zones) {
            result.push(<CreateActionRowView actionData={groupedActions[zone]} onSelectAction={selectActionForEdit}/>)
        }
        return result;
    }

    function CostRow() {
        if (totalCost > 0) {
            return <p className={"cost"}><b>{t("Planning_Cost")}:</b>{formatCost(totalCost)}</p>
        }
        return null;
    }
    return (
        <Box className={"box-content-col-padding"} style={{gap:"0"}}>
            {/* ---------- Header ---------- */}
            <Box className={"box-col"}>
                <Box className={"box-content-row"}>
                    <TextFieldSmall
                        width={"100%"}
                        value={(editActionSet) ? editActionSet.name : ""}
                        label={t("Planning_Create_ScenarioName")}
                        style={{width:"100%"}}
                        onChange={(event) => {setActionSetName(event.currentTarget.value)}}
                    />
                    <TextFieldSelectSmall
                        id={"scenario-pop"}
                        width={"100%"}
                        value={(editActionSet) ? editActionSet.id : undefined}
                        label={t("Planning_Create_Template")}
                        style={{width:"100%"}}
                        selectionList={actionSetsDrop}
                        onSelectChange={onActionSetSelectorChange}
                    />
                </Box>
                <Box className={"box-content-row"}>
                    <TextFieldSmall
                        width={"100%"}
                        value={(editActionSet) ? editActionSet.description : ""}
                        label={t("Planning_Create_Description")}
                        style={{width:"100%"}}
                        onChange={(event) => setActionSetDesc(event.currentTarget.value)}
                    />
                </Box>
                <Box className={"box-content-row"}>
                    <a href="" style={{fontSize:"15px", color:"teal"}}>{t("Simulation_EditScenarioEffects_ShowFactors")}</a>
                </Box>
            </Box>

            {/* ---------- Body ---------- */}
            <Box className={"box-col"} sx={{flex:1}}>
                <Box className={"box-content-auto"}>
                    {/* Row with text, quick edit and plus button */}
                    <ToolRow/>

                    {(editActionSet && editActionSet.actions.length > 0) ?
                    <Box className={"box-row"}>
                        <p className={"field-label"}>{t("Planning_Create_ActionList_Title")}</p>
                    </Box>
                    : ""
                    }
                    {
                        getActionRows()
                    }
                </Box>

            </Box>
            {/* ---------- Footer ---------- */}
            <CostRow />
            <Box className={"footer-with-buttons"}>
                <Button
                    onClick={()=>{onCancelAll()}}
                    variant={"outlined"}
                >{t("Cancel")}</Button>
                <DivWithRef id={"create-save-new"}>
                    <Button
                        onClick={()=>{onSaveNew()}}
                        variant={"outlined"}
                        id={"create-save-new"}
                    >{t("Planning_Create_SaveAsNew")}
                    </Button>
                </DivWithRef>
                <Button
                    onClick={()=>{onSaveCurrent()}}
                    disabled={(editActionSet) ? editActionSet.readOnly : false}
                    variant={"outlined"}
                    id={"create-save-current"}
                >{t("Planning_Create_Save")}</Button>
            </Box>
            <CreateNewSimulationDialog open={simulationDialogVisible} onClose={onCloseSimulationDialog} />
        </Box>
    );

}
