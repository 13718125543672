

export type httpParameters = {[key: string]: string | number} | null;

export type httpResult = {
    result: any;            // The actual data
    status: number;         // Response status
    queryID: string;        // An arbitrary ID sent in with the call
}

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_NO_CONTENT = 204;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_CONFLICT = 409;
export const HTTP_INTERNAL_SERVER_ERROR = 500;

 export async function httpGet(url: string, params: httpParameters = null, queryID: string = "", useCache: boolean = false) : Promise<httpResult> {
    try {
        let response = await fetch(url + parseQueryParams(params), {
            headers: getHeaders(useCache)
        });
        if (!response.ok) {
            return {
                result: null,
                status: response.status,
                queryID: queryID
            }
        }
        let jsonResponse = await response.json();
        return {
            result: jsonResponse,
            status: response.status,
            queryID: queryID
        }
    } catch (error:any) {
        return {
            result: null,
            status: error.status,
            queryID: queryID
        }
    }
}

export async function httpPost(url: string, body: Object | null, params: httpParameters = null, queryID: string = "")  : Promise<httpResult> {
    try {
        let response = await fetch(url + parseQueryParams(params), {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            return {
                result: null,
                status: response.status,
                queryID: queryID
            }
        }
        let jsonResponse = null;
        if (response.status !== HTTP_NO_CONTENT) {
            jsonResponse = await response.json();
        }
        return {
            result: jsonResponse,
            status: response.status,
            queryID: queryID
        }
    } catch (error:any) {
        return {
            result: null,
            status: error.code,
            queryID: queryID
        }
    }
}

export async function httpPut(url: string, body: Object, params: httpParameters = null, queryID: string = "")  : Promise<httpResult> {
    try {
        let response = await fetch(url + parseQueryParams(params), {
            method: "PUT",
            headers: getHeaders(),
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            return {
                result: null,
                status: response.status,
                queryID: queryID
            }
        }
        let jsonResponse = await response.json();
        return {
            result: jsonResponse,
            status: response.status,
            queryID: queryID
        }
    } catch (error:any) {
        console.log(error);
        return {
            result: null,
            status: error.code,
            queryID: queryID
        }
    }
}

export async function httpDelete(url: string, params: httpParameters = null, queryID: string = "")  : Promise<httpResult> {
    try {
        let response = await fetch(url + parseQueryParams(params), {
            method: "DELETE",
            headers: getHeaders()
        });
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            let jsonResponse = await response.json();
            return {
                result: jsonResponse,
                status: response.status,
                queryID: queryID
            }
        } else {
            return {
                result: null,
                status: response.status,
                queryID: queryID
            }
        }
    } catch (error:any) {
        console.log(error);
        return {
            result: null,
            status: error.code,
            queryID: queryID
        }
    }
}

export function httpStatusCodeIsOK(status: number) : boolean {
    return (status >= 200 && status < 300);
}

function getHeaders(useCache: boolean = false) : {[key: string]: string} {
        let headers : {[key: string]: string} = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        if (useCache) {
            headers["Cache-Control"] = "private";
            headers["Cache-Control"] = "max-age=500";  // Dunno, but this is mainly just to quickly get a person or address
        }


        /*
        if (checkSession()) {
            let apiToken = getSession().api_token
            this.headers = {
                ...this.headers,
                "Authorisation": `Bearer ${apiToken}`
            }
        }
         */
        return headers;
    }

    /*
    getSession() {
        let session = localStorage.getItem(SESSION_KEY)
        if (session) {
            return JSON.parse(session)
        }
        return session
    }

    checkSession() {
        return localStorage.getItem(SESSION_KEY) !== null
    }
     */



function parseQueryParams(params: httpParameters) : string {
        if (params === null) {
            return "";
        }
        let pairs: Array<string> = [];
        Object.keys(params).forEach(key => {
            pairs.push(key + "=" + params[key].toString());
        });
        return "?" + pairs.join("&");
}

