
// Should really be in the backend somehow, but for now, collected here

export type MapLocationInfo = {
    name:string;
    id:string;
}
let idToNameIdx: { [key:string]:string  } = {};
let nameToIdIdx: { [key:string]:string  } = {};
let idxInited: boolean = false;

function initIndexes() {
    for (const val of siteCatchments) {
        idToNameIdx[val.id] = val.name;
        nameToIdIdx[val.name] = val.id;
    }
    for (const val of siteDischarges) {
        idToNameIdx[val.id] = val.name;
        nameToIdIdx[val.name] = val.id;
    }
    for (const val of siteTreatmentPlants) {
        idToNameIdx[val.id] = val.name;
        nameToIdIdx[val.name] = val.id;
    }
    idxInited = true;
}
export function siteIDToSiteName(id:string) : string {
    if (!idxInited) {
        initIndexes();
    }

    let name = idToNameIdx[id] ?? "";
    return name;
}
export function siteNameToSiteID(name:string) : string {
    if (!idxInited) {
        initIndexes();
    }
    let id = nameToIdIdx[name] ?? "";
    return id;
}
export function sortedCatchments():Array<MapLocationInfo> {
    let unique:Array<MapLocationInfo> = [];
    let used:Array<string> = [];
    for (const loc of siteCatchments) {
        if (used.includes(loc.id)) {
            continue;
        }
        used.push(loc.id);
        unique.push(loc);
    }
    unique.sort((a, b):number => {
        return a.name.localeCompare(b.name);
    });
    return unique;
}

export const siteCatchments:Array<MapLocationInfo> =
    [
        {
            "name": "Kronogården",
            "id": "9"
        },
        {
            "name": "Eriksro",
            "id": "8"
        },
        {
            "name": "Lextorp",
            "id": "7"
        },
        {
            "name": "Sylte",
            "id": "6"
        },
        {
            "name": "Upphärad",
            "id": "24"
        },
        {
            "name": "Velanda",
            "id": "23"
        },
        {
            "name": "Norra Sjuntorp",
            "id": "22_b"
        },
        {
            "name": "Västra Sjuntorp",
            "id": "22_c"
        },
        {
            "name": "Östra Sjuntorp",
            "id": "22_a"
        },
        {
            "name": "Hjulkvarn-Egnahem norra-Stallbacka södra",
            "id": "15"
        },
        {
            "name": "Central staden sydvästra",
            "id": "18_a"
        },
        {
            "name": "Central staden nordvästra",
            "id": "18_b"
        },
        {
            "name": "Norra Torsred",
            "id": "3_a"
        },
        {
            "name": "Norra Strömslund",
            "id": "3_b"
        },
        {
            "name": "Åsaka",
            "id": "25"
        },
        {
            "name": "Norra Björke",
            "id": "26"
        },
        {
            "name": "Halvorstorp-Skogstorpa",
            "id": "5"
        },
        {
            "name": "Källstorp-Björndalen-Skogshöjden",
            "id": "4"
        },
        {
            "name": "Hjortmossen",
            "id": "20"
        },
        {
            "name": "Södra Torsred-Strömslund",
            "id": "2"
        },
        {
            "name": "Tingvalla östra",
            "id": "19"
        },
        {
            "name": "Egnahem östra",
            "id": "17"
        },
        {
            "name": "Centrala staden östra-Tingvalla västra-Egnahem södra",
            "id": "16"
        },
        {
            "name": "Centrala staden östra-Tingvalla västra-Egnahem s",
            "id": "16"
        },
        {
            "name": "Stavre",
            "id": "14"
        },
        {
            "name": "Centrala staden runt stadshuset",
            "id": "13"
        },
        {
            "name": "Innovatum-Centrala staden södra-Olidan",
            "id": "12"
        },
        {
            "name": "Stallbacka",
            "id": "10"
        },
        {
            "name": "Sandhem-Hojum",
            "id": "11"
        },
        {
            "name": "Skoftebyn-Åker",
            "id": "1"
        },
        {
            "name": "Södra Sjuntorp",
            "id": "21"
        }
    ];

export const siteDischarges: Array<MapLocationInfo>  =
    [
        {
            "id": "B-P39",
            "name": "B-P39"
        },
        {
            "id": "B-P16",
            "name": "B-P16"
        },
        {
            "id": "B-P3",
            "name": "B-P3"
        },
        {
            "id": "B-P5",
            "name": "B-P5"
        },
        {
            "id": "B10",
            "name": "B10"
        },
        {
            "id": "B17",
            "name": "B17"
        },
        {
            "id": "B20",
            "name": "B20"
        },
        {
            "id": "B21",
            "name": "B21"
        },
        {
            "id": "B26",
            "name": "B26"
        },
        {
            "id": "B27",
            "name": "B27"
        },
        {
            "id": "B-P1",
            "name": "B-P1"
        },
        {
            "id": "B-P40",
            "name": "B_P40"
        }
    ];

export const siteTreatmentPlants:Array<MapLocationInfo> =
    [
        {
            "id": "Arvidstorp",
            "name": "Arvidstorp"
        }
    ];

export type MapTreshold = {
    yellow:number,
    red:number
}

export const siteThresholdLevels: { [key:string] : MapTreshold } = {
    "fastRainHA": {red:6, yellow:3},
    "slowRainHA": {red:4, yellow:2},
    "groundWaterHA": {red:60, yellow:30},
    "fastRainM2PerM2": {red:0.2, yellow:0.1},
    "slowRainM2PerM2": {red:0.1, yellow:0.05},
    "groundWaterM2PerM2": {red:1, yellow:0.5},
    "fastRainM2PerPe": {red:50, yellow:25},
    "slowRainM2PerPe": {red:30, yellow:15},
    "groundWaterM2PerPe": {red:300, yellow:150},
    "fastRainM2PerM": {red:20, yellow:10},
    "slowRainM2PerM": {red:10, yellow:5},
    "groundWaterM2PerM": {red:100, yellow:50},

    "totalAmountOfOverflowVolume": {red:10000, yellow:5000},

    // TODO: Treatment plant
}
