import React, {useContext, useEffect, useRef} from "react";
import * as am5 from "@amcharts/amcharts5";
import {Circle, Root, Template} from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {FlowDataContext, FlowDataSet, FlowDataTags, getGlobalValueFromRegionalData} from "../../models/FlowContext";
import {useTranslation} from "react-i18next";
import {CompareResultSwitches} from "./CompareResultSwitches";
import {AppTheme} from "../../AppTheme";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {DivWithRef} from "../common/DivWithRef";
import {getFlowReduction} from "../../models/FlowContextUtils";

export interface ShowCostBenefitViewProps extends React.HTMLProps<any>  {
    id: string;
    flowData:{[key:string]:FlowDataSet}
    useRelativeValues:boolean
    onRelativeValuesChanged:(flag:boolean) => void
}

export function CompareCostBenefitView(props:ShowCostBenefitViewProps) {
    const {currentActionSet, inflowSelection, overflowSelection, partlyTreatedSelection} = useContext(FlowDataContext);
    const {setScenarioEvent} = useContext(ScenarioDataContext)

    const {t} = useTranslation();
    const [root, setRoot] = React.useState<Root>();

    const [resultSettings, setResultSettings] = React.useState<{ [key:string]:boolean }>(
    {
        inflow:true,
        absolute:true
    });

    const rootRef = useRef<HTMLElement>()

    /*
    useLayoutEffect(() => {
        // let r = am5.Root.new("graph-" + props.id);
        if (rootRef.current) {
            let r = am5.Root.new(rootRef.current);
            setRoot(r);
        }
    }, [props.id]);
    */
    useEffect(() => {
        for (const key in resultSettings) {
            setScenarioEvent("show-settings-" + key, resultSettings[key])
        }

    },[resultSettings,setScenarioEvent])

    /*
    const getTooltip = useCallback((root:am5.Root, label:string) : am5.Tooltip => {
        let tooltip = am5.Tooltip.new(root, {
            autoTextColor: false,
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            labelText: "[bold]" + label + "[/]\n{name} {id}\n[bold]{valueY.formatNumber('#.0')}[/]",
        });
        let bg = tooltip.get("background");
        if (bg) {
            bg.setAll({
                fill: am5.color(0xffffff),
                fillOpacity: 0.8,
            });
        }
        tooltip.label.setAll({
            textAlign:"center",
            fill:am5.color(0x0),
            fontSize:12
        });
        return tooltip
    },[]);
     */

    useEffect(() => {

        if (rootRef.current && !root) {
            let r = am5.Root.new(rootRef.current);
            setRoot(r);
        }
        if (!root || !t) {
            return;
        }

        let yFormatter = "#,###' mkr'";
        // xFormatter is always percentage for relative values, when absolute only one can be selected, so either ha or m3
        let xFormatter = (props.useRelativeValues) ? "###' %'" : (resultSettings["inflow"] ? "#,###' ha'" : "#,###' m3'");

        root.container.children.clear();
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout
            })
        );
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                numberFormatter: am5.NumberFormatter.new(root, {numberFormat: yFormatter}),
                renderer: am5xy.AxisRendererY.new(root, {}),
                // tooltip: am5.Tooltip.new(root, {})
            })
        );
        let yr = yAxis.get('renderer', null);
        if (yr) {
            yr.labels.template.set('fontSize', 12);
        }

        let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                numberFormatter: am5.NumberFormatter.new(root, {numberFormat: xFormatter}),
                renderer: am5xy.AxisRendererX.new(root, {}),
                // tooltip: am5.Tooltip.new(root, {})
            })
        );
        let xr = xAxis.get('renderer', null);
        if (xr) {
            xr.labels.template.set('fontSize', 12);
            xr.labels.template.set('paddingTop', 10);
        }

        chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis
        }));


        for (const resultTag of ["inflow", "overflow", "partlyTreated"]) {
            if (!resultSettings[resultTag]) {
                continue; // Data type is not selected
            }
            let dataTag = "";
            let fillColor = "";

            switch (resultTag) {
                case "inflow":
                    dataTag = inflowSelection;
                    fillColor = AppTheme.palette.globalInflowDark
                    break;
                case "overflow":
                    dataTag = overflowSelection;
                    fillColor = AppTheme.palette.globalOverflowDark
                    break;
                case "partlyTreated":
                    dataTag = partlyTreatedSelection;
                    fillColor = AppTheme.palette.globalTreatedDark
            }

            let baseSettings = {
                name: t("Planning_Status_Graph_PartlyTreated_USGa"),
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "cost",
                valueXField: "value",
                maskBullets: false,
                // TODO: This is the tool tip that works, but not really
                // tooltip: am5.Tooltip.new(root, {labelText:"{valueY},{valueX}"})
            };

            let baseSeries = chart.series.push(
                am5xy.LineSeries.new(root, baseSettings)
            );


            // Add bullet
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
            let circleTemplate: Template<Circle> = am5.Template.new({});

            baseSeries.bullets.push(function () {
                let graphics = am5.Circle.new(root, {
                    radius: 20,
                    fill: am5.color(fillColor),
                    interactive: true,
                    // tooltip: am5.Tooltip.new(root, {labelText:"C"})
                }, circleTemplate);
                graphics.events.on("click", function(ev) {
                    console.log("Clicked on a bullet!", ev.target);
                });
                // graphics.set("tooltip", getTooltip(chart.root, t("Planning_Create_Current_Situation")));
                return am5.Bullet.new(root, {
                    sprite: graphics
                });
            });

            let graphData: { [key: string]: any }[] = [];

            for (const key in props.flowData) {
                const localFlow = props.flowData[key];
                let entry = {
                    cost: getGlobalValueFromRegionalData(localFlow, FlowDataTags.cost) / 1000000,
                    value: (localFlow.id === "default") ? 0 : getFlowReduction(props.flowData, localFlow.id, dataTag, props.useRelativeValues),
                    id: localFlow.id
                }
                graphData.push(entry);
            }
            baseSeries.data.setAll(graphData);
        }




        /*
        baseSeries.data.setAll(
            [
                {x:10, y:10},
                {x:50, y:50},
                {x:99, y:99}

            ]
        );
         */

        /*
        // unshift adds it on top
        let legend = chart.children.unshift(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            y: am5.percent(-5),
        }));

        legend.data.setAll(chart.series.values);

        legend.labels.template.setAll({
            fontSize: 12
        });

         */

        // Make stuff animate on load
        chart.appear(1000, 100).then();

    }, [root, t, currentActionSet, props.flowData, inflowSelection, overflowSelection, partlyTreatedSelection, resultSettings, props.useRelativeValues]);

    function resultSettingChanged(key:string, value:boolean) {
        let results = {...resultSettings}
        if (key === "absolute") {
            results["absolute"] = !results["absolute"]
            setResultSettings(results);
            props.onRelativeValuesChanged(!results["absolute"])
        } else {
            if (results["absolute"]) {
                results["inflow"] = false;
                results["overflow"] = false;
                results["partlyTreated"] = false;
                results[key] = value;
            } else {
                results[key] = !results[key];
            }
            setResultSettings(results);
        }
    }

    return (
        <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}>
            <div style={{padding:"0px 16px 0px 16px"}}><b>{t("Planning_Compare_CompareGraph_Title")}</b></div>
            <div style={{flex:1, display:"flex", flexDirection:"row", width:"100%", height:"100%"}}>
                <div
                    style={{flex:1, height:"100%"}}
                    ref={node => rootRef.current = node ? node : undefined}
                    id={"graph-" + props.id}
                />
                <DivWithRef id={"show-settings-view"}>
                    <CompareResultSwitches resultSettings={resultSettings} onResultSettingChanged={resultSettingChanged} />
                </DivWithRef>
            </div>
        </div>
    );
}
