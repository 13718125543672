import {FlowDataEntry, FlowDataMap, FlowDataSet, getGlobalValueFromRegionalData} from "./FlowContext";


export function getFlowReduction(flowData: FlowDataMap, compareId:string, dataTag:string, useRelative:boolean) : number {
    let reduction = 0;
    let defaultValue = 0;
    let currentValue = 0;
    if (compareId === "default") {
        return 0
    }
    for (const key in flowData) {
        if (flowData[key].id === "default") {
            defaultValue = getGlobalValueFromRegionalData(flowData[key], dataTag)
        } else if (flowData[key].id === compareId) {
            currentValue = getGlobalValueFromRegionalData(flowData[key], dataTag)
        }
    }
    reduction = defaultValue - currentValue;
    if (useRelative) {
        if (defaultValue !== 0) {
            // Relative reduction
            reduction = (reduction / defaultValue) * 100
        }
    }
    return reduction;
}

export function sortFlowDataByCatchmentId(flowData: FlowDataSet) {
    flowData.years.sort(flowEntryIdCompare)
    flowData.months.sort(flowEntryIdCompare)
    flowData.days.sort(flowEntryIdCompare)
}
export function sortFlowDataByName(flowData: FlowDataSet) {
    flowData.years.sort(flowEntryNameCompare)
    flowData.months.sort(flowEntryNameCompare)
    flowData.days.sort(flowEntryNameCompare)
}

function flowEntryNameCompare(a:FlowDataEntry, b:FlowDataEntry) : number {
    let typeA = a["type"] ?? "0";
    let typeB = b["type"] ?? "0";
    let cmp = typeA.localeCompare(typeB);
    if (cmp !== 0) {
        return cmp;
    }
    let nameA: string = a["name"] ?? "0";
    let nameB: string = b["name"] ?? "0";
    return nameA.localeCompare(nameB);
}
function flowEntryIdCompare(a:FlowDataEntry, b:FlowDataEntry) : number {
    let typeA = a["type"] ?? "0";
    let typeB = b["type"] ?? "0";
    let cmp = typeA.localeCompare(typeB);
    if (cmp !== 0) {
        return cmp;
    }
    let idA: string = a["id"] ?? "0";
    let idB: string = b["id"] ?? "0";
    let cpA: string[];
    let cpB: string[];

    idA = idA.replace("_", "-")
    idA = idA.replace("B-P", "")
    if (idA.startsWith("B")) {
        idA = idA.substring(1);
    }
    idB = idB.replace("_", "-")
    idB = idB.replace("B-P", "")
    if (idB.startsWith("B")) {
        idB = idB.substring(1);
    }

    cpA = idA.split("-");
    cpB = idB.split("-");

    cmp = cpA[0].localeCompare(cpB[0], undefined, {numeric: true, sensitivity: 'base'})
    if (cmp !== 0) {
        return cmp
    }
    if (cpA.length === 1) {
        cpA.push("")
    }
    if (cpB.length === 1) {
        cpB.push("")
    }
    return cpA[1].localeCompare(cpB[1])
}

export function formatCost(cost:number) : string {
    let result = ""
    if (cost >= 1000000) {
        result = (cost / 1000000).toFixed(2) + " mkr"
    } else if (cost >= 1000) {
        result = (cost / 1000).toFixed(1) + " tkr"
    } else {
        result = cost.toFixed(0) + " kr"
    }
    return result;
}
