    import * as React from "react";
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import "./../App.css"
import {Link, useLocation} from "react-router-dom";
import {useContext, useRef} from "react";
import {ScenarioDataContext} from "../models/ScenarioContext";
export function LeftBarView() {
    const {t} = useTranslation();
    const {setScenarioElement} = useContext(ScenarioDataContext)

    let l = useLocation();
    let path = l.pathname;
    const navbarStatus = useRef<HTMLAnchorElement | null>(null);
    const navbarCreate = useRef<HTMLAnchorElement | null>(null);
    const navbarShow = useRef<HTMLAnchorElement | null>(null);
    const navbarPlan = useRef<HTMLAnchorElement | null>(null);
    const navbarScenarios = useRef<HTMLAnchorElement | null>(null);
    setScenarioElement("navbar-status", navbarStatus);
    setScenarioElement("navbar-create", navbarCreate);
    setScenarioElement("navbar-show", navbarShow);
    setScenarioElement("navbar-plan", navbarPlan);
    setScenarioElement("navbar-scenarios", navbarScenarios);

    return (
        <Box className="left-bar" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'flex-start'}}>

            <Link ref={navbarStatus} to="/status" id="navbar-status"
                style={{borderTop:"lightgray solid 1px"}}
                className={(path === "/status") ? "selected" : "default"}
            >{t("Planning_Navigation_Status")}</Link>

            <Link ref={navbarCreate} to="/create" id="navbar-create"
                  className={(path === "/create") ? "selected" : "default"}
            >{t("Planning_Navigation_Create")}</Link>

            <Link ref={navbarShow} to="/show" id="navbar-show"
                  className={(path === "/show") ? "selected" : "default"}
            >{t("Planning_Navigation_Compare")}</Link>

            {/*
            <Link ref={navbarPlan} to="/plan" id="navbar-plan"
                  className={(path === "/plan") ? "selected" : "default"}
            >{t("Planning_Navigation_Strategic")}</Link>
            */}
            <p id="navbar-plan"
                  className={(path === "/plan") ? "selected" : "default"}
            >{t("Planning_Navigation_Strategic")}</p>


            <div style={{flex:1}} />

            <Link ref={navbarScenarios} to="/scenarios" id="navbar-scenarios"
                  style={{borderTop:"lightgray solid 1px"}}
                  className={(path === "/scenarios") ? "selected" : "default"}
            >{t("Planning_Navigation_Scenarios")}</Link>
        </Box>
    )
}
