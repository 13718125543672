import {GraphInflowView} from "../common/GraphInflowView";
import React from "react";
import {TimeUnitSelector} from "../common/TimeUnitSelector";
import {Box} from "@mui/material";


export function StatusGraphInflowView() {
    const [timeUnit, setTimeUnit] = React.useState<string>("month");
    return (
        <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'center', width:"100%", height:"100%"}}>
            <Box sx={{width:'90%', flex:'1'}}>
                <GraphInflowView id={"graph-inflow"} timeUnit={timeUnit}/>
            </Box>
            <Box sx={{height:'60px'}}>
                <TimeUnitSelector timeUnit={timeUnit} onTimeUnitChange={(value)=>setTimeUnit(value)} />
            </Box>
        </Box>
    );
}
