// Used if we need to dispatch a pointer event on an object that normally doesn't do that
import {useContext} from "react";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export function DivWithEventDispatch(props:any) {
    const {setScenarioEvent} = useContext(ScenarioDataContext)

    /*
    function clickDispatch(e:React.MouseEvent) {
        if (e.currentTarget) {
            var evt1 = new PointerEvent("pointerdown", {bubbles:true});
            var evt2 = new PointerEvent("pointerup", {bubbles:true});
            e.currentTarget.dispatchEvent(evt1);
            e.currentTarget.dispatchEvent(evt2);
        }
    }
     */
    function clickDispatch(id:string) {
        setScenarioEvent(id, true)
    }

    return <div
        id = {props.id}
        onClick={() => clickDispatch(props.id)}
        style={props.style}
    >
        {props.children}
    </div>



}
