import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {AppTheme} from "../../AppTheme";
import {useTranslation} from "react-i18next";
import {FlowDataContext, FlowDataSet, FlowDataTags, getGlobalValueFromRegionalData} from "../../models/FlowContext";
import {formatCost, getFlowReduction} from "../../models/FlowContextUtils";
import {formatNumber} from "../../utils/textUtils";
import {MissionMeterView} from "./MissionMeterView";
import {ActionSet, ActionSetState} from "../../models/Action";
import {ScenarioConditionLogic, ScenarioConditionType} from "../../models/ScenarioModels";
import {ScenarioDataContext} from "../../models/ScenarioContext";
import {DivWithRef} from "../common/DivWithRef";

export type GaugeParameters = {
    min: number
    max: number
    current: number
    goal: number
    reduction: number
}

export function GetEmptyGaugeParameters() : GaugeParameters {
    return {
        current: 0, max: 0, min: 0, goal: 0, reduction: 0
    }
}

export interface MissionMetersViewProps extends React.HTMLProps<any>  {
    selectedActionSet: ActionSet|null;
    flowData:{[key:string]:FlowDataSet}
}

export function MissionMetersView(props:MissionMetersViewProps) {
    const {inflowSelection, overflowSelection, partlyTreatedSelection, periodSelection} = useContext(FlowDataContext);
    const {currentScenario} = useContext(ScenarioDataContext)

    const {t} = useTranslation()
    const [inflowParams, setInflowParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());
    const [overflowParams, setOverflowParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());
    const [partlyTreatedParams, setPartlyTreatedParams] = useState<GaugeParameters>(GetEmptyGaugeParameters());
    const [totalCost, setTotalCost] = useState<number>(0);

    const meterWidth = 180;
    const meterHeight = 16;


    useEffect(() => {

        if (!props.selectedActionSet || (!props.flowData[props.selectedActionSet.id] ?? false)) {
            return;
        }
        if (!currentScenario) {
            return;
        }
        let state = props.selectedActionSet.states[periodSelection] ?? ActionSetState.created;
        let simulated = (state === ActionSetState.simulated);

        function getGoal(type:ScenarioConditionType, defaultValue:number) : number {
            let result = 0
            if (!currentScenario) {
                return result;
            }

            for (const condition of currentScenario.winConditions) {
                if (condition.type === type) {
                    if (condition.logic === ScenarioConditionLogic.reducedRelative) {
                        result = defaultValue - defaultValue * condition.value;
                    }
                }
            }
            return result
        }

        let params = GetEmptyGaugeParameters();
        let reduction:number;
        let current:number;
        let defValue;

        // ------------------ Inflow ----------------
        defValue = getGlobalValueFromRegionalData(props.flowData["default"], FlowDataTags.totalInflowPercentage)
        if (simulated) {
            current = getGlobalValueFromRegionalData(props.flowData[props.selectedActionSet.id], FlowDataTags.totalInflowPercentage)
            reduction = getFlowReduction(props.flowData, props.selectedActionSet.id, FlowDataTags.groundWater, true)
        } else {
            current = defValue;
            reduction = 0;
        }
        params.reduction = reduction;
        params.current = current
        params.goal = getGoal(ScenarioConditionType.inflow, defValue);
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setInflowParams(params)

        // ------------------ Overflow ----------------
        params = GetEmptyGaugeParameters();
        defValue = getGlobalValueFromRegionalData(props.flowData["default"], FlowDataTags.totalOverflowPercentage)
        if (simulated) {
            current = getGlobalValueFromRegionalData(props.flowData[props.selectedActionSet.id], FlowDataTags.totalOverflowPercentage)
            reduction = getFlowReduction(props.flowData, props.selectedActionSet.id, FlowDataTags.overflow, true)
        } else {
            current = defValue;
            reduction = 0;
        }
        params.reduction = reduction;
        params.current = current
        params.goal = getGoal(ScenarioConditionType.overflow, defValue);
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setOverflowParams(params)

        // ------------------ Partially treated ----------------
        params = GetEmptyGaugeParameters();
        let currentSelection = (partlyTreatedSelection === FlowDataTags.partiallyUpper) ?
            FlowDataTags.totalPartiallyUpperPercentage : FlowDataTags.totalPartiallyLowerPercentage;

        defValue = getGlobalValueFromRegionalData(props.flowData["default"], currentSelection);
        if (simulated) {
            current = getGlobalValueFromRegionalData(props.flowData[props.selectedActionSet.id], currentSelection)
            reduction = getFlowReduction(props.flowData, props.selectedActionSet.id, partlyTreatedSelection, true)
        } else {
            current = defValue;
            reduction = 0;
        }
        params.reduction = reduction;
        params.current = current
        let scenarioCondition = (currentSelection === FlowDataTags.totalPartiallyUpperPercentage) ? ScenarioConditionType.treatedUpper : ScenarioConditionType.treatedLower;
        params.goal = getGoal(scenarioCondition, defValue)
        params.max = (params.current < 7.0) ? 10.0 : 100.0;
        setPartlyTreatedParams(params)

        let c = getGlobalValueFromRegionalData(props.flowData[props.selectedActionSet.id], FlowDataTags.cost)
        setTotalCost(c)


    },[currentScenario, inflowSelection, overflowSelection, partlyTreatedSelection, periodSelection, props.flowData, props.selectedActionSet])

    function conditionIsVisible(conditionType:ScenarioConditionType) : boolean {
        if (!currentScenario) {
            return false;
        }
        for (const condition of currentScenario.winConditions) {
            if (condition.type === conditionType) {
                return true;
            }
        }
        return false
    }
    function getBudget() : number {
        let result = 0
        if (!currentScenario) {
            return result;
        }

        for (const condition of currentScenario.winConditions) {
            if (condition.type === ScenarioConditionType.budget) {
                result = condition.value > result ? condition.value : result;
            }
        }
        return result
    }


    return (
        <DivWithRef id={"mission-meters"}>
        <Box className={"box-row"} style={{gap:"16px", alignItems:"flex-start", padding:"0px 0px 0px 16px"}}>
            <Box style={{minWidth:"300px", height:"100%"}}>
                <p className={"mission-bar-title"}>{currentScenario ? t("Scenarios:" + currentScenario.title) : " "}</p>
                <p className={"mission-bar-body"}>{currentScenario ? t("Scenarios:" + currentScenario.shortBody) : " "}</p>
            </Box>
            {
                conditionIsVisible(ScenarioConditionType.inflow) ?
                    <Box>
                        <Box>
                            <p className={'mission-bar-title'}>{t("Simulator_InflowInfiltrationPercent")}</p>
                            <MissionMeterView
                                id={"inflow-gauge"} color={AppTheme.palette.globalInflowDark}
                                width={meterWidth} height={meterHeight}
                                min={inflowParams.min} max={inflowParams.max}
                                current={inflowParams.current} goal={inflowParams.goal}
                            />
                        </Box>
                        <p className={'mission-bar-body'}>{
                            (inflowParams.reduction <= 0) ?
                                t("Planning_Compare_Chart_No_Effect") :
                                t("Planning_Compare_Chart_Reduction") + " " + formatNumber(inflowParams.reduction) + "%"
                        }</p>
                    </Box>
                    : null
            }
            {
                conditionIsVisible(ScenarioConditionType.overflow) ?
                    <Box>
                        <Box>
                            <p className={'mission-bar-title'}>{t("Simulator_SewerOverflowPercent")}</p>
                            <MissionMeterView
                                id={"overflow-gauge"} color={AppTheme.palette.globalOverflowDark}
                                width={meterWidth} height={meterHeight}
                                min={overflowParams.min} max={overflowParams.max}
                                current={overflowParams.current} goal={overflowParams.goal}
                            />
                            <p className={'mission-bar-body'}>{
                            (overflowParams.reduction <= 0) ?
                                t("Planning_Compare_Chart_No_Effect") :
                                t("Planning_Compare_Chart_Reduction") + " " + formatNumber(overflowParams.reduction) + "%"
                        }</p>
                        </Box>
                    </Box>
                    : null
            }
            {
                conditionIsVisible(ScenarioConditionType.treatedLower) || conditionIsVisible(ScenarioConditionType.treatedUpper) ?
                    <Box>
                        <Box>
                            <p className={'mission-bar-title'}>{t("Simulator_PartlyTreatedPercent")}</p>
                            <MissionMeterView
                                id={"partly-treated-gauge"} color={AppTheme.palette.globalTreatedDark}
                                width={meterWidth} height={meterHeight}
                                min={partlyTreatedParams.min} max={partlyTreatedParams.max}
                                current={partlyTreatedParams.current} goal={partlyTreatedParams.goal}
                            />
                        </Box>
                        <p className={'mission-bar-body'}>{
                            (partlyTreatedParams.reduction <= 0) ?
                                t("Planning_Compare_Chart_No_Effect") :
                                t("Planning_Compare_Chart_Reduction") + " " + formatNumber(partlyTreatedParams.reduction) + "%"
                        }</p>
                    </Box>
                    : null

            }
            {
                conditionIsVisible(ScenarioConditionType.budget) ?
                    <Box className={"box-row"} style={{height:"100%"}}>
                        <Box style={{height:"100%"}}>
                            <p className={"mission-bar-title"}>{t("ScenarioUI:scenarios_bar_budget")}</p>
                            <p className={"mission-bar-title"}>{t("ScenarioUI:scenarios_bar_cost")}</p>
                        </Box>
                        <Box style={{height:"100%"}}>
                            <p className={"mission-bar-info"}>{formatCost(getBudget())}</p>
                            <p className={"mission-bar-info"}>{formatCost(totalCost)}</p>
                        </Box>
                    </Box>
                    :
                    null
            }
        </Box>
        </DivWithRef>
    )
}
