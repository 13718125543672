import * as React from "react";
import {faCodeFork, faExchange, faHome, faTint, faTree} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ActionIconProps extends React.HTMLProps<any>  {
    action:string;
}

export function ActionIcon(props:ActionIconProps) {
    let icon = <FontAwesomeIcon icon={faCodeFork} />;
    switch (props.action) {
        case "separatePipes":
            icon = <FontAwesomeIcon icon={faCodeFork} />;
            break;
        case "activateDuplicated":
            icon = <FontAwesomeIcon icon={faExchange} />;
            break;
        case "fixConnections":
            icon = <FontAwesomeIcon icon={faHome} />;
            break;
        case "reliningSeparated":
            icon = <FontAwesomeIcon icon={faTint} />;
            break;
        case "reliningCombined":
            icon = <FontAwesomeIcon icon={faTint} />;
            break;
        case "blueGreen":
            icon = <FontAwesomeIcon icon={faTree} />;
            break;
        default:
    }
    return (<div>{icon}</div>);

}
