import React, {RefObject, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom'
import {
    parseScenario,
    Scenario,
    ScenarioCondition,
    ScenarioConditionLogic,
    ScenarioConditionType,
    ScenarioStep,
} from "./ScenarioModels";

import {
    FE_ID_LOCAL,
    FE_TIME_RANGE_YEAR,
    FlowDataContext,
    FlowDataMap,
    FlowDataTags,
    getEmptyQueryData,
    getGlobalValueFromRegionalData,
    QueryData,
    queryFlowEngine,
    queryFlowEngineForScenarioStates
} from "./FlowContext";
import {ActionSet, ActionSetState, getDefaultActionSet, getEmptyActionSet} from "./Action";
import {httpGet, httpResult, httpStatusCodeIsOK} from "../api/HttpServices";
import {getFlowReduction} from "./FlowContextUtils";

// let refsByID:{[key:string]:RefObject<HTMLElement | undefined>} = {};

type ElementRef = RefObject<HTMLElement | undefined>
type ElementRefTable = {[key:string]:ElementRef}

interface ScenarioDataContextInterface {
    scenarios:Array<Scenario>;
    setScenarios:(val:Array<Scenario>) => void;
    currentScenario:Scenario | null;
    setCurrentScenario:(val:Scenario | null) => void;
    currentScenarioStep:ScenarioStep | null;
    setCurrentScenarioStep:(val:ScenarioStep | null) => void;

    resetScenarioEvents:() => void;
    setScenarioEvent:(key:string, val:any) => void;
    getScenarioEvent:(key:string) => any;
    setScenarioElement:(key:string, val:ElementRef) => void;
    getScenarioElement:(key:string) => ElementRef;

    addMessageListener:(listener:string, id:string, func:(val:any) => void) => void;
    removeMessageListener:(listener:string, id:string) => void;
    sendMessage:(listener:string, val:any) => void;

}

export const ScenarioDataContext = React.createContext<ScenarioDataContextInterface>({
    scenarios:[],
    setScenarios:() => {},
    currentScenario:null,
    setCurrentScenario:() => {},
    currentScenarioStep:null,
    setCurrentScenarioStep:() => {},

    resetScenarioEvents:() => {},
    setScenarioEvent:() => {},
    getScenarioEvent:() => {},

    setScenarioElement:() => {},
    getScenarioElement:() : ElementRef  => {return {} as RefObject<any>},

    addMessageListener:() => {},
    removeMessageListener:() => {},
    sendMessage:() => {}
});


export function ScenarioContext(props:any) {
    // Set up context values
    const [scenarios, setScenarios] = useState<Array<Scenario>>([]);
    const [currentScenario, setCurrentScenario] = useState<Scenario | null>(null);
    const [currentScenarioStep, setCurrentScenarioStep] = useState<ScenarioStep | null>(null);

    const [scenarioEventChanged, setScenarioEventChanged] = useState<number>(0);

    const scenarioEventCounterRef = useRef<number>(0)
    const scenarioEventRef = useRef<{[key:string]:any}>({})
    const scenarioElementRef = useRef<ElementRefTable>({})

    type listenerObj = {
        function:(val:any)=>void,
        id:string
    }
    const messageListeners = useRef<{[listener:string]:listenerObj[]}>({})

    const {periodSelection, setActionSets} = useContext(FlowDataContext);

    const onResetScenarioEvents = useCallback(() => {
        scenarioEventRef.current = {}
    },[scenarioEventRef])

    const onSetScenarioEvent = useCallback((key:string, val:any) => {
        console.log("missions, set event:" + key + " to:" + val)
        scenarioEventRef.current[key] = val;
        // Not sure about this, but I don't seem to be inside correct scope here, so this forces an update inside main scope
        scenarioEventCounterRef.current = scenarioEventCounterRef.current + 1
        setScenarioEventChanged(scenarioEventCounterRef.current);
    }, [scenarioEventRef, scenarioEventCounterRef]);

    const onResetScenarioEvent = useCallback((key:string) => {
        delete scenarioEventRef.current[key];
    },[scenarioEventRef])

    const onGetScenarioEvent = useCallback((key:string) : any => {
        return scenarioEventRef.current[key] ?? null
    },[scenarioEventRef])


    const onSetScenarioElement = useCallback((key:string, val: ElementRef) => {
        scenarioElementRef.current[key] = val;
    }, [scenarioElementRef]);

    const onGetScenarioElement = useCallback((key:string) : ElementRef => {
        return scenarioElementRef.current[key]
    },[scenarioElementRef])

    const onAddMessageListener = useCallback((listener:string, id:string, func:(val:any) => void) : void  => {
        let listenerFunctions = messageListeners.current[listener] ?? [];
        for (const funcObj of listenerFunctions) {
            if (funcObj.id === id) {
                funcObj.function = func;
                return;
            }
        }
        listenerFunctions.push({
            function:func,
            id:id
        });
        messageListeners.current[listener] = listenerFunctions;
    }, [messageListeners]);

    const onRemoveMessageListener = useCallback((listener:string, id:string) : void => {
        let listenerFunctions = messageListeners.current[listener] ?? [];
        let newFunctions:listenerObj[] = [];
        for (const funcObj of listenerFunctions) {
            if (funcObj.id === id) {
                continue;
            }
            newFunctions.push(funcObj)
        }
        messageListeners.current[listener] = newFunctions;
    },[messageListeners])

    const onSendMessage = useCallback((listener:string, val:any) : void => {
        let listenerFunctions = messageListeners.current[listener] ?? [];
        for (const funcObj of listenerFunctions) {
            funcObj.function(val);
        }
    },[messageListeners])


    const {currentActionSet} = useContext(FlowDataContext);

    const [resetScenarioEvents] = useState<() => void>(() => onResetScenarioEvents);
    const [setScenarioEvent] = useState<(key:string, val:any) => void>(() => onSetScenarioEvent);
    const [getScenarioEvent] = useState<(key:string) => any>(() => onGetScenarioEvent);

    const [setScenarioElement] = useState<(key:string, val:ElementRef) => void>(() => onSetScenarioElement);
    const [getScenarioElement] = useState<(key:string) => ElementRef>(() => onGetScenarioElement);
    const [addMessageListener] = useState<(listener:string, id:string, func:(val:any) => void) => void>(() => onAddMessageListener);
    const [removeMessageListener] = useState<(listener:string, id:string) => void>(() => onRemoveMessageListener);
    const [sendMessage] = useState<(listener:string, val:any) => void>(() => onSendMessage);

    const scenarioContextValues = {
        scenarios, setScenarios,
        currentScenario, setCurrentScenario,
        currentScenarioStep, setCurrentScenarioStep,
        resetScenarioEvents, setScenarioEvent, getScenarioEvent,
        setScenarioElement, getScenarioElement,
        addMessageListener, removeMessageListener, sendMessage
    }

    let location = useLocation();

    // Init missions (only triggered once)
    useEffect(() => {
        loadScenarios().then((result) => {
            setScenarios(result);
        })
    },[])

    // Triggers when current scenario has changed
    useEffect(() => {
        if (!currentScenario || currentScenario.steps.length === 0) {
            setCurrentScenarioStep(null);
            return;
        }

        // Reset action sets (scenarios)
        let defaultSets:ActionSet[] = [];
        defaultSets.push(getDefaultActionSet());
        setActionSets(defaultSets);

        runScenarioSteps(currentScenario, 0, () => {
           // All done, do something?
            setCurrentScenarioStep(null);
            setCurrentScenario(null);
        });

        function runScenarioSteps(scenario:Scenario, idx:number, scenarioCallback:ScenarioCallback) {
            if (idx >= scenario.steps.length) { // All done
                setCurrentScenarioStep(null);
                scenarioCallback(scenario);
                return;
            }

            let nextStep = scenario.steps[idx];
            // Set the step callback recursively to call back to this function when done
            nextStep.stepCallback = () => {
                runScenarioSteps(scenario, idx + 1, scenarioCallback);
            };
            // Sometimes we want to reset an event before we start, sometimes we don't
            if (nextStep.stepCondition.resetAtStart) {
                let eventsToReset:string[] = []
                if (nextStep.stepCondition.type === ScenarioConditionType.event) {
                    eventsToReset = [nextStep.stepCondition.id]
                } else if (nextStep.stepCondition.type === ScenarioConditionType.multiEvent) {
                    eventsToReset = nextStep.stepCondition.id.split(",")
                }
                for (const event of eventsToReset) {
                    onResetScenarioEvent(event)
                }
            }

            // Setting this step as current will trigger events depending on the type,
            // for example show an overlay or register a timeout
            setCurrentScenarioStep(nextStep);
        }

    }, [currentScenario, onResetScenarioEvent, setActionSets]);

    // Triggers when current scenario step or current action set has changed, handles screen clicks and timers
    useEffect(() => {
        if (!currentScenarioStep || !currentScenario) {
            return;
        }
        let conditionsToCheck:Array<ScenarioCondition> = [];

        switch (currentScenarioStep.stepCondition.type) {
            case ScenarioConditionType.time:
                setTimeout(() => {
                        if (currentScenarioStep && currentScenarioStep.stepCallback) {
                            currentScenarioStep.stepCallback();
                        }
                    }, (currentScenarioStep.stepCondition.value as number) * 1000);
                break;
            case ScenarioConditionType.save:
                // Save and continue immediately
                currentScenario.savedLevel = currentScenario.completedLevel;
                persistScenarioData([currentScenario])
                setTimeout(() => {
                    if (currentScenarioStep && currentScenarioStep.stepCallback) {
                        currentScenarioStep.stepCallback();
                    }
                }, 100);
                break;
            case ScenarioConditionType.message:
                // Send a message and continue immediately
                let id = currentScenarioStep.stepCondition.id;
                let value = currentScenarioStep.stepCondition.value;
                sendMessage(id, value);
                setTimeout(() => {
                    if (currentScenarioStep && currentScenarioStep.stepCallback) {
                        currentScenarioStep.stepCallback();
                    }
                }, 100);
                break;
            case ScenarioConditionType.click:
                // Do nothing, wait for events to trigger
                break;
            case ScenarioConditionType.event:
                // Do nothing, wait for events to trigger
                break;
            case ScenarioConditionType.url:
                // Waits for the user to switch url
                if (currentScenarioStep.stepCondition.value === location.pathname) {
                    if (currentScenarioStep.stepCallback) {
                        currentScenarioStep.stepCallback();
                    }
                }
                break;

            case ScenarioConditionType.inflow:
            case ScenarioConditionType.overflow:
            case ScenarioConditionType.treatedUpper:
            case ScenarioConditionType.treatedLower:
                // This is when an actual step is dependent on a change in the flow engine.
                conditionsToCheck.push(currentScenarioStep.stepCondition);
                break;
            case ScenarioConditionType.win:
                conditionsToCheck = currentScenario.winConditions;
                break;
        }
        if (conditionsToCheck.length === 1 && conditionsToCheck[0].type === ScenarioConditionType.none) {
            // This is a check for just completing the level and so it will set the completed level and always pass
            currentScenario.completedLevel = currentScenario.levels;
            if (currentScenarioStep.stepCallback) {
                currentScenarioStep.stepCallback();
            }
        } else if (conditionsToCheck.length > 0 && currentActionSet && currentActionSet.states[periodSelection] === ActionSetState.simulated) {
            queryFlowEngineForScenarioStates([currentActionSet], periodSelection).then((result) => {
                let conditionResponses = checkFlowConditionsWithFlowData(result, conditionsToCheck, currentActionSet, periodSelection);
                let levelPassed = 0
                for (let level = 1; level <= currentScenario.levels; level++) {
                    let success = true;
                    for (const conditionResponse of conditionResponses) {
                        if (conditionResponse.level > level) {
                            continue;
                        }
                        if (!conditionResponse.success) {
                            success = false;
                            break;
                        }
                    }
                    if (success) {
                        levelPassed = level
                    }
                }
                // If we've reached a new level (or max level), continue
                if ((levelPassed > currentScenario.savedLevel || levelPassed >= currentScenario.levels) && currentScenarioStep.stepCallback) {
                    currentScenario.completedLevel = levelPassed;
                    currentScenarioStep.stepCallback();
                }
            });

            /*
            checkFlowConditions(conditionsToCheck, currentActionSet, periodSelection).then((response) => {
                let levelPassed = 0
                for (let level = 1; level <= currentScenario.levels; level++) {
                    let success = true;
                    for (const conditionResponse of response) {
                        if (conditionResponse.level > level) {
                            continue;
                        }
                        if (!conditionResponse.success) {
                            success = false;
                            break;
                        }
                    }
                    if (success) {
                        levelPassed = level
                    }
                }
                // If we've reached a new level (or max level), continue
                if ((levelPassed > currentScenario.savedLevel || levelPassed >= currentScenario.levels) && currentScenarioStep.stepCallback) {
                    currentScenario.completedLevel = levelPassed;
                    currentScenarioStep.stepCallback();
                }
            });
             */
        }
    },[currentActionSet, currentScenario, currentScenarioStep, location, periodSelection, sendMessage]);


    // Handles moving step forward on event change
    useEffect(() => {
        if (!currentScenarioStep || !currentScenarioStep.stepCallback) {
            return;
        }
        if (currentScenarioStep.stepCondition.type !== ScenarioConditionType.event &&
            currentScenarioStep.stepCondition.type !== ScenarioConditionType.multiEvent) {
            return;
        }

        if (!scenarioEventChanged) {
            return;
        }

        let ids:string[];
        let logics:string[];
        let values:any[];
        if (currentScenarioStep.stepCondition.type === ScenarioConditionType.event) {
            ids = [currentScenarioStep.stepCondition.id];
            logics = [currentScenarioStep.stepCondition.logic];
            values = [currentScenarioStep.stepCondition.value];
        } else {  // Multi-event, where several events should be true
            ids = currentScenarioStep.stepCondition.id.split(",")
            logics = currentScenarioStep.stepCondition.logic.split(",")
            values = currentScenarioStep.stepCondition.value; // This has to be an array
        }
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const logic = logics[i];
            const value = values[i];

            let eventVal = scenarioEventRef.current[id] ?? null;
            if (eventVal === null) {
                return;
            }

            switch (logic) {
                case ScenarioConditionLogic.equals:
                    if (eventVal !== value) {
                        return;
                    }
                    break;
                case ScenarioConditionLogic.equalsNot:
                    if (eventVal === value) {
                        return;
                    }
                    break;
                case ScenarioConditionLogic.contains:
                    if (!eventVal.includes(value)) {
                        return;
                    }
                    break;
                default:
                    return;
            }
        }
        // Pass!

        // Reset it so it can trigger again later
        if (currentScenarioStep.stepCondition.resetAtStop) {
            for (const id of ids) {
                onResetScenarioEvent(id);
            }
        }

        // Wait a short while to let screen update before next step
        setTimeout(() => {
                if (currentScenarioStep && currentScenarioStep.stepCallback) {
                    currentScenarioStep.stepCallback();}
            },100
        );
    }, [scenarioEventChanged, scenarioEventRef, currentScenarioStep, onResetScenarioEvent]);

    return (
        <ScenarioDataContext.Provider value={scenarioContextValues}>{props.children}</ScenarioDataContext.Provider>
    );
}

export type ScenarioCallback = (scenario:Scenario) => void;


export type ScenarioConditionValue = {
    type: ScenarioConditionType;
    success: boolean;
    original: number;
    current: number;
    goal: number;
    level: number;
}

export function getEmptyScenarioConditionValue(type:ScenarioConditionType = ScenarioConditionType.none) {
    return {
        type: type,
        success: false,
        original: 0,
        current: 0,
        goal: 0,
        level: 1
    }
}

export function checkFlowConditionsWithFlowData(flowData:FlowDataMap, conditions:Array<ScenarioCondition>, actionSet:ActionSet, partiallyType:string) : ScenarioConditionValue[] {

    let response:ScenarioConditionValue[] = [];
    if (conditions.length === 0) {
        return response;
    }
    let setId = actionSet.id;

    if (!flowData[setId]) {
        return response
    }
    if (!flowData["default"]) {
        return response
    }
    let original = 0;
    let current = 0;
    let reduction = 0;

    for (const condition of conditions) {
        let useRelative = condition.logic === ScenarioConditionLogic.reducedRelative
        if (condition.type === ScenarioConditionType.inflow) {
            original = getGlobalValueFromRegionalData(flowData["default"], FlowDataTags.totalInflowPercentage)
            current = getGlobalValueFromRegionalData(flowData[setId], FlowDataTags.totalInflowPercentage)
            reduction = getFlowReduction(flowData, actionSet.id, FlowDataTags.groundWater, useRelative)
        } else if (condition.type === ScenarioConditionType.overflow) {
            original = getGlobalValueFromRegionalData(flowData["default"], FlowDataTags.totalOverflowPercentage)
            current = getGlobalValueFromRegionalData(flowData[setId], FlowDataTags.totalOverflowPercentage)
            reduction = getFlowReduction(flowData, actionSet.id, FlowDataTags.overflow, useRelative)
        } else if (condition.type === ScenarioConditionType.treatedLower) {
            original = getGlobalValueFromRegionalData(flowData["default"], FlowDataTags.totalPartiallyLowerPercentage)
            current = getGlobalValueFromRegionalData(flowData[setId], FlowDataTags.totalPartiallyLowerPercentage)
            reduction = getFlowReduction(flowData, actionSet.id, FlowDataTags.partiallyLower, useRelative)
        } else if (condition.type === ScenarioConditionType.treatedUpper) {
            original = getGlobalValueFromRegionalData(flowData["default"], FlowDataTags.totalPartiallyUpperPercentage)
            current = getGlobalValueFromRegionalData(flowData[setId], FlowDataTags.totalPartiallyUpperPercentage)
            reduction = getFlowReduction(flowData, actionSet.id, FlowDataTags.partiallyUpper, useRelative)
        } else if (condition.type === ScenarioConditionType.budget) {
            original = 0;
            current = flowData[setId].cost;
            reduction = current
        }

        let conditionResponse = getEmptyScenarioConditionValue(condition.type);
        conditionResponse.level = condition.level
        conditionResponse.original = original;
        conditionResponse.current = current;
        conditionResponse.goal = condition.value;
        if (condition.logic === ScenarioConditionLogic.reducedRelative) {
            conditionResponse.success = (reduction / 100.0 >= conditionResponse.goal);
        } else if (condition.logic === ScenarioConditionLogic.lessOrEqual) {
            conditionResponse.success = (reduction <= conditionResponse.goal);
        }
        response.push(conditionResponse);
    }

    return response;
}

export async function checkFlowConditions(conditions:Array<ScenarioCondition>, actionSet:ActionSet, period:string) : Promise<Array<ScenarioConditionValue>> {

    let response:Array<ScenarioConditionValue> = [];
    if (conditions.length === 0) {
        return response;
    }

    let defaultQuery: QueryData = getEmptyQueryData();
    defaultQuery.id = FE_ID_LOCAL;
    defaultQuery.timeRange = FE_TIME_RANGE_YEAR;
    defaultQuery.period = period;
    defaultQuery.fields = [];
    defaultQuery.actionSet = getEmptyActionSet("default");

    let currentQuery = {...defaultQuery} as QueryData;
    currentQuery.actionSet = actionSet;

    // Get data with and without actions applied
    let results = await Promise.all([
        queryFlowEngine(defaultQuery),
        queryFlowEngine(currentQuery)
    ]);

    let [defaultFlowData, currentFlowData] = results;

    let flowDataMap:FlowDataMap = {"default":defaultFlowData, "current":currentFlowData}

    let original = 0;
    let current = 0;
    let reduction = 0;
    // let goal = 0;

    for (const condition of conditions) {

        let useRelative = condition.logic === ScenarioConditionLogic.reducedRelative
        if (condition.type === ScenarioConditionType.inflow) {
            original = getGlobalValueFromRegionalData(flowDataMap["default"], FlowDataTags.totalInflowPercentage)
            current = getGlobalValueFromRegionalData(flowDataMap["current"], FlowDataTags.totalInflowPercentage)
            // Ground water isn't really correct, it should be all inflow water, but this will do
            reduction = getFlowReduction(flowDataMap, actionSet.id, FlowDataTags.groundWater, true)
        } else if (condition.type === ScenarioConditionType.overflow) {
            original = getGlobalValueFromRegionalData(flowDataMap["default"], FlowDataTags.totalOverflowPercentage)
            current = getGlobalValueFromRegionalData(flowDataMap["current"], FlowDataTags.totalOverflowPercentage)
            reduction = getFlowReduction(flowDataMap, actionSet.id, FlowDataTags.overflow, true)
        } else if (condition.type === ScenarioConditionType.treatedLower) {
            original = getGlobalValueFromRegionalData(flowDataMap["default"], FlowDataTags.totalPartiallyLowerPercentage)
            current = getGlobalValueFromRegionalData(flowDataMap["current"], FlowDataTags.totalPartiallyLowerPercentage)
            reduction = getFlowReduction(flowDataMap, actionSet.id, FlowDataTags.partiallyLower, true)
        } else if (condition.type === ScenarioConditionType.treatedUpper) {
            original = getGlobalValueFromRegionalData(flowDataMap["default"], FlowDataTags.totalPartiallyUpperPercentage)
            current = getGlobalValueFromRegionalData(flowDataMap["current"], FlowDataTags.totalPartiallyUpperPercentage)
            reduction = getFlowReduction(flowDataMap, actionSet.id, FlowDataTags.partiallyUpper, true)
        } else if (condition.type === ScenarioConditionType.budget) {
            original = 0;
            current = flowDataMap["current"].cost;
            reduction = current
        }

        let conditionResponse = getEmptyScenarioConditionValue(condition.type);
        conditionResponse.level = condition.level
        conditionResponse.original = original;
        conditionResponse.current = current;
        conditionResponse.goal = condition.value;
        if (condition.logic === ScenarioConditionLogic.reducedRelative) {
            conditionResponse.success = (reduction / 100.0 >= conditionResponse.goal);
        } else if (condition.logic === ScenarioConditionLogic.lessOrEqual) {
            conditionResponse.success = (reduction <= conditionResponse.goal);
        }

        response.push(conditionResponse);
    }

    return response;
}

/*
export function registerInteractionRef(id:string | undefined, ref:RefObject<HTMLElement | undefined> | null) {
    if (id && ref !== null) {
        refsByID[id] = ref;
    }
}
export function getInteractionRefByID(id:string) : RefObject<HTMLElement | undefined> | null {
    return refsByID[id] ?? null;
}
 */

async function loadScenarios():Promise<Array<Scenario>> {
    let result:Array<Scenario> = [];

    let response = await httpGet("/missions/missions.json");
    if (!httpStatusCodeIsOK(response.status)) {
        return result;
    }
    let promises:Array<Promise<httpResult>> = [];
    let files:Array<string> = response.result as Array<string>;
    for (const name of files) {
        promises.push(httpGet(`/missions/${name}.json`));
    }
    if (promises.length === 0) {
        return result;
    }
    let responses = await Promise.all(promises)
    for (const response of responses) {
        if (httpStatusCodeIsOK(response.status)) {
            result.push(parseScenario(response.result));
        }
    }
    // Check if anything has been persisted
    let persistedData = getPersistedScenarioData()
    for (let scenario of result) {
        let scenarioData = persistedData[scenario.id];
        if (!scenarioData) {
            continue;
        }
        scenario.savedLevel = scenarioData["savedLevel"] ?? 0;
        scenario.completedLevel = scenario.savedLevel; // To keep track of when user has reached a new level
    }

    return result;
}

export function persistScenarioData(scenarios:Scenario[]) {
    let persistedData = getPersistedScenarioData()
    for (let scenario of scenarios) {
        persistedData[scenario.id] = {
            "id":scenario.id,
            "savedLevel":scenario.savedLevel
        }
    }
    localStorage.setItem("scenarios", JSON.stringify(persistedData))
}

function getPersistedScenarioData() : {[key:string]:{[key:string]:any}} {
    let result:{[key:string]:{[key:string]:any}} = {}
    let persistedData = localStorage.getItem("scenarios")
    if (persistedData) {
        let data = JSON.parse(persistedData)
        if (data) {
            result = data
        }
    }
    return result
}
