import {Box, Tooltip} from "@mui/material";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeFork, faExchange, faHome, faTint, faTree} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

export interface CreateQuickEditIconHeaderProps extends React.HTMLProps<any>  {
    firstColWidth:number;
    text:string;
}
export function CreateQuickEditIconHeader(props:CreateQuickEditIconHeaderProps) {
    const {t} = useTranslation()
    return (
        <Box style={{height: "48px"}} className={"box-content-row-padding"}>
            <Box style={{width: props.firstColWidth + "px"}}>
                <p style={{margin: "4px 0px 4px 0px", fontSize: "14px", fontWeight:"bold"}}>{props.text}</p>
            </Box>
            <Tooltip title={t("Planning_Create_ActionList_SeperatePipes") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faCodeFork} />
                </Box>
            </Tooltip>
            <Tooltip title={t("Planning_Create_ActionList_ActivateDuplicate") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faExchange} />
                </Box>
            </Tooltip>

            <Tooltip title={t("Planning_Create_ActionList_CorrectMiss") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faHome} />
                </Box>
            </Tooltip>
            <Tooltip title={t("Planning_Create_ActionList_SealSPipes") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faTint} />
                </Box>
            </Tooltip>
            <Tooltip title={t("Planning_Create_ActionList_SealKPipes") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faTint} />
                </Box>
            </Tooltip>

            <Tooltip title={t("Planning_Create_ActionList_BlueGreenStormwater") as string}>
                <Box style={{flex:1, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <FontAwesomeIcon icon={faTree} />
                </Box>
            </Tooltip>

        </Box>
    )
}
