import * as React from "react";
import {Paper} from "@mui/material";
import {DropDownBarView} from "../common/DropDownBarView";
import {StatusGraphsView} from "./StatusGraphsView";
import {StatusCatchmentsView} from "./StatusCatchmentsView";
import Box from "@mui/material/Box";
import {useContext, useEffect, useState} from "react";
import {getEmptyActionSet} from "../../models/Action";
import {
    FE_ID_LOCAL,
    FE_TIME_RANGE_YEAR, FlowDataContext, FlowDataMap,
    FlowDataSet,
    FlowDataTags,
    getEmptyQueryData,
    QueryData, queryFlowEngine
} from "../../models/FlowContext";

export function StatusView() {
    const {currentActionSet, periodSelection} = useContext(FlowDataContext);
    const [flowData, setFlowData] = useState<{[key:string]:FlowDataSet}>({});

    useEffect(() => {
        let query: QueryData = getEmptyQueryData();
        query.id = FE_ID_LOCAL;
        query.timeRange = FE_TIME_RANGE_YEAR;
        query.period = periodSelection;
        query.fields = [
            "type",
            "fastRainHA", "slowRainHA", "groundWaterHA",
            FlowDataTags.totalInflowPercentage,
            FlowDataTags.overflow,
            FlowDataTags.totalVolume,
            FlowDataTags.partiallyTreated, FlowDataTags.partiallyLowerPercentage, FlowDataTags.partiallyUpperPercentage
        ];
        query.fields = [];
        query.actionSet = getEmptyActionSet("default");
        queryFlowEngine(query).then((result) => {
            let indexedResults:FlowDataMap = {}
            indexedResults[result.id] = result
            setFlowData(indexedResults);
        });
    },[currentActionSet, periodSelection])

    return (
        <Box sx={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
            <Paper className={"paper-area"}>
                <DropDownBarView
                    show={["period", "inflow", "overflow", "sewer-plant"]}
                />
            </Paper>
            <Box sx={{display: "flex", flexDirection: 'row', justifyContent: 'flex-start',  alignItems: 'flex-start', width:"100%", height:"100%"}}>
                <Box sx={{flex:1, height:'100%'}}>
                    <Paper className={"paper-left"}>
                        <StatusGraphsView
                            id="status-graphs-view"
                            flowData={flowData}
                        />
                    </Paper>
                </Box>
                <Box sx={{flex:1, height:'100%'}}>
                    <Paper className={"paper-right"}>
                        <StatusCatchmentsView id="status-catchments-view" editMode={false}/>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );

}
