import React, {FormEvent} from "react";
import {TextFieldSmall} from "../common/TextFieldSmall";
import Box from "@mui/material/Box";

export interface CreateNewDoubleInputProps extends React.HTMLProps<any>  {
    heading:string;
    id:string;
    valueLeft?:string;
    valueRight?:string;
    labelLeft?:string;
    labelRight?:string;
    typeLeft?:string;
    typeRight?:string;
    suffixLeft?:string;
    suffixRight?:string;
    onBlurLeft?:(value:string) => void;
    onBlurRight?:(value:string) => void;
}

export function CreateNewDoubleInputView(props:CreateNewDoubleInputProps) {
    const [leftVal, setLeftVal] = React.useState<string>(props.valueLeft ? props.valueLeft : "");
    const [rightVal, setRightVal] = React.useState<string>(props.valueRight ? props.valueRight : "");

    function blurLeft(event:FormEvent<any>) {
        if (props.onBlurLeft) {
            props.onBlurLeft(leftVal);
        }
    }
    function blurRight(event:FormEvent<any>) {
        if (props.onBlurRight) {
            props.onBlurRight(rightVal);
        }
    }

    return (
        <Box className={"box-content-row"}>
            <Box className={"box-col"}>
                <Box className={"box-row"}>
                    <p className={"field-label"}>{props.heading}</p>
                </Box>
                <Box className={"box-row"}>
                    <TextFieldSmall
                        id={props.id + "-left"}
                        type={props.typeLeft}
                        style={{width:"100%"}}
                        value={leftVal}
                        label={props.labelLeft}
                        suffix={props.suffixLeft}
                        onChange={(event)=>setLeftVal(event.currentTarget.value)}
                        onBlur={blurLeft}
                    />
                    <TextFieldSmall
                        id={props.id + "-right"}
                        type={props.typeRight}
                        style={{width:"100%"}}
                        value={rightVal}
                        label={props.labelRight}
                        suffix={props.suffixRight}
                        onChange={(event)=>setRightVal(event.currentTarget.value)}
                        onBlur={blurRight}
                    />
                </Box>
            </Box>
        </Box>
    );
}
