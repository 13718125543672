import * as React from "react";
import {GaugeView} from "../common/GaugeView";
import {useTranslation} from "react-i18next";
import {useContext, useRef} from "react";
import {ScenarioDataContext} from "../../models/ScenarioContext";

export interface StatusGaugeProps extends React.HTMLProps<any>  {
    id: string;
    label: string;
    min: number;
    max: number;
    current: number;
    goal: number;
}
export function StatusGaugeView(props:StatusGaugeProps) {
    const {setScenarioElement} = useContext(ScenarioDataContext)

    const {t} = useTranslation();
    let gaugeRef = useRef<HTMLDivElement | null>(null);
    setScenarioElement(props.id, gaugeRef)

    return (
        <div ref={gaugeRef} id={props.id + "-tab"} style={{width:'100%', height:'100%'}}>
            <div id={props.id + "-container"} style={{width:'100%', height:'50%'}}>
                <GaugeView id={props.id + "-gauge"} min={props.min} max={props.max} current={props.current} goal={props.goal}/>
            </div>
            <div id={props.id + "-stats"} className={'gauge'} style={{width:'100%', height:'50%'}}>
                <p>{props.current.toFixed(1)}</p>
                <p className={'gauge-legend-sel'}>{props.label}</p>
                <p className={'gauge-legend'}>{t("Planning_Status_Gauges_Scale") + ":" + props.min.toFixed(0) + " - " + props.max.toFixed(0)}</p>
                <p className={'gauge-legend'}>{t("Planning_Status_Gauges_Target") + ":" + props.goal.toFixed(1)}</p>
            </div>
        </div>

    );

}
