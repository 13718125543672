import React, {useContext} from "react";
import {
    Box,
    Button,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack
} from "@mui/material";
import OpacityIcon from '@mui/icons-material/Opacity';
import {ScenarioDataContext} from "../../models/ScenarioContext";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {Stars} from "../common/Stars";
import {DivWithRef} from "../common/DivWithRef";


export interface ScenarioSelectorProps extends React.HTMLProps<any>  {
    open:boolean;
    onOK:() => void
    onCancel:() => void
}

export function MissionSelectorView(props:ScenarioSelectorProps) {
    const {scenarios, setCurrentScenario, setScenarioEvent} = useContext(ScenarioDataContext);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const {t} = useTranslation();

    function ok() {
        if (selectedIndex < scenarios.length) {
            setScenarioEvent("close-mission-selector", true);
            setCurrentScenario(null);
            setCurrentScenario(scenarios[selectedIndex]);
            props.onOK();
        }
    }

    function cancel() {
        setScenarioEvent("close-mission-selector", true);
        setCurrentScenario(null);
        props.onCancel();
    }

    const rows = scenarios.map((value, idx)=>{
        return(
                <ListItemButton
                    key={value.id}
                    selected={(idx === selectedIndex)}
                    onClick={() => setSelectedIndex(idx)}
                >
                    <ListItemIcon>
                        <OpacityIcon />
                    </ListItemIcon>
                    <DivWithRef id={"mission-selector-" + value.id} style={{width:"100%"}}>
                        <ListItemText primary={t("Scenarios:" + value.title)} />
                    </DivWithRef>
                    <Stars maxLevel={value.levels} level={value.savedLevel} />
                </ListItemButton>
        );
    });

    return (
        <Popover open={props.open}
                 anchorReference="anchorPosition"
                 anchorPosition={{ top: 100, left: window.innerWidth / 2 }}
                 anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                 transformOrigin={{vertical: 'top', horizontal: 'center'}}
                 style={{zIndex:3000}}
        >

            <Box sx={{width:"600px"}}>
                <Box sx={{width:"100%", padding:"8px 16px 0px 16px"}}>
                    <p className={"field-header"}>{t("ScenarioUI:scenarios_select_scenario")}</p>
                </Box>
                <DivWithRef id={"mission-selector"}>
                    <List style={{maxHeight:"300px", overflow:"auto"}}>
                        {rows}
                    </List>
                </DivWithRef>
                <Divider />
                <Box sx={{height:"300px", width:"100%", padding:"8px 16px 8px 16px"}}>
                    <p>
                        {
                            (selectedIndex >= 0 && selectedIndex < scenarios.length) ?
                                t("Scenarios:" + scenarios[selectedIndex].body) : ""
                        }
                    </p>
                </Box>

                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} style={{width:"100%", height:"64px", marginTop:"auto", padding:"12px 12px 12px 12px"}}>
                    <Button variant="outlined" onClick={cancel}>{t("ScenarioUI:scenarios_select_scenario_cancel")}</Button>
                    <Button variant="outlined" onClick={ok}>{t("ScenarioUI:scenarios_select_scenario_ok")}</Button>
                </Stack>
            </Box>
        </Popover>
    )

}
